.review {
	display: none;
}

.rating {
	width: 180px;
	height: 35px;
	background:url(//primoprint.imgix.net/star-ratings.svg) top left no-repeat;
	background-size: cover;
	display: block;
	margin: 0 auto 10px;
	&.stars5 {background-position: 0 0%;}
	&.stars4 {background-position: 0 25%;}
	&.stars3 {background-position: 0 50%;}
	&.stars2 {background-position: 0 75%;}
	&.stars1 {background-position: 0 100%;}
}

.star-ratings-sprite {
  background: url("//primoprint.imgix.net/star-rating-bottom.svg");
	background-size: cover;
  font-size: 0;
  height: 15px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 86px;
  margin: 0 auto;

  &-rating {
    background: url("//primoprint.imgix.net/star-rating-top.svg");
		background-size: cover;
    background-position: 0 100%;
    float: left;
    height: 15px;
    display:block;
  }

}

.review.review-active {
    display: block
}

// Reviews Page

#map-plug {
	display:none;
}

.review-item {
	margin:0 auto;
	padding:1em;
}

.review-meta,
.review-stars {
	font-size:115%;
}
.review-meta {
	float: left;
}
.review-stars {
	float: right;
}
.review-author {
	text-transform: capitalize;
	font-weight:bold;
}
.review-date {
	opacity:.6;
	margin-left: 10px;
}

.review-text {
	line-height:1.55;
	text-align:left;
	padding-top: 1em;
	clear: both;
}

.review-stars ul {
	display: inline-block;
	list-style: none !important;
	margin:0;
	padding:0;
	li {
		float: left;
		list-style: none !important;
		margin-right: 1px;
		line-height:1;
		i {
			color: #E4B248;
			font-size: 1.4em;
			font-style:normal;
			&.inactive {
				color: #c6c6c6;
			}
		}
	}
}

.star:after {
	content: "\2605";
}
