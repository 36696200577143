.datepickr-wrapper {
    display: inline-block;
    position: relative;
}

.datepickr-calendar {
	font-family: 'Trebuchet MS', Tahoma, Verdana, Arial, sans-serif;
	font-size: 12px;
	background-color: #eee;
	color: #333;
	border: 1px solid #ddd;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	padding: 2px;
	display: none;
	position: absolute;
    top: 100%;
	left: 0;
	z-index: 100;
    width:250px;
}

.open .datepickr-calendar {
    display: block;
}

.datepickr-calendar .datepickr-months {
	background-color: #4D648d;
	border: 1px solid #283655;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	color: #fff;
	padding: 2px;
	text-align: center;
    font-size: 120%;
}

.datepickr-calendar .datepickr-prev-month,
.datepickr-calendar .datepickr-next-month {
	color: #fff;
	text-decoration: none;
	padding: 0 .6em;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	cursor: pointer;
    font-weight:bold;
}

.datepickr-calendar .datepickr-prev-month {
	float: left;
}

.datepickr-calendar .datepickr-next-month {
	float: right;
}

.datepickr-calendar .datepickr-current-month {
	padding: 0 .5em;
    font-weight:bold;
}

.datepickr-calendar .datepickr-prev-month:hover,
.datepickr-calendar .datepickr-next-month:hover {
	background-color: #fff;
	color: #478DD9;
}

.datepickr-calendar table {
	border-collapse: collapse;
	padding: 0;
	width: 100%;
}

.datepickr-calendar thead {
    font-size: 90%;
}

.datepickr-calendar th,
.datepickr-calendar td {
    width: 14.3%;
}

.datepickr-calendar th {
	text-align: center;
    padding: 5px;
}

.datepickr-calendar td {
	text-align: right;
	padding: 1px;
}

.datepickr-calendar .datepickr-day {
	display: block;
	color: #283655;
	background-color: #f6f6f6;
	border: 1px solid #ccc;
	padding: 5px;
	cursor: pointer;
    height: 34px;
    line-height: 24px;
}

.datepickr-calendar .datepickr-day:hover {
	color: #ffffff;
	background-color: #283655;
	border: 1px solid #283655;
}

.datepickr-calendar .today .datepickr-day {
	background-color: #fff0A5;
	border: 1px solid #fed22f;
	color: #363636;
}

.datepickr-calendar .selected .datepickr-day {
	background-color: #283655;
	color: #fff;
}

.datepickr-calendar .disabled .datepickr-day,
.datepickr-calendar .disabled .datepickr-day:hover {
    background-color: #eee;
    border: 1px dotted #ccc;
    color: #bbb;
    cursor: default;
}
