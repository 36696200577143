@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;
}

@mixin border-radius-custom ($topleft: 5px, $topright: 5px, $bottomright: 5px, $bottomleft: 5px) {
  -webkit-border-radius: $topleft $topright $bottomright $bottomleft;
  border-radius: $topleft $topright $bottomright $bottomleft;
  background-clip: padding-box;
}

@mixin box-shadow ($x: 0, $y: 0, $blur: 1px, $color: #000) {
	-moz-box-shadow: $x $y $blur $color;
	-webkit-box-shadow: $x $y $blur $color;
	box-shadow: $x $y $blur $color;
}

@mixin rotate ($rotate: 30deg) {
	-ms-transform: rotate($rotate); /* IE 9 */
	-webkit-transform: rotate($rotate); /* Chrome, Safari, Opera */
	transform: rotate($rotate);
}

/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}
@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}
@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

.bounce-right {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
}

/* /right bounce */

.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-align-ab {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-align-fix {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-align {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.horizontal-align-ab {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.horizontal-align-fix {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.center-align {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-align-fix {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-align-ab {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes shake{
0% { transform: translate(2px, 0px); }
10% { transform: translate(-2px, 0px); }
20% { transform: translate(2px, 0px); }
30% { transform: translate(-2px, 0px); }
40% { transform: translate(2px, 0px); }
50% { transform: translate(-2px, 0px); }
60% { transform: translate(2px, 0px); }
70% { transform: translate(-2px, 0px); }
80% { transform: translate(2px, 0px); }
90% { transform: translate(-2px, 0px); }
100% { transform: translate(2px, 0px); }
}

@-moz-keyframes shake{
0% { -moz-transform: translate(2px, 0px); }
10% { -moz-transform: translate(-2px, 0px); }
20% { -moz-transform: translate(2px, 0px); }
30% { -moz-transform: translate(-2px, 0px); }
40% { -moz-transform: translate(2px, 0px); }
50% { -moz-transform: translate(-2px, 0px); }
60% { -moz-transform: translate(2px, 0px); }
70% { -moz-transform: translate(-2px, 0px); }
80% { -moz-transform: translate(2px, 0px); }
90% { -moz-transform: translate(-2px, 0px); }
100% { -moz-transform: translate(2px, 0px); }
}

@-webkit-keyframes shake {
0% { -webkit-transform: translate(2px, 0px); }
10% { -webkit-transform: translate(-2px, 0px); }
20% { -webkit-transform: translate(2px, 0px); }
30% { -webkit-transform: translate(-2px, 0px); }
40% { -webkit-transform: translate(2px, 0px); }
50% { -webkit-transform: translate(-2px, 0px); }
60% { -webkit-transform: translate(2px, 0px); }
70% { -webkit-transform: translate(-2px, 0px); }
80% { -webkit-transform: translate(2px, 0px); }
90% { -webkit-transform: translate(-2px, 0px); }
100% { -webkit-transform: translate(2px, 0px); }
}

.shake{
  animation-name: shake;
  animation-duration: 0.8s;
  transform-origin:50% 50%;
  animation-iteration-count: 200ms;
  animation-timing-function: linear;

  -moz-animation-name: shake;
  -moz-animation-duration: 0.8s;
  -moz-transform-origin:50% 50%;
  -moz-animation-iteration-count: 200ms;
  -moz-animation-timing-function: linear;

  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin:50% 50%;
  -webkit-animation-iteration-count: 200ms;
  -webkit-animation-timing-function: linear;
}

.animate2 {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.animateopacity {
  -webkit-transition: opacity .2s ease;
  -moz-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease;
}

.shadow {
    box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.15);
}

.shadowflat {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
}

.shadowlight {
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.07);
}

.rotate0 {
    -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -o-transition:.2s;
    -ms-transition:.2s;
    -moz-transition:.2s;
    -webkit-transition:.2s;
    transition:.2s;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
}

.rotate90 {
    -webkit-transform: rotate(90deg) translate3d(0, 0, 0);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -o-transition:.2s;
    -ms-transition:.2s;
    -moz-transition:.2s;
    -webkit-transition:.2s;
    transition:.2s;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}
