//Alt Placeholder Display
.modal .inputwrap.placeholder {
    margin: 0;
    &.select {
        margin-bottom: 15px;
    }
}

.inputwrap.placeholder{
  select::-ms-expand {
    display: none;
  }
}

.inputwrap.placeholder{
    border: 1px solid #ccc;
    border-radius: 4px;
    @extend .animate2;
    position: relative;
    height: auto;
    .form-control{
        font-size: .9rem;
        padding: 0 10px;
    }
    textarea.form-control{
        border: 0;
        padding: 25px 9px 8px;
        line-height: 16px;
        height: auto;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    input.form-control{
        border: 0;
        padding: 25px 9px 8px;
        line-height: 16px;
        height: auto;
        -moz-appearance: none;
        -webkit-appearance: none;
    }
    label {
        color: $grayLight;
        position: absolute;
        top: 10px;
        left: 10px;
        line-height: 32px;
        pointer-events: none;
        -webkit-transform: translateZ(0) scale(1);
        transform: translateZ(0) scale(1);
        transform-origin: left top 0;
        transition: all .25s ease-in-out;
        text-overflow: ellipsis;
        overflow-x: hidden;
        width: 90%;
        display: inline-block;
        white-space: nowrap;
    }
    &.focused {
        label {
            color: $blueReg;
            -webkit-transform: translate3d(0,-9px,0) scale(.8);
            transform: translate3d(0,-9px,0) scale(.8);
            text-overflow: ellipsis;
            overflow-x: hidden;
            width: 100%;
            display: inline-block;
            white-space: nowrap;
        }
    }
    &.select, &.upload {
        @extend .boxsize;
        @extend .animate2;
        label {
            color: $blueReg;
            -webkit-transform: translate3d(0,-9px,0) scale(.8);
            transform: translate3d(0,-9px,0) scale(.8);
            text-overflow: ellipsis;
            overflow-x: hidden;
            width: 100%;
            display: inline-block;
            white-space: nowrap;
        }
        select {
            height: 29px;
            margin-bottom: 0;
            margin-top: 20px;
            line-height: 29px;
            float: none;
            border: 0;
            box-shadow: none;
            -moz-appearance: none;
            -webkit-appearance: none;
        }
        &.focused {
            border-color: #80bdff;
        }
        @media #{$mq-mobile} {
            padding-left: 0;
        }
    }
    &.select {
        &:after {
            @extend .vertical-align-ab;
            right: 4px;
            font-family: 'primo';
            content: attr(data-icon);
            content: "\e001";
            speak: none;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            font-size: 20px;
            pointer-events: none;
        }
        select::-ms-expand {
          display: none;
        }
    }
    @media #{$mq-desktop} {
        padding: 0
    }
    @media #{$mq-mobile} {
        width: 100%;
    }
}

@-moz-document url-prefix() {
  .inputwrap.placeholder{
    overflow: hidden;
    select {
      overflow:hidden;
      width: 110%;
    }
  }
  .step-form {
    .inputwrap.placeholder{
      overflow: visible;
      select {
        overflow: visible;
        width: 100%;
      }
    }
  }
}

.reqit {
    @extend .vertical-align-ab;
    right: 10px;
    font-size: 0.8em;
    color: #cdcdcd;
    &.red {
        color: $attention;
    }
}
