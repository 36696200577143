.method {
    width: 46px;
    height: 32px;
    background: url("/images/paymentmethods.png") top left no-repeat;
    background-size: 100%;
    cursor: pointer;

    &.credit {
        background-position: 0 0;
    }

    &.echeck {
        background-position: 0 -32px;
    }

    &.paypal {
        background-position: 0 -64px;
    }

    &.invoice {
        background-position: 0 -96px;
    }

    &.visa {
        background-position: 0 -128px;
    }

    &.mastercard {
        background-position: 0 -160px;
    }

    &.discover {
        background-position: 0 -192px;
    }

    &.amex {
        background-position: 0 -224px;
    }

    &.apple {
        background-position: 0 -256px;
    }
}

.cartlist {
    @media print {
        font-size: 1.2rem;
        padding: 0 !important;
        .jobTitle {
            font-weight: 700;
            font-size: 1.2rem;
            color: #000000;
            padding: 0;
        }
    }
}
