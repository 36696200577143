// Visibility

.desktop-hide {
	display: none;
}
.hide {
	display: none;
}
.hidestrong {
	display: none !important;
}
.hibernate {
	display: none !important;
}
.displayblock {
	display: block;
}
.d-inline-table {
	display: inline-table;
}
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
.overflowhide {
	overflow: hidden;
}

.overflowshow {
	overflow: visible;
}

.outlinenone {
	outline: none;
}

.autofillnone:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px white inset;
}

.pointer {
	cursor: pointer;
}

.nopointer {
	cursor: not-allowed;
}


.zoomhover{
	transition: transform .2s;
	&:hover {
  	transform: scale(1.02);
	}
}

.col-center {
	float: none;
	margin: 0 auto;
}

.m-0-auto {
	margin: 0 auto;
}

// Layout

.clear {
	clear: both;
}

.clearright {
	clear: right;
}

.group:before,
.group:after {
	content:"";
	display:table;
}
.group:after {
	clear:both;
}
.group {
	zoom:1; /* For IE 6/7 (trigger hasLayout) */
}

.boxsize {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.absolute-top {
	position: absolute;
	top: 0;
}

.fixed {
	position: fixed;
}

.top-0 {
	top: 0;
}

.left-0 {
	left: 0;
}

.left-1 {
	left: 1em;
}

.right-0 {
	right: 0;
}

.bottom-0 {
	bottom: 0;
}

.nowrap {
	white-space: nowrap
}

.imgauto, .imgauto img {
	width: 100%;
	height: auto;
}

.imgcover, .imgcover img {
	width: auto;
	height: auto;
	object-fit: cover;
}

.backgroundcover {
	background-repeat: no-repeat;
	background-size: cover;
}

.max-100 {
	max-height: 100%;
}

.max-600 {
	max-width: 600px;
	margin: 0 auto;
}

.max-700 {
	max-width: 700px;
	margin: 0 auto;
}

.max-850 {
	max-width: 850px;
	margin: 0 auto;
}

.max-900 {
	max-width: 900px;
	margin: 0 auto;
}

.max-1000 {
	max-width: 1000px;
	margin: 0 auto;
}

.max-1100 {
	max-width: 1100px;
	margin: 0 auto;
}

.min-300 {
	min-width: 300px;
	margin: 0 auto;
}

.min-200 {
	min-width: 200px;
	margin: 0 auto;
}

.z-index {
	&-0 {z-index: 0}
	&-1 {z-index: 1}
	&-2 {z-index: 2}
	&-3 {z-index: 3}
	&-4 {z-index: 4}
	&-50 {z-index: 50 !important}
	&-max {
		z-index: 16777216;
		&-1 {z-index: 16777215}
	}
}

.radius {
	&-0 {border-radius: 0em}
	&-025 {border-radius: .25em}
	&-05 {border-radius: .5em}
	&-1 {border-radius: 1em}
	&-2 {border-radius: 2em}
	&-3 {border-radius: 3em}
	&-30 {border-radius: 30em}
}

hr.white {
	border-color: #ffffff;
}

//Typography

// Change bootstrap rem to em
h1, .h1 {
	font-size: 2.5em;
}

h2, .h2 {
	font-size: 2em;
}

h3, .h3 {
	font-size: 1.75em;
}

h4, .h4 {
	font-size: 1.5em;
}

h5, .h5 {
	font-size: 1.25em;
}

h6, .h6 {
	font-size: 1em;
}

// Font Scale and Weight
.lineheight {
	&-normal {line-height: normal}
	&-05 {line-height: .5em}
	&-75 {line-height: .75em}
	&-85 {line-height: .85em}
	&-1 {line-height: 1em}
	&-115 {line-height: 1.15em}
	&-125 {line-height: 1.25em}
	&-15 {line-height: 1.5em}
	&-165 {line-height: 1.65em}
	&-175 {line-height: 1.75em}
	&-2 {line-height: 2em}
	&-250 {line-height: 2.5em}
	&-3 {line-height: 3em}
	&-4 {line-height: 4em}
}

.type{
	&-4 {font-size: 4em;}
	&-350 {font-size: 3.5em;}
	&-3 {font-size: 3em;}
	&-250 {font-size: 2.5em;}
	&-225 {font-size: 2.25em;}
	&-2 {font-size: 2em;}
	&-175 {font-size: 1.75em;}
	&-165 {font-size: 1.75em;}
	&-150 {font-size: 1.5em;}
	&-135 {font-size: 1.35em;}
	&-125 {font-size: 1.25em;}
	&-115 {font-size: 1.15em;}
	&-1 {font-size: 1em;}
	&-90 {font-size: .90em;}
	&-85 {font-size: .85em;}
	&-80 {font-size: .80em;}
	&-75 {font-size: .75em;}
	&-66 {font-size: .66em;}
	&-50 {font-size: .5em;}
	&-33 {font-size: .33em;}
	&-25 {font-size: .25em;}
}

.type-rem{
	&-4 {font-size: 4rem;}
	&-3 {font-size: 3rem;}
	&-250 {font-size: 2.5rem;}
	&-2 {font-size: 2rem;}
	&-175 {font-size: 1.75rem;}
	&-150 {font-size: 1.5rem;}
	&-125 {font-size: 1.25rem;}
	&-115 {font-size: 1.15rem;}
	&-1 {font-size: 1rem;}
	&-90 {font-size: .90rem;}
	&-85 {font-size: .85rem;}
	&-80 {font-size: .80rem;}
	&-75 {font-size: .75rem;}
	&-66 {font-size: .66rem;}
	&-50 {font-size: .5rem;}
	&-33 {font-size: .33rem;}
	&-25 {font-size: .25rem;}
}

.font {
	&-100 {font-weight: 100;}
	&-200 {font-weight: 200;}
	&-300 {font-weight: 300;}
	&-400 {font-weight: 400;}
	&-500 {font-weight: 500;}
	&-600 {font-weight: 600;}
	&-700 {font-weight: 700;}
}

// Links
.noline {
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
	a {
		text-decoration: none;
		&:hover {
			text-decoration: none;
		}
	}
}

.nolist {
	li {
		list-style: none !important;
	}
}

.dotted {
	text-decoration: none;
	padding-bottom: 5px;
	margin-bottom: 5px;
	&:hover {
		text-decoration: none;
	}
	&.light {
		border-bottom: 1px dotted #ffffff;
	}
	&.dark {
		border-bottom: 1px dotted $dark;
	}
}

.line {
	a {
		text-decoration: underline;
	}
}

.boldlinks {
	a {
		font-weight: bold;
	}
}


// Other
.noselect {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select:none;
	user-select:none;
}

.alertnotice {
	color: $attention;
}

.border-alert {
	border-color: $attention !important;
}

.center {
	text-align: center;
}

.nowrap{
	white-space: nowrap;
}

.superscript {
	position: relative;
	top: -.5em;
	font-size: 40%;
	line-height: normal;
}

.text-shadow {
	text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
	&.heavy {
		text-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
	}
}

.shadowbox {
	border-radius: 0.3rem;
	box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
	transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
	&:hover {
		box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
	}
}

.box-shadow-angle {
	background: rgb(0,0,0);
	background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 49%);
	filter: blur(.2rem);
	opacity: .2;
}

.buttonshadow {
	box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.opacityhover {
	&:hover {
		opacity: .85;
	}
}

.opacity {
	&-1 {opacity: .1}
	&-2 {opacity: .2}
	&-3 {opacity: .3}
	&-4 {opacity: .4}
	&-5 {opacity: .5}
	&-6 {opacity: .6}
	&-7 {opacity: .7}
	&-8 {opacity: .8}
	&-9 {opacity: .9}
}

// Borders

.border {
	&-1 {border-width: 1px !important}
	&-2 {border-width: 2px !important}
	&-3 {border-width: 3px !important}
	&-4 {border-width: 4px !important}
	&-5 {border-width: 5px !important}
	&-hidesm {
		@media (max-width: 767px) {
			border-width: 0 !important;
		}
	}
}

.border-right {
	border-right-style: solid;
}

.border-left {
	border-left-style: solid;
}

.border-bottom {
	border-bottom-style: solid;
}

.border-top {
	border-top-style: solid;
}

.hideborder {
	@media (max-width: 767px) {
		border: 0 !important;
		outline: none;
	}
}

.hover-show {
	opacity: 0;
	@extend .animate2;
	&:hover {
		opacity: 1;
	}
}
