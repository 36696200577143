/** Modal **/
.mapModal{
	position: fixed;
	top:60px;
	left:50px;
	right: 50px;
	bottom: 50px;
	box-sizing: border-box;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.8) url('/images/loading.svg') no-repeat center center;
	border-radius: 8px;
	transition: opacity .5s;

	a.btn{
		&.hide{
			opacity: 0;
		}
	}

	.route_functions{
		height: 50px;
		z-index: 3;
		top:54px;
		left: 10px;
		width: 290px;

		.login_buttons{
			display: none;
			a{
				margin-left: 10px;
			}
		}

		&.login .login_buttons{
			display: block;
		}

		.route_buttons{
			margin-left: -290px;
			transition: all .3s;
		}

		.route_save{
			margin-left: 290px;
			transition: all .3s;
		}

		&.rButtons{
			.route_buttons{
				margin-left: 0;
			}
			.route_save{
				margin-left: 290px;
			}
		}

		&.rSave{
			.route_buttons{
				margin-left: -290px;
			}
			.route_save{
				margin-left: 0;
			}
		}

	}

	.routes_list{
		z-index: 3;
		left: 10px;
		top: 104px; //54px
		bottom: 10px;
		width: 290px;
		border-right: 1px solid #A3A3A3;
		background: #D8D8D8;
		border-radius: 0 0 0 4px;
		overflow-x: hidden;
		overflow-y: auto;

		div.login{
			font-size: 1.1em;
			padding: 10px;
			display: none !important;
		}

		&.login div.login{
			display: block !important;
		}

		ul{

			li{
				border-bottom: 1px solid #BABBBA;
				padding: 10px;
				transition: all .4s;

				&.tutorial{
					height: 100%;
					h4{
						margin: 7px 0;
						font-weight: normal;
						color: #212121;
					}
					span, a{
						display: none;
					}
				}

				a.opt{
					top: 10px;
					right: 10px;
					width: 22px;
					height: 22px;
					background: #BABBBA;
					line-height: 22px;
					font-size: 0.8em;
					&.optGreen{
						right: 40px;
						display: none;
					}
					&.optRed{
						&:hover{
							background: lighten($attention, 10%);
						}
					}
				}

				&.active{
					background: #fff;
					a.optGreen{
						display: block;
					}
				}

				&:hover{
					background: #efefef;
				}

				h3, h4{
					margin: 0;
				}
				h3{
					font-size: 1.2em;
					color: $blueReg;
				}
				h4{
					font-size: 1.1em;
					color: $blueReg;
				}
				& > span{
					display: inline-block;
					padding-right: 12px;
					font-size: 1em;
					color: #5B5B5B;
					& + span{
						padding-right: 0px;
					}
				}
			}
		}
	}

	iframe{
		box-sizing: border-box;
		position: absolute;
		height: 100% !important;
		z-index: 1;
		opacity: 0;
		transition: all .5s;
		border: 0;
	}

	.map{
		padding: 0;
		max-width: 100%;
		position: absolute;
		left: 10px;
		right: 10px;
		top:10px;
		.routeSelection{
			margin: 0;
			background: none;

			.productheader{
				border-radius: 0 4px 0 0;
				background: $blueReg;
				border-top: 1px solid lighten($blueReg, 15%);
				border-bottom: 1px solid darken($blueReg, 15%);
				border-left: 1px solid darken($blueReg, 15%);
				position: relative;
				z-index: 2;
				margin-left: 290px;

				&:first-child{
					position: absolute;
					border-radius: 4px 0 0 0;
					z-index: 4;
					margin-left: 0;
					width:269px;
					// border-right: 1px solid lighten($blueReg, 15%);
					border-left: 0;
				}

				a.mapCloseBtn{
					position: absolute;
					top: 0px;
					right: 0px;
					color: #fff;
					transition: color .5s;
					width: 40px;
					height: 40px;
					line-height: 42px;
					&:hover{
						color: $blueReg;
					}
				}
			}
		}
	}
}

/** Prod Pages **/
.productDetails.routeSelection{
	position: relative;
	margin-bottom: 0;
	background: #fff url(/images/loading.svg) no-repeat center center;

	iframe{
		box-sizing: border-box;
		transition: all .5s;
	}

	.eddmSubNav{
		position: absolute;
		top: 42px;
		left: 0;
		bottom: 0;
		background: darken($blueReg, 8%);
		width: 44px;
		transition: all .5s;

		&.expand{
			margin-left: 290px;
		}

		.navToggle{
			z-index: 999;
			line-height: 50px;
			bottom: 9px;
			left: 4px;
			color: #fff;
			padding: 8px 16px;
			font-weight: bold;
		}
	}

	.route_functions{
		height: 50px;
		z-index: 3;
		top: 42px;
		margin-left: -290px;
		width: 290px;
		border-right: 1px solid darken($blueReg, 15%);
		transition: all .5s;

		&.expand{
			margin-left: 0;
		}

		.login_buttons{
			display: none;
			z-index: 100;
			a{
				margin: 6px 0 0 8px;
			}
		}

		&.login .login_buttons{
			display: block;
		}

		.route_buttons{
			margin-left: -290px;
			transition: all .3s;
		}

		.route_save{
			margin-left: 290px;
			transition: all .3s;
		}

		&.rButtons{
			.route_buttons{
				margin-left: 0;
			}
			.route_save{
				margin-left: 290px;
			}
		}

		&.rSave{
			.route_buttons{
				margin-left: -290px;
			}
			.route_save{
				margin-left: 0;
			}
		}

	}

	.routes_list{
		z-index: 3;
		margin-left: -290px; //-290px
		top: 92px; //54px
		bottom: 0;
		width: 290px;
		border-right: 1px solid #A3A3A3;
		background: #D8D8D8;
		border-radius: 0 0 0 4px;
		overflow-x: hidden;
		overflow-y: auto;
		transition: all .5s;

		div.login{
			display: none;
			padding: 10px;
		}

		&.login div.login{
			display: block;
		}

		&.expand{
			margin-left: 0;
		}

		ul{

			li{
				border-bottom: 1px solid #BABBBA;
				padding: 10px;
				transition: all .4s;

				&.tutorial{
					height: 100%;
					h4{
						margin: 7px 0;
						font-weight: normal;
						color: #212121;
					}
					span, a{
						display: none;
					}
				}

				a.opt{
					top: 10px;
					right: 10px;
					width: 22px;
					height: 22px;
					background: #BABBBA;
					line-height: 22px;
					font-size: 0.8em;
					&.optGreen{
						right: 40px;
						background: $blueReg;
						display: none;
					}
					&.optRed{
						&:hover{
							background: lighten($attention, 10%);
						}
					}
				}

				&.active{
					background: #fff;
					a.optGreen{
						display: block;
					}
				}

				&:hover{
					background: #efefef;
				}

				h3, h4{
					margin: 0;
				}
				h3{
					font-size: 1.2em;
					color: $blueReg;
				}
				h4{
					font-size: 1.1em;
					color: $blueReg;
				}
				& > span{
					display: inline-block;
					padding-right: 12px;
					font-size: 1em;
					color: #5B5B5B;
					& + span{
						padding-right: 0px;
					}
				}
			}
		}
	}
}
