input[type="checkbox"] {
	display: none;
}

input[type="checkbox"] + label span {
	display: inline-block;
	width: 17px;
	height: 19px;
	margin: -1px 0 0 0;
	vertical-align: middle;
	background: url("/images/check_radio_dark.png") left top no-repeat;
	cursor: pointer;
	background-size: cover;
}

input[type="checkbox"]:checked + label span {
	background: url("/images/check_radio_dark.png") -18px top no-repeat;
	background-size: cover;
}

input[type="radio"] {
	display: none;
}

input[type="radio"] + label span {
	display: inline-block;
	width: 17px;
	height: 19px;
	margin: -1px 0 0 4px;
	vertical-align: middle;
	background: url("/images/check_radio_dark.png") -36px top no-repeat;
	cursor: pointer;
	background-size: cover;
}

input[type="radio"]:checked + label span {
	background: url("/images/check_radio_dark.png") -54px top no-repeat;
	background-size: cover;
}
