.accountsNav {
    border-right: 0;
    border-radius: 10px 0 0 10px;
}

.accountExpand {
    .accountsNav {
        text-align: center;
        .acctnavtext {
            display: none;
        }
    }
}

.accountContent {
    @include border-radius(5px);
    border: 1px solid $grayLighter;
    min-height: 400px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    @media ($bp-min-md) {
        width: 75%;
        @include border-radius-custom(0, 10px, 10px, 10px);
    }
    @media print {
        border: 0;
        padding: 0;
        width: 100% !important;
        .invoiceDetails {
            width: 100%;
        }
        .container {
          max-width: none;
        }
    }
}

.sidenav {
    float: left;
    width: 100%;
    @include border-radius(5px);
    margin-bottom: 1em;
    background: rgba(255, 255, 255, .2);
    border: 1px solid #9f9f9f;
    @media ($bp-min-md) {
        width: 25%;
        border-right: none;
        @include border-radius-custom(10px, 0, 0, 10px);
    }
}

.sidenav li {
    margin-bottom: 0;

    a {
        font-size: .85rem;
        color: $grayReg;
        display: block;
        padding: 5px 10px;
        border-bottom: 1px solid #9f9f9f;
        overflow: hidden;
        &:hover {
            background: rgba(255, 255, 255, .4);
            color: $blueReg;
        }
        &.select,
        &.select:hover {
            background-color: $blueReg;
            color: #ffffff
        }
    }

    &:last-child a {
        border-bottom: none;
    }
}

.historyDetails {
    position: absolute;
    top: 0;
    left: 105%;
    width: 100%;
    opacity: 0;
}

.historyJobs,
.invoiceList,
.pendingJobs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
