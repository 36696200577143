/*** FILE UPLOADER ***/

.uploadDesign,
.uploadMobile {
    margin-bottom: 20px;
    padding: 11px 6px 11px 37px;
    line-height: 20px;
    position: relative;
    @include border-radius(5px);

    span {
        font-size: 25px;
        height: 25px;
        position: absolute;
        left: 7px;
    }
}

.uploadMobile {
    display: none;
}

.uploadLeft div.directions,
.uploadLeft div.filePreview {
    display: none;
}

.uploadLeft.showDirections div.directions {
    display: block;
    padding-bottom: 20px;
}

.uploadLeft.showPreview div.filePreview {
    display: block;
}

.uploadLeft h3 {
    font-weight: bold;
    font-size: 19px;
}

.uploadLeft p {
    color: $dark;
    font-size: 13px;
    line-height: 16px;
}

.uploadLeft img {
    display: inline-block;
    margin-bottom: 10px;
}

.oval-h {
    background: url("/images/oval-business-card-area-h.png");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.oval-v {
    background: url("/images/oval-business-card-area-v.png");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.oval-h-clip {
    background: url("/images/oval-business-card-area-h-clip.png");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.oval-v-clip {
    background: url("/images/oval-business-card-area-v-clip.png");
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.preview {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1020;
}

.preview img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0;
    width: 100%;
}

.previewLine {
    position: absolute;
}

.preview {
    .hangtaghole {
        display: none;
        min-height: inherit;
        min-width: inherit;

        .drillsafe {
            border: 1px solid green;
            width: 160%;
            height: 160%;
            display: block;
            border-radius: 100px !important;
            @extend .center-align-ab, .boxsize;
        }
    }

    &.vertical {
        .hangtaghole {
            display: block;
            width: 6.9%;
            height: auto;
            @extend .horizontal-align-ab;
        }
    }

    &.horizontal {
        .hangtaghole {
            display: block;
            height: 6.9%;
            width: auto;
            @extend .vertical-align-ab;

            img {
                height: 100%;
                width: auto;
            }
        }
    }
}

.heightauto {
    height: auto !important;
}

.widthauto {
    width: auto !important;
}

.previewMask1,
.previewMask2 {
    z-index: 1001;
    opacity: 0.75;
    position: absolute;
    left: 0;
    top: 0;
}

.previewBleed,
.previewMask1,
.previewMask2 {
    border: 1px solid red;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1000;
}

.previewTrim {
    border: 1px solid blue;
    z-index: 1002;
}

.previewMargins .previewTrim {
    top: 0;
    left: 0;
    position: absolute;
}

.previewSafe {
    border: 1px solid green;
    z-index: 1003;
    top: 0;
    left: 0;
}

.previewLabel {
    font-size: 16px;
    color: $dark;
    text-align: center;
    font-weight: bold;
    padding: 10px 0;
}

.preview-flip-container {
    min-height: 254px;
}

.preview-flip-container .previewTrim {
    border: none;
    @include box-shadow(0, 0, 10px, #cccccc);
    background-position: center center;
    background-repeat: no-repeat;
    z-index: 1000;
}

.preview-flip-container .previewMask1,
.preview-flip-container .previewMask2 {
    border: none;
}

.preview-flip-container,
.previewMargins {
    padding-bottom: 20px;
}

.previewControls {
    text-align: left;
    margin-bottom: 22px;
}

.previewControls .viewbutton {
    border: 1px solid #cccccc;
    cursor: pointer;
    margin-bottom: 0;
    text-align: center;
}

.previewControls .viewfront {
    margin-right: 4%;
}

.previewControls .viewback,
.previewControls .viewfront {
    width: 48%;
}

.previewPrint .back,
.previewPrint .flip-container,
.previewPrint .front {
    width: 100%;
    height: auto;
}

.previewPrint .front {
    position: relative;
    top: 0;
    left: 0;
}

.viewBtns {
    margin-bottom: 10px;
}

.previewViews {
    @extend .noselect;
}

.previewViews label {
    width: 100%;
    text-align: left;
    font-size: 18px;
    line-height: 19px;
    cursor: pointer;
    @include border-radius();
    padding: 5px 5px 5px 28px;
    cursor: pointer;
    @extend .boxsize;
    border: 1px solid #ccc;
    position: relative;
    margin-bottom: 10px;
}

.previewViews label span {
    margin: 0 !important;
    clear: both;
    position: absolute;
    left: 5px;
}

.previewLegend {
    width: 100%;
}

.previewLegend li {
    width: 33%;
    float: left;
    text-align: left;
    line-height: 18px;
    height: 40px;
    padding: 10px 0;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin: 0;
}

.previewLegend li span {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin: 0 5px -4px 0;
}

.bleedBox {
    background-color: red;
}

.trimBox {
    background-color: blue;
}

.safeBox {
    background-color: green;
}

.filePreview h4 {
    text-align: left;
    padding: 20px 0;
    font-size: 19px;
    color: #7c7c7c;
}

.filePreview h6 {
    text-align: center;
    padding: 0 0 16px;
    margin-bottom: 0;
}

.uploadRight {

}

.fileUpload {
    border: 1px solid #ddd;
    margin: 0 0 10px;
    position: relative;
    background: #fff;
    padding: 0 0 0 70px;
    min-height: 70px;
}

.fileUpload {
    cursor: pointer;
}

.fileUpload:hover {
    background: #f3f3f3;
}

.fileUpload input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 1001;
    cursor: pointer;
}

.upload-message {
    color: #000;
    margin: 0;
    .icon-download-arrow:before{
        margin-right: 5px;
    }
}

.upload-message a {
    color: $blueReg;
}

.fileUpload .icon {
    position: absolute;
    width: 70px;
    line-height: 70px;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.fileUpload .icon div {
    position: absolute;
    width: 70px;
    height: 70px;
    top: 0;
    left: 0;
    text-align: center;
    color: #ffffff;
    font-size: 35px;
    padding-top: 17px;
    @extend .boxsize;
}

.fileUpload .waiting {
    background: #cccccc;
}

.fileUpload .uploading {
    background: $blueReg;
}

.fileUpload .warning {
    background: #e7c900;
}

.fileUpload .approved {
    background: $blueReg;

    &.rejected {
        background: $attention;
    }
}

.fileUpload .pending {
    background: #c6e493;
}

.fileUpload .failed {
    background: $attention;
}

.fileUpload span {
    font-weight: bold;
}

.uploaderWrap {
    padding: 0;
    position: relative;
}

.uploaderWrap .progress {
    margin: 0;
    height: 15px;
}

.upload-message {
    padding-right: 38px;
}

.uploadStep2 .upload-message {
    padding-right: 80px;
}

.uploadStep3 .upload-message {
    padding-right: 0;
}

.uploaderWrap {
    padding: 0;
}

.sr-only {
    color: #000;
    z-index: 1002;
    position: absolute;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;
    clip: auto;
    border: 0;
    top: 12px;
    right: 10px;
}

.icon-spinner-2:before {
    -webkit-animation: rotation 3s infinite linear;
    display: inline-block;
}
@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

.removeFile {}

.fileActions {
    padding: 10px 0 0;
    border-top: 1px solid #ccc;
}

.fileActions ul {
    margin-bottom: 10px;
    width: 100%;
    display: block;
}

.fileActions li {
    font-size: 13px;
    margin: 0;
    padding-left: 10px;
    text-indent: -10px;
    line-height: 17px;
}

.fileActions li:before {
    content: "• ";
    color: #e7c900;
}

.fileActions .faileddesc li:before {
    content: "• ";
    color: $attention;
}

.fileActions .button {
    margin: 0 10px 5px 0;
    font-size: 13px !important;
    height: 25px;
    border: 1px solid #cdcdcc;
}

.button {
    @extend .noselect;
}

.button.nocolor {
    background: #f5f5f5;
    color: #000;
}

.button.nocolor:hover {
    background: #ffffff;
}

.button.errorcolor {
    background: #ffc4c4;
    color: #000;
}

.button.errorcolor:hover {
    background: #ffd6d6;
}

.button .removeFile {
    margin-left: 0;
    color: $dark;
    font-size: 15px;
}

.button sup {
    margin-top: -5px;
    display: inline-block;
}

.dateDelay {
    font-size: 11px;
    clear: both;
    padding: 0;
}

.deleteCommon {
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    width: 100%;
    height: 100%;
    z-index: 99999;
    padding-top: 17px;
}

.deleteCommon .button {
    margin-bottom: 0;
}

.uploadStep1 {
    padding: 14px 14px 12px;

    p {
        color: #777;

        span {
            color: #000;
        }
    }
}

.uploadApproved {
    padding: 24px 10px;
    position: relative;
}

.uploadVerify {
    padding: 4px 0;
}

.uploadVerify .button {
    margin: 0;
}

.uploadStep2 {
    position: relative;
    padding: 12px 10px;
}

.approved,
.failed,
.pending,
.uploadApproved,
.uploadCommon,
.uploadFailed,
.uploadPending,
.uploadStep1,
.uploadStep2,
.uploadStep3,
.uploadVerify,
.uploadWarning,
.uploading,
.waiting,
.warning {
    display: none;
}

.stepUpload .uploadStep1,
.stepUpload .waiting {
    display: block;
}

.stepUploading .uploadStep2,
.stepUploading .uploading {
    display: block;
}

.stepWarning .uploadStep3,
.stepWarning .uploadWarning,
.stepWarning .warning {
    display: block;
}

.stepPending .pending,
.stepPending .uploadPending,
.stepPending .uploadStep3 {
    display: block;
}

.stepCommon .pending,
.stepCommon .uploadCommon,
.stepCommon .uploadStep3 {
    display: block;
}

.stepVerify .approved,
.stepVerify .uploadStep3,
.stepVerify .uploadVerify {
    display: block;
}

.stepApproved .approved,
.stepApproved .uploadApproved,
.stepApproved .uploadStep3 {
    display: block;
}

.stepFailed .failed,
.stepFailed .uploadFailed,
.stepFailed .uploadStep3 {
    display: block;
}

.uploadCommon,
.uploadFailed,
.uploadPending,
.uploadVerify,
.uploadWarning {
    padding: 17px 10px;
}

.dz-preview {
    display: none;
}

.dz-drag-hover {
    background: #e6f4cb;
}

.approval {
    textarea {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #cdcdcd;
        background: #fbfbfb;
        font-weight: normal;
    }

    div.approval_step_2 {
        display: none;
    }
}

.adminBar {
    div {
        padding: 10px;
    }

    h1 {
        margin: 0;
        padding: 0;
        line-height: 38px;
    }

    .productDetails {
        margin-bottom: 0;
    }
}

.attention {
    background: #f3f0ba;
    padding: 8px 5px 8px 40px;
    @include border-radius();
    position: relative;
    margin-bottom: 20px;
}

.attention .notification {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    color: #bfbc83;
    position: absolute;
    left: 10px;
}

.attention a {
    color: $blueReg;
}

.attention.info {
    background: #badbff;
    padding: 8px 5px 8px 40px;
    @include border-radius();
    position: relative;
    margin-bottom: 20px;
}

.attention.info .notification {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    color: $blueReg;
    position: absolute;
    left: 10px;
}

.attention.info a {
    color: $blueReg;
}
