@import url("//cloud.typography.com/6205616/6411392/css/fonts.css");

@font-face {
    font-family: 'primo';
    font-display: auto;
    src:
      url('//cdn.primoprint.com/fonts/primo.ttf?8loy6x') format('truetype'),
      url('//cdn.primoprint.com/fonts/primo.woff?8loy6x') format('woff'),
      url('//cdn.primoprint.com/fonts/primo.svg?8loy6x#primo') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class*=" icon-"],
[class^="icon-"] {
    font-family: 'primo', 'Helvetica', 'sans-serif';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
    font-family: 'primo';
    content: attr(data-icon);
    speak: none;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /*line-height: 1;*/
    /*-webkit-font-smoothing: antialiased;*/
}
.icon-primo-badge:before {
  content: "\e93e";
}
.icon-primo-logodesign:before {
  content: "\e932";
}
.icon-primo-eddm:before {
  content: "\e933";
}
.icon-primo-blog:before {
  content: "\e921";
}
.icon-primo-samples:before {
  content: "\e926";
}
.icon-primo-design:before {
  content: "\e927";
}
.icon-primo-custom:before {
  content: "\e928";
}
.icon-primo-gallery:before {
  content: "\e929";
}
.icon-primo-templates:before {
  content: "\e92a";
}
.icon-primo-directmail:before {
  content: "\e92b";
}
.icon-primo-businesscard:before {
  content: "\e92c";
}
.icon-primo-eventmarketing:before {
  content: "\e92d";
}
.icon-primo-education:before {
  content: "\e92e";
}
.icon-primo-business:before {
  content: "\e92f";
}
.icon-primo-foodbeverage:before {
  content: "\e930";
}
.icon-primo-targeted:before {
  content: "\e931";
}
.icon-primo-designfaq:before {
  content: "\e934";
}
.icon-primo-modify:before {
  content: "\e935";
}
.icon-primo-fullbrand:before {
  content: "\e936";
}
.icon-primo-tradeshow:before {
  content: "\e937";
}
.icon-primo-sportsmarketing:before {
  content: "\e938";
}
.icon-primo-realestate:before {
  content: "\e939";
}
.icon-primo-political:before {
  content: "\e93a";
}
.icon-primo-nonprofit:before {
  content: "\e93b";
}
.icon-primo-stationery:before {
  content: "\e93c";
}
.icon-primo-health:before {
  content: "\e93d";
}
.icon-sad-face:before {
  content: "\e923";
}
.icon-check:before {
  content: "\e925";
}
.icon-heart:before {
  content: "\e922";
}
.icon-realestate-sign:before {
  content: "\e91f";
}
.icon-download:before {
  content: "\e97e";
}
.icon-star-check:before {
  content: "\e91b";
}
.icon-speed:before {
  content: "\e91c";
}
.icon-intercom:before {
  content: "\e91d";
}
.icon-flag:before {
  content: "\e91e";
}
.icon-linkedin2:before {
  content: "\e915";
}
.icon-instagram2:before {
  content: "\e90e";
}
.icon-facebook2:before {
  content: "\e90c";
}
.icon-twitter2:before {
  content: "\e90d";
}
.icon-applepay:before {
  content: "\e914";
}
.icon-discover:before {
  content: "\e913";
}
.icon-amex:before {
  content: "\e912";
}
.icon-visa:before {
  content: "\e90f";
}
.icon-paypal:before {
  content: "\e910";
}
.icon-mastercard:before {
  content: "\e911";
}
.icon-cart:before {
  content: "\e90b";
}
.icon-user-outline:before {
  content: "\e90a";
}
.icon-bag:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e908";
}
.icon-rss:before {
  content: "\e907";
}
.icon-terminal:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\e0dc";
}
.icon-primo-phone:before {
  content: "\e006";
}
.icon-primo-map:before {
  content: "\e03f";
}
.icon-primo-envelope:before {
  content: "\e048";
}
.icon-primo-streetsign:before {
  content: "\e049";
}
.icon-primo-chat:before {
  content: "\e04a";
}
.icon-primo-target:before {
  content: "\e04e";
}
.icon-undo:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e803";
}
.icon-dollar:before {
  content: "\e801";
}
.icon-megaphone:before {
  content: "\e802";
}
.icon-youtube:before {
  content: "\e924";
}
.icon-starburst:before {
  content: "\e920";
}
.icon-percent:before {
  content: "\e91a";
}
.icon-award:before {
  content: "\e916";
}
.icon-user-plus:before {
  content: "\e917";
}
.icon-multi-users:before {
  content: "\e918";
}
.icon-check-circle:before {
  content: "\e919";
}
.icon-menu:before {
  content: "\e905";
}
.icon-shipping:before {
  content: "\e902";
}
.icon-cross2:before {
  content: "\e900";
}
.icon-help2:before {
  content: "\e800";
}
.icon-download-arrow:before {
  content: "\e047";
}
.icon-arrow-down:before {
  content: "\e001";
}
.icon-googleplus:before {
  content: "\e003";
}
.icon-facebook:before {
  content: "\e004";
}
.icon-twitter:before {
  content: "\e005";
}
.icon-smiley:before {
  content: "\e002";
}
.icon-linkedin:before {
  content: "\e007";
}
.icon-tumblr:before {
  content: "\e008";
}
.icon-unlocked:before {
  content: "\e009";
}
.icon-credit:before {
  content: "\e00b";
}
.icon-box:before {
  content: "\e00c";
}
.icon-checkmark:before {
  content: "\e00d";
}
.icon-search:before {
  content: "\e00f";
}
.icon-folder:before {
  content: "\e00e";
}
.icon-newspaper:before {
  content: "\e010";
}
.icon-coin:before {
  content: "\e011";
}
.icon-close:before {
  content: "\e012";
}
.icon-corporate:before {
  content: "\e014";
}
.icon-reseller:before {
  content: "\e013";
}
.icon-referral:before {
  content: "\e015";
}
.icon-pencil:before {
  content: "\e016";
}
.icon-cross:before {
  content: "\e017";
}
.icon-tag:before {
  content: "\e018";
}
.icon-notification:before {
  content: "\e019";
}
.icon-burst:before {
  content: "\e01a";
}
.icon-office:before {
  content: "\e01b";
}
.icon-arrow-up:before {
  content: "\e01c";
}
.icon-plus:before {
  content: "\e01d";
}
.icon-minus:before {
  content: "\e01e";
}
.icon-mail:before {
  content: "\e01f";
}
.icon-images:before {
  content: "\e020";
}
.icon-hourglass:before {
  content: "\e021";
}
.icon-shield:before {
  content: "\e022";
}
.icon-cube:before {
  content: "\e023";
}
.icon-file-download:before {
  content: "\e024";
}
.icon-stack-list:before {
  content: "\e025";
}
.icon-earth:before {
  content: "\e026";
}
.icon-list:before {
  content: "\e027";
}
.icon-bubble:before {
  content: "\e028";
}
.icon-checkbox:before {
  content: "\e029";
}
.icon-rulers:before {
  content: "\e02a";
}
.icon-file-check:before {
  content: "\e02b";
}
.icon-file-remove:before {
  content: "\e02c";
}
.icon-stack-picture:before {
  content: "\e02d";
}
.icon-users:before {
  content: "\e02e";
}
.icon-bubble-2:before {
  content: "\e030";
}
.icon-pencil-2:before {
  content: "\e02f";
}
.icon-arrow:before {
  content: "\e031";
}
.icon-lock:before {
  content: "\e032";
}
.icon-info:before {
  content: "\e034";
}
.icon-file-upload:before {
  content: "\e035";
}
.icon-spinner:before {
  content: "\e036";
}
.icon-spinner-2:before {
  content: "\e037";
}
.icon-warning:before {
  content: "\e038";
}
.icon-check-alt:before {
  content: "\e03a";
}
.icon-arrow-right:before {
  content: "\e039";
}
.icon-arrow-up-2:before {
  content: "\e03b";
}
.icon-arrow-down-2:before {
  content: "\e03c";
}
.icon-arrow-left:before {
  content: "\e03d";
}
.icon-eye:before {
  content: "\e03e";
}
.icon-reading:before {
  content: "\e040";
}
.icon-users-2:before {
  content: "\e041";
}
.icon-clock:before {
  content: "\e042";
}
.icon-thumbs-up:before {
  content: "\e043";
}
.icon-user:before {
  content: "\e00a";
}
.icon-help:before {
  content: "\e033";
}
.icon-play:before {
  content: "\e044";
}
.icon-search-2:before {
  content: "\e045";
}
.icon-search-thin:before {
  content: "\e046";
}
.icon-snapchat:before {
  content: "\e904";
}
.icon-youtube-circle:before {
  content: "\e903";
}
