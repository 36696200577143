@charset "UTF-8";
/*!
 * PRIMOPRINT®
 * Copyright 2018 Primoprint
 */
/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url("//cloud.typography.com/6205616/6411392/css/fonts.css");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
  width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014 \00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table .table {
  background-color: #fff;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #212529;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:valid, .form-control.is-valid, .was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::after, .custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::after, .custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn, .galleryLink {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn, .galleryLink {
    transition: none;
  }
}

.btn:hover, .galleryLink:hover, .btn:focus, .galleryLink:focus {
  text-decoration: none;
}

.btn:focus, .galleryLink:focus, .btn.focus, .focus.galleryLink {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .disabled.galleryLink, .btn:disabled, .galleryLink:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled), .galleryLink:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled, a.disabled.galleryLink,
fieldset:disabled a.btn,
fieldset:disabled a.galleryLink {
  pointer-events: none;
}

.btn-primary, .galleryLink {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover, .galleryLink:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .galleryLink:focus, .btn-primary.focus, .focus.galleryLink {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-primary.disabled, .disabled.galleryLink, .btn-primary:disabled, .galleryLink:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .galleryLink:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .galleryLink:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.galleryLink {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .galleryLink:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .galleryLink:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.galleryLink:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn, .btn-group-lg > .galleryLink {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn, .btn-group-sm > .galleryLink {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn, .btn-group > .galleryLink,
.btn-group-vertical > .btn,
.btn-group-vertical > .galleryLink {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.btn-group > .btn:hover, .btn-group > .galleryLink:hover,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .galleryLink:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .galleryLink:focus, .btn-group > .btn:active, .btn-group > .galleryLink:active, .btn-group > .btn.active, .btn-group > .active.galleryLink,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .galleryLink:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .galleryLink:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.galleryLink {
  z-index: 1;
}

.btn-group .btn + .btn, .btn-group .galleryLink + .btn, .btn-group .btn + .galleryLink, .btn-group .galleryLink + .galleryLink,
.btn-group .btn + .btn-group,
.btn-group .galleryLink + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .galleryLink,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .galleryLink + .btn,
.btn-group-vertical .btn + .galleryLink,
.btn-group-vertical .galleryLink + .galleryLink,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .galleryLink + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .galleryLink,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:first-child, .btn-group > .galleryLink:first-child {
  margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .galleryLink:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .galleryLink {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child), .btn-group > .galleryLink:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .galleryLink {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-group-sm > .galleryLink + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-lg > .galleryLink + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-group-vertical .btn, .btn-group-vertical .galleryLink,
.btn-group-vertical .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .galleryLink + .btn, .btn-group-vertical > .btn + .galleryLink, .btn-group-vertical > .galleryLink + .galleryLink,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .galleryLink + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .galleryLink,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .galleryLink:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .galleryLink {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .galleryLink:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .galleryLink {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn, .btn-group-toggle > .galleryLink,
.btn-group-toggle > .btn-group > .btn,
.btn-group-toggle > .btn-group > .galleryLink {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"], .btn-group-toggle > .galleryLink input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .galleryLink input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .galleryLink input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .galleryLink input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}

.input-group-prepend .btn, .input-group-prepend .galleryLink,
.input-group-append .btn,
.input-group-append .galleryLink {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn + .btn, .input-group-prepend .galleryLink + .btn, .input-group-prepend .btn + .galleryLink, .input-group-prepend .galleryLink + .galleryLink,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .galleryLink + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-prepend .input-group-text + .galleryLink,
.input-group-append .btn + .btn,
.input-group-append .galleryLink + .btn,
.input-group-append .btn + .galleryLink,
.input-group-append .galleryLink + .galleryLink,
.input-group-append .btn + .input-group-text,
.input-group-append .galleryLink + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn,
.input-group-append .input-group-text + .galleryLink {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-prepend > .galleryLink,
.input-group-lg > .input-group-append > .btn,
.input-group-lg > .input-group-append > .galleryLink {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-prepend > .galleryLink,
.input-group-sm > .input-group-append > .btn,
.input-group-sm > .input-group-append > .galleryLink {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .galleryLink,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .galleryLink,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .galleryLink:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .galleryLink,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .galleryLink,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .galleryLink:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge, .galleryLink .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.badge-primary[href]:hover, .badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}

.badge-secondary[href]:hover, .badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}

.badge-light[href]:hover, .badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}

.badge-dark[href]:hover, .badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.alert-primary hr {
  border-top-color: #9fcdff;
}

.alert-primary .alert-link {
  color: #002752;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}

.alert-secondary hr {
  border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-success hr {
  border-top-color: #b1dfbb;
}

.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}

.alert-info hr {
  border-top-color: #abdde5;
}

.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.alert-warning hr {
  border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-danger hr {
  border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light hr {
  border-top-color: #ececf6;
}

.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}

.alert-dark hr {
  border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
  color: #040505;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item:hover, .list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

@supports (-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}

.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}

.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .galleryLink,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .galleryLink,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1, .galleryLink {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2, .galleryLink,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2, .galleryLink,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0062cc !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #545b62 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #117a8b !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #d39e00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #bd2130 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1d2124 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn):not(.galleryLink) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}

/*# sourceMappingURL=bootstrap.css.map */
@font-face {
  font-family: 'primo';
  font-display: auto;
  src: url("//cdn.primoprint.com/fonts/primo.ttf?8loy6x") format("truetype"), url("//cdn.primoprint.com/fonts/primo.woff?8loy6x") format("woff"), url("//cdn.primoprint.com/fonts/primo.svg?8loy6x#primo") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class*=" icon-"],
[class^="icon-"] {
  font-family: 'primo', 'Helvetica', 'sans-serif';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-icon]:before {
  font-family: 'primo';
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /*line-height: 1;*/
  /*-webkit-font-smoothing: antialiased;*/
}

.icon-primo-badge:before {
  content: "\e93e";
}

.icon-primo-logodesign:before {
  content: "\e932";
}

.icon-primo-eddm:before {
  content: "\e933";
}

.icon-primo-blog:before {
  content: "\e921";
}

.icon-primo-samples:before {
  content: "\e926";
}

.icon-primo-design:before {
  content: "\e927";
}

.icon-primo-custom:before {
  content: "\e928";
}

.icon-primo-gallery:before {
  content: "\e929";
}

.icon-primo-templates:before {
  content: "\e92a";
}

.icon-primo-directmail:before {
  content: "\e92b";
}

.icon-primo-businesscard:before {
  content: "\e92c";
}

.icon-primo-eventmarketing:before {
  content: "\e92d";
}

.icon-primo-education:before {
  content: "\e92e";
}

.icon-primo-business:before {
  content: "\e92f";
}

.icon-primo-foodbeverage:before {
  content: "\e930";
}

.icon-primo-targeted:before {
  content: "\e931";
}

.icon-primo-designfaq:before {
  content: "\e934";
}

.icon-primo-modify:before {
  content: "\e935";
}

.icon-primo-fullbrand:before {
  content: "\e936";
}

.icon-primo-tradeshow:before {
  content: "\e937";
}

.icon-primo-sportsmarketing:before {
  content: "\e938";
}

.icon-primo-realestate:before {
  content: "\e939";
}

.icon-primo-political:before {
  content: "\e93a";
}

.icon-primo-nonprofit:before {
  content: "\e93b";
}

.icon-primo-stationery:before {
  content: "\e93c";
}

.icon-primo-health:before {
  content: "\e93d";
}

.icon-sad-face:before {
  content: "\e923";
}

.icon-check:before {
  content: "\e925";
}

.icon-heart:before {
  content: "\e922";
}

.icon-realestate-sign:before {
  content: "\e91f";
}

.icon-download:before {
  content: "\e97e";
}

.icon-star-check:before {
  content: "\e91b";
}

.icon-speed:before {
  content: "\e91c";
}

.icon-intercom:before {
  content: "\e91d";
}

.icon-flag:before {
  content: "\e91e";
}

.icon-linkedin2:before {
  content: "\e915";
}

.icon-instagram2:before {
  content: "\e90e";
}

.icon-facebook2:before {
  content: "\e90c";
}

.icon-twitter2:before {
  content: "\e90d";
}

.icon-applepay:before {
  content: "\e914";
}

.icon-discover:before {
  content: "\e913";
}

.icon-amex:before {
  content: "\e912";
}

.icon-visa:before {
  content: "\e90f";
}

.icon-paypal:before {
  content: "\e910";
}

.icon-mastercard:before {
  content: "\e911";
}

.icon-cart:before {
  content: "\e90b";
}

.icon-user-outline:before {
  content: "\e90a";
}

.icon-bag:before {
  content: "\e909";
}

.icon-instagram:before {
  content: "\e908";
}

.icon-rss:before {
  content: "\e907";
}

.icon-terminal:before {
  content: "\e901";
}

.icon-phone:before {
  content: "\e0dc";
}

.icon-primo-phone:before {
  content: "\e006";
}

.icon-primo-map:before {
  content: "\e03f";
}

.icon-primo-envelope:before {
  content: "\e048";
}

.icon-primo-streetsign:before {
  content: "\e049";
}

.icon-primo-chat:before {
  content: "\e04a";
}

.icon-primo-target:before {
  content: "\e04e";
}

.icon-undo:before {
  content: "\e906";
}

.icon-edit:before {
  content: "\e803";
}

.icon-dollar:before {
  content: "\e801";
}

.icon-megaphone:before {
  content: "\e802";
}

.icon-youtube:before {
  content: "\e924";
}

.icon-starburst:before {
  content: "\e920";
}

.icon-percent:before {
  content: "\e91a";
}

.icon-award:before {
  content: "\e916";
}

.icon-user-plus:before {
  content: "\e917";
}

.icon-multi-users:before {
  content: "\e918";
}

.icon-check-circle:before {
  content: "\e919";
}

.icon-menu:before {
  content: "\e905";
}

.icon-shipping:before {
  content: "\e902";
}

.icon-cross2:before {
  content: "\e900";
}

.icon-help2:before {
  content: "\e800";
}

.icon-download-arrow:before {
  content: "\e047";
}

.icon-arrow-down:before {
  content: "\e001";
}

.icon-googleplus:before {
  content: "\e003";
}

.icon-facebook:before {
  content: "\e004";
}

.icon-twitter:before {
  content: "\e005";
}

.icon-smiley:before {
  content: "\e002";
}

.icon-linkedin:before {
  content: "\e007";
}

.icon-tumblr:before {
  content: "\e008";
}

.icon-unlocked:before {
  content: "\e009";
}

.icon-credit:before {
  content: "\e00b";
}

.icon-box:before {
  content: "\e00c";
}

.icon-checkmark:before {
  content: "\e00d";
}

.icon-search:before {
  content: "\e00f";
}

.icon-folder:before {
  content: "\e00e";
}

.icon-newspaper:before {
  content: "\e010";
}

.icon-coin:before {
  content: "\e011";
}

.icon-close:before {
  content: "\e012";
}

.icon-corporate:before {
  content: "\e014";
}

.icon-reseller:before {
  content: "\e013";
}

.icon-referral:before {
  content: "\e015";
}

.icon-pencil:before {
  content: "\e016";
}

.icon-cross:before {
  content: "\e017";
}

.icon-tag:before {
  content: "\e018";
}

.icon-notification:before {
  content: "\e019";
}

.icon-burst:before {
  content: "\e01a";
}

.icon-office:before {
  content: "\e01b";
}

.icon-arrow-up:before {
  content: "\e01c";
}

.icon-plus:before {
  content: "\e01d";
}

.icon-minus:before {
  content: "\e01e";
}

.icon-mail:before {
  content: "\e01f";
}

.icon-images:before {
  content: "\e020";
}

.icon-hourglass:before {
  content: "\e021";
}

.icon-shield:before {
  content: "\e022";
}

.icon-cube:before {
  content: "\e023";
}

.icon-file-download:before {
  content: "\e024";
}

.icon-stack-list:before {
  content: "\e025";
}

.icon-earth:before {
  content: "\e026";
}

.icon-list:before {
  content: "\e027";
}

.icon-bubble:before {
  content: "\e028";
}

.icon-checkbox:before {
  content: "\e029";
}

.icon-rulers:before {
  content: "\e02a";
}

.icon-file-check:before {
  content: "\e02b";
}

.icon-file-remove:before {
  content: "\e02c";
}

.icon-stack-picture:before {
  content: "\e02d";
}

.icon-users:before {
  content: "\e02e";
}

.icon-bubble-2:before {
  content: "\e030";
}

.icon-pencil-2:before {
  content: "\e02f";
}

.icon-arrow:before {
  content: "\e031";
}

.icon-lock:before {
  content: "\e032";
}

.icon-info:before {
  content: "\e034";
}

.icon-file-upload:before {
  content: "\e035";
}

.icon-spinner:before {
  content: "\e036";
}

.icon-spinner-2:before {
  content: "\e037";
}

.icon-warning:before {
  content: "\e038";
}

.icon-check-alt:before {
  content: "\e03a";
}

.icon-arrow-right:before {
  content: "\e039";
}

.icon-arrow-up-2:before {
  content: "\e03b";
}

.icon-arrow-down-2:before {
  content: "\e03c";
}

.icon-arrow-left:before {
  content: "\e03d";
}

.icon-eye:before {
  content: "\e03e";
}

.icon-reading:before {
  content: "\e040";
}

.icon-users-2:before {
  content: "\e041";
}

.icon-clock:before {
  content: "\e042";
}

.icon-thumbs-up:before {
  content: "\e043";
}

.icon-user:before {
  content: "\e00a";
}

.icon-help:before {
  content: "\e033";
}

.icon-play:before {
  content: "\e044";
}

.icon-search-2:before {
  content: "\e045";
}

.icon-search-thin:before {
  content: "\e046";
}

.icon-snapchat:before {
  content: "\e904";
}

.icon-youtube-circle:before {
  content: "\e903";
}

/**
COLOR
*/
.bgwhite {
  background: #ffffff;
}

.bgwhite.hover:hover, .bgwhite.galleryLink:hover {
  background: #ffffff;
}

.bgwhite.colorfirst {
  color: #283655;
}

.bgdark {
  background: #1e1f26;
  color: #ffffff;
}

.bgdark.hover:hover, .bgdark.galleryLink:hover {
  background: #1e1f26;
  color: #ffffff;
}

.bgdark a {
  color: #ffffff;
}

.bgfirst {
  background: #283655;
  color: #ffffff;
}

.bgfirst.hover:hover, .bgfirst.galleryLink:hover {
  background: #4D648d;
  color: #ffffff;
}

.bgfirst.disabled {
  color: #ffffff;
}

.bgfirst.colorwhite, .bgfirst a {
  color: #ffffff;
}

.bgfirst.light {
  background: #4D648d;
}

.bgfirst.light.hover:hover, .bgfirst.light.galleryLink:hover {
  background: #728fc1;
}

.bgred {
  background: #E75666;
  border: 2px solid #E75666;
  color: #ffffff;
}

.bgred.hover:hover, .bgred.galleryLink:hover {
  background: #f26675;
  border: 2px solid #f26675;
  color: #ffffff;
}

.bgred.colorwhite, .bgred a {
  color: #ffffff;
}

.bgred.outline {
  background: #ffffff;
  border: 2px solid #E75666;
  color: #E75666;
}

.bgred.outline.hover:hover, .bgred.outline.galleryLink:hover {
  background: #ffffff;
  border: 2px solid #f26675;
  color: #f26675;
}

.bgattention {
  background: #c30000;
  border: 2px solid #c30000;
  color: #ffffff;
}

.bgattention.hover:hover, .bgattention.galleryLink:hover {
  background: #d91e1e;
  border: 2px solid #d91e1e;
  color: #ffffff;
}

.bgattention.colorwhite, .bgattention a {
  color: #ffffff;
}

.bgattention.outline {
  background: #ffffff;
  border: 2px solid #c30000;
  color: #c30000;
}

.bgattention.outline.hover:hover, .bgattention.outline.galleryLink:hover {
  background: #ffffff;
  border: 2px solid #d91e1e;
  color: #d91e1e;
}

.bgblue {
  background: #2fa6e5;
  border: 2px solid #2fa6e5;
  color: #ffffff;
}

.bgblue.hover:hover, .bgblue.galleryLink:hover {
  background: #4cb7f0;
  border: 2px solid #4cb7f0;
  color: #ffffff;
}

.bgblue.colorwhite, .bgblue a {
  color: #ffffff;
}

.bgblue.outline {
  background: #ffffff;
  border: 2px solid #2fa6e5;
  color: #2fa6e5;
}

.bgblue.outline.hover:hover, .bgblue.outline.galleryLink:hover {
  background: #ffffff;
  border: 2px solid #4cb7f0;
  color: #4cb7f0;
}

.bgsecond, .galleryLink {
  background: #3a3a3a;
  color: #ffffff;
}

.bgsecond.hover:hover, .galleryLink:hover {
  background: #636363;
}

.bgsecond a, .galleryLink a {
  color: #ffffff;
}

.bgthird {
  background: #f4f1ec;
}

.bgfourth {
  background: #cacaca;
  color: #ffffff;
}

.bgfourth.hover:hover, .bgfourth.galleryLink:hover {
  background: #adadad;
  color: #ffffff;
}

.bgfourth.dark {
  background: #636363;
}

.bgfourth a {
  color: #ffffff;
}

.bgfourth.disabled {
  color: #ffffff;
}

.bgfourth.light {
  background: #f6f6f6;
}

.bgfifth {
  background: #9e0b0f;
  color: #ffffff;
}

.bgfifth.hover:hover, .bgfifth.galleryLink:hover {
  background: #b8181c;
  color: #ffffff;
}

.bgsixth {
  background-color: #fafafa;
  color: #3a3a3a;
}

.bgsixth.dark {
  background: #cacaca;
}

.bgsixth a {
  color: #3a3a3a;
}

.bgseventh {
  background: #f6f6f6;
}

.bgeighth {
  background-color: #fafafa;
}

.bgerror {
  background: #c30000;
  color: #ffffff;
}

.bgerror.hover:hover, .bgerror.galleryLink:hover {
  background: #db3a3a;
}

.bgerror a {
  color: #ffffff;
}

.bghighlight {
  background: rgba(250, 255, 153, 0.3);
}

.bgshadow {
  background: rgba(0, 0, 0, 0.5);
}

.lightlink a {
  color: #ffffff;
  text-decoration: underline;
}

.bgnone {
  background: none;
}

.colordark {
  color: #1e1f26;
}

.colordark a {
  color: #1e1f26;
}

.colordark.hover a:hover, .colordark.galleryLink a:hover {
  color: #728fc1;
}

.colordark.nohover {
  color: #1e1f26;
}

.colordark.selected {
  color: #283655;
}

.colorwhite {
  color: #ffffff;
}

.colorwhite:hover {
  color: #ffffff;
}

.colorwhite a {
  color: #ffffff;
}

.colorwhite.nohover {
  color: #ffffff;
}

.colorblack {
  color: #000000 !important;
}

.colorblack:hover {
  color: #000000;
}

.colorblack a {
  color: #000000;
}

.colorblack.nohover {
  color: #000000;
}

.colorattention {
  color: #c30000;
}

.colorcoral {
  color: #E75666;
}

.button-coral {
  background: #E75666;
  color: #ffffff;
}

.button-coral:hover {
  background: #f26675;
  color: #ffffff;
}

.button-coral.outline {
  background: none;
  border-color: #E75666;
  color: #283655;
  border-style: solid;
}

.button-coral.outline:hover {
  background: none;
  border-color: #f26675;
  color: #4D648d;
}

.button-blue {
  background: #283655;
  color: #ffffff;
}

.button-blue:hover {
  background: #4D648d;
  color: #ffffff;
}

.button-blue.outline {
  background: none;
  border-color: #283655;
  color: #283655;
  border-style: solid;
}

.button-blue.outline:hover {
  background: none;
  border-color: #4D648d;
  color: #4D648d;
}

.colorgreen {
  color: #8FD944;
}

.bgattention {
  background-color: #c30000;
}

.colorfirst {
  color: #283655;
}

.colorfirst.hover:hover, .colorfirst.galleryLink:hover {
  color: #4D648d;
}

.colorsecond {
  color: #4D648d;
}

.colorsecond a {
  color: #4D648d;
}

.colorsecond a:hover {
  color: #728fc1;
}

.colorsecond.hover:hover, .colorsecond.galleryLink:hover {
  color: #728fc1;
}

.colorthird {
  color: #728fc1;
}

.colorthird a {
  color: #728fc1;
}

.colorthird a:hover {
  color: #D0E1F9;
}

.colorthird.hover:hover, .colorthird.galleryLink:hover {
  color: #D0E1F9;
}

.colordarkgray {
  color: #3a3a3a;
}

.colorgray {
  color: #636363;
}

.colorgray a {
  color: #636363;
}

.colorlightgray {
  color: #adadad;
}

.colorlightgray a {
  color: #adadad;
}

.colorlightgray.nohover {
  color: #adadad;
}

a.preventhover {
  color: inherit !important;
}

a.preventhover:visited, a.preventhover:hover, a.preventhover:active {
  color: inherit !important;
}

a.althover img, a.althover div, a.althover p {
  -webkit-backface-visibility: hidden;
}

a.althover:hover img {
  opacity: .8;
}

a.althover:hover .bgfirst {
  background: #4D648d;
}

a.althover:hover .bgfourth {
  background: #fafafa;
}

a.althover:hover .colorsecond {
  color: #728fc1;
}

.hoverbglight:hover {
  background: rgba(255, 255, 255, 0.1);
}

.hoverbglight.selected {
  background: rgba(255, 255, 255, 0.2);
}

.hoverdark:hover {
  background: #283655;
  border-color: #283655;
  color: #ffffff;
}

.grouphover:hover .hoverdark {
  background: #283655;
  border-color: #283655;
  color: #ffffff;
}

.grouphover:hover .bgfifth {
  background: #b8181c;
  color: #fff;
}

.borderfirst {
  border: 1px solid #283655;
}

.borderfirst.hover:hover, .borderfirst.galleryLink:hover {
  border: 1px solid #4D648d;
}

.bordersecond {
  border: 1px solid #3a3a3a;
}

.bordersecond.light {
  border: 1px solid #cacaca;
}

.borderthird {
  border: 1px solid #adadad;
}

.borderthird.light {
  border: 1px solid #cacaca;
}

.borderfourth {
  border: 1px solid #c30000;
}

.borderfifth {
  border: 1px solid #e9ecef;
}

.borderwhite {
  border: 1px solid #ffffff;
}

.bordergray {
  border-color: #cacaca;
}

.bordergray.dark {
  border-color: #636363;
}

.inputrequired {
  border: 1px solid #c30000 !important;
}

/* right bounce */
@-webkit-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

@-moz-keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}

@keyframes bounceRight {
  0%,
  20%,
  50%,
  80%,
  100% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
  40% {
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  60% {
    -ms-transform: translateX(-5px);
    transform: translateX(-5px);
  }
}

.bounce-right {
  -webkit-animation: bounceRight 2s infinite;
  animation: bounceRight 2s infinite;
}

/* /right bounce */
.vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-align-ab, .inputwrap.placeholder.select:after, .reqit, .preview.horizontal .hangtaghole {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-align-fix {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-align {
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.horizontal-align-ab, .successburst, .listbuilderSteps .step:nth-child(2) .steptooltip, .listbuilderSteps .step:nth-child(2) .steptooltip img, .listbuilderSteps .step:nth-child(3) .steptooltip, .listbuilderSteps .step:nth-child(3) .steptooltip img, .preview.vertical .hangtaghole {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.horizontal-align-fix {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.center-align {
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-align-fix {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.center-align-ab, .preview .hangtaghole .drillsafe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes shake {
  0% {
    transform: translate(2px, 0px);
  }
  10% {
    transform: translate(-2px, 0px);
  }
  20% {
    transform: translate(2px, 0px);
  }
  30% {
    transform: translate(-2px, 0px);
  }
  40% {
    transform: translate(2px, 0px);
  }
  50% {
    transform: translate(-2px, 0px);
  }
  60% {
    transform: translate(2px, 0px);
  }
  70% {
    transform: translate(-2px, 0px);
  }
  80% {
    transform: translate(2px, 0px);
  }
  90% {
    transform: translate(-2px, 0px);
  }
  100% {
    transform: translate(2px, 0px);
  }
}

@-moz-keyframes shake {
  0% {
    -moz-transform: translate(2px, 0px);
  }
  10% {
    -moz-transform: translate(-2px, 0px);
  }
  20% {
    -moz-transform: translate(2px, 0px);
  }
  30% {
    -moz-transform: translate(-2px, 0px);
  }
  40% {
    -moz-transform: translate(2px, 0px);
  }
  50% {
    -moz-transform: translate(-2px, 0px);
  }
  60% {
    -moz-transform: translate(2px, 0px);
  }
  70% {
    -moz-transform: translate(-2px, 0px);
  }
  80% {
    -moz-transform: translate(2px, 0px);
  }
  90% {
    -moz-transform: translate(-2px, 0px);
  }
  100% {
    -moz-transform: translate(2px, 0px);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(2px, 0px);
  }
  10% {
    -webkit-transform: translate(-2px, 0px);
  }
  20% {
    -webkit-transform: translate(2px, 0px);
  }
  30% {
    -webkit-transform: translate(-2px, 0px);
  }
  40% {
    -webkit-transform: translate(2px, 0px);
  }
  50% {
    -webkit-transform: translate(-2px, 0px);
  }
  60% {
    -webkit-transform: translate(2px, 0px);
  }
  70% {
    -webkit-transform: translate(-2px, 0px);
  }
  80% {
    -webkit-transform: translate(2px, 0px);
  }
  90% {
    -webkit-transform: translate(-2px, 0px);
  }
  100% {
    -webkit-transform: translate(2px, 0px);
  }
}

.shake {
  animation-name: shake;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: 200ms;
  animation-timing-function: linear;
  -moz-animation-name: shake;
  -moz-animation-duration: 0.8s;
  -moz-transform-origin: 50% 50%;
  -moz-animation-iteration-count: 200ms;
  -moz-animation-timing-function: linear;
  -webkit-animation-name: shake;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: 200ms;
  -webkit-animation-timing-function: linear;
}

.animate2, a.althover img, a.althover div, a.althover p, .hoverdark, .hover-show, .step-form.light button, .step-form.dark button, .nav-search input::placeholder, .inputwrap.placeholder, .inputwrap.placeholder.select, .inputwrap.placeholder.upload, a, .modal-header .close,
.modal-header .close:focus,
.modal-header .close:hover {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.animateopacity, .nav-wrap .site-header {
  -webkit-transition: opacity .2s ease;
  -moz-transition: opacity .2s ease;
  -o-transition: opacity .2s ease;
  transition: opacity .2s ease;
}

.shadow {
  box-shadow: 0px 12px 20px 0px rgba(0, 0, 0, 0.15);
}

.shadowflat {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.15);
}

.shadowlight {
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.07);
}

.rotate0 {
  -webkit-transform: rotate(0deg) translate3d(0, 0, 0);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -o-transition: .2s;
  -ms-transition: .2s;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  transition: .2s;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
}

.rotate90 {
  -webkit-transform: rotate(90deg) translate3d(0, 0, 0);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -o-transition: .2s;
  -ms-transition: .2s;
  -moz-transition: .2s;
  -webkit-transition: .2s;
  transition: .2s;
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
}

.desktop-hide {
  display: none;
}

.hide {
  display: none;
}

.hidestrong {
  display: none !important;
}

.hibernate {
  display: none !important;
}

.displayblock {
  display: block;
}

.d-inline-table {
  display: inline-table;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.overflowhide {
  overflow: hidden;
}

.overflowshow {
  overflow: visible;
}

.outlinenone {
  outline: none;
}

.autofillnone:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}

.pointer {
  cursor: pointer;
}

.nopointer {
  cursor: not-allowed;
}

.zoomhover {
  transition: transform .2s;
}

.zoomhover:hover {
  transform: scale(1.02);
}

.col-center {
  float: none;
  margin: 0 auto;
}

.m-0-auto {
  margin: 0 auto;
}

.clear {
  clear: both;
}

.clearright {
  clear: right;
}

.group:before,
.group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

.group {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

.boxsize, .inputwrap.placeholder.select, .inputwrap.placeholder.upload, .preview .hangtaghole .drillsafe, .previewViews label, .fileUpload .icon div {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.absolute-top {
  position: absolute;
  top: 0;
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: 1em;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.nowrap {
  white-space: nowrap;
}

.imgauto, .vendordesc img, .imgauto img, .vendordesc img img {
  width: 100%;
  height: auto;
}

.imgcover, .imgcover img {
  width: auto;
  height: auto;
  object-fit: cover;
}

.backgroundcover {
  background-repeat: no-repeat;
  background-size: cover;
}

.max-100 {
  max-height: 100%;
}

.max-600 {
  max-width: 600px;
  margin: 0 auto;
}

.max-700 {
  max-width: 700px;
  margin: 0 auto;
}

.max-850 {
  max-width: 850px;
  margin: 0 auto;
}

.max-900 {
  max-width: 900px;
  margin: 0 auto;
}

.max-1000 {
  max-width: 1000px;
  margin: 0 auto;
}

.max-1100 {
  max-width: 1100px;
  margin: 0 auto;
}

.min-300 {
  min-width: 300px;
  margin: 0 auto;
}

.min-200 {
  min-width: 200px;
  margin: 0 auto;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-50 {
  z-index: 50 !important;
}

.z-index-max {
  z-index: 16777216;
}

.z-index-max-1 {
  z-index: 16777215;
}

.radius-0 {
  border-radius: 0em;
}

.radius-025 {
  border-radius: .25em;
}

.radius-05 {
  border-radius: .5em;
}

.radius-1 {
  border-radius: 1em;
}

.radius-2 {
  border-radius: 2em;
}

.radius-3 {
  border-radius: 3em;
}

.radius-30 {
  border-radius: 30em;
}

hr.white {
  border-color: #ffffff;
}

h1, .h1 {
  font-size: 2.5em;
}

h2, .h2 {
  font-size: 2em;
}

h3, .h3 {
  font-size: 1.75em;
}

h4, .h4 {
  font-size: 1.5em;
}

h5, .h5 {
  font-size: 1.25em;
}

h6, .h6 {
  font-size: 1em;
}

.lineheight-normal {
  line-height: normal;
}

.lineheight-05 {
  line-height: .5em;
}

.lineheight-75 {
  line-height: .75em;
}

.lineheight-85 {
  line-height: .85em;
}

.lineheight-1 {
  line-height: 1em;
}

.lineheight-115 {
  line-height: 1.15em;
}

.lineheight-125 {
  line-height: 1.25em;
}

.lineheight-15 {
  line-height: 1.5em;
}

.lineheight-165 {
  line-height: 1.65em;
}

.lineheight-175 {
  line-height: 1.75em;
}

.lineheight-2 {
  line-height: 2em;
}

.lineheight-250 {
  line-height: 2.5em;
}

.lineheight-3 {
  line-height: 3em;
}

.lineheight-4 {
  line-height: 4em;
}

.type-4 {
  font-size: 4em;
}

.type-350 {
  font-size: 3.5em;
}

.type-3 {
  font-size: 3em;
}

.type-250 {
  font-size: 2.5em;
}

.type-225 {
  font-size: 2.25em;
}

.type-2 {
  font-size: 2em;
}

.type-175 {
  font-size: 1.75em;
}

.type-165 {
  font-size: 1.75em;
}

.type-150 {
  font-size: 1.5em;
}

.type-135 {
  font-size: 1.35em;
}

.type-125 {
  font-size: 1.25em;
}

.type-115 {
  font-size: 1.15em;
}

.type-1, .galleryLink {
  font-size: 1em;
}

.type-90 {
  font-size: .90em;
}

.type-85 {
  font-size: .85em;
}

.type-80 {
  font-size: .80em;
}

.type-75 {
  font-size: .75em;
}

.type-66 {
  font-size: .66em;
}

.type-50 {
  font-size: .5em;
}

.type-33 {
  font-size: .33em;
}

.type-25 {
  font-size: .25em;
}

.type-rem-4 {
  font-size: 4rem;
}

.type-rem-3 {
  font-size: 3rem;
}

.type-rem-250 {
  font-size: 2.5rem;
}

.type-rem-2 {
  font-size: 2rem;
}

.type-rem-175 {
  font-size: 1.75rem;
}

.type-rem-150 {
  font-size: 1.5rem;
}

.type-rem-125 {
  font-size: 1.25rem;
}

.type-rem-115 {
  font-size: 1.15rem;
}

.type-rem-1 {
  font-size: 1rem;
}

.type-rem-90 {
  font-size: .90rem;
}

.type-rem-85 {
  font-size: .85rem;
}

.type-rem-80 {
  font-size: .80rem;
}

.type-rem-75 {
  font-size: .75rem;
}

.type-rem-66 {
  font-size: .66rem;
}

.type-rem-50 {
  font-size: .5rem;
}

.type-rem-33 {
  font-size: .33rem;
}

.type-rem-25 {
  font-size: .25rem;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.noline, .galleryLink {
  text-decoration: none;
}

.noline:hover, .galleryLink:hover {
  text-decoration: none;
}

.noline a, .galleryLink a {
  text-decoration: none;
}

.noline a:hover, .galleryLink a:hover {
  text-decoration: none;
}

.nolist li {
  list-style: none !important;
}

.dotted {
  text-decoration: none;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.dotted:hover {
  text-decoration: none;
}

.dotted.light {
  border-bottom: 1px dotted #ffffff;
}

.dotted.dark {
  border-bottom: 1px dotted #1e1f26;
}

.line a {
  text-decoration: underline;
}

.boldlinks a {
  font-weight: bold;
}

.noselect, .groupalert.disabled, .previewViews, .button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.alertnotice {
  color: #c30000;
}

.border-alert {
  border-color: #c30000 !important;
}

.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.superscript {
  position: relative;
  top: -.5em;
  font-size: 40%;
  line-height: normal;
}

.text-shadow {
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.text-shadow.heavy {
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
}

.shadowbox {
  border-radius: 0.3rem;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
}

.shadowbox:hover {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.box-shadow-angle {
  background: black;
  background: linear-gradient(180deg, black 0%, white 49%);
  filter: blur(0.2rem);
  opacity: .2;
}

.buttonshadow {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.opacityhover:hover {
  opacity: .85;
}

.opacity-1 {
  opacity: .1;
}

.opacity-2 {
  opacity: .2;
}

.opacity-3 {
  opacity: .3;
}

.opacity-4 {
  opacity: .4;
}

.opacity-5 {
  opacity: .5;
}

.opacity-6 {
  opacity: .6;
}

.opacity-7 {
  opacity: .7;
}

.opacity-8 {
  opacity: .8;
}

.opacity-9 {
  opacity: .9;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

@media (max-width: 767px) {
  .border-hidesm {
    border-width: 0 !important;
  }
}

.border-right {
  border-right-style: solid;
}

.border-left {
  border-left-style: solid;
}

.border-bottom {
  border-bottom-style: solid;
}

.border-top {
  border-top-style: solid;
}

@media (max-width: 767px) {
  .hideborder {
    border: 0 !important;
    outline: none;
  }
}

.hover-show {
  opacity: 0;
}

.hover-show:hover {
  opacity: 1;
}

/**
    MEDIA QUERIES

    Where all media queries' variables are defined

    1100px = largescreen
    970px = desktop
    850px = tablet
    670px = medium
    570px = small
    425px = mobile
*/
/*------------------------------------*\
    STYLE
\*------------------------------------*/
.nav-wrap {
  font-size: .8rem;
}

@media (min-width: 992px) {
  .nav-wrap {
    font-size: 1rem;
  }
}

.nav-wrap .site-header {
  z-index: 1000;
  will-change: transform;
}

.nav-wrap .site-header .nav-extras {
  width: 100%;
  max-width: 300px;
  border: 1px solid #1e1f26;
  background: #ffffff;
  border-top: 0;
  position: fixed;
  top: 4em;
  z-index: 10;
  display: none;
}

.nav-wrap .site-header .nav-extras input::placeholder {
  color: #cacaca;
  font-size: .8em;
}

.nav-wrap .site-header .nav-upper {
  display: block;
  height: 4em;
}

.nav-wrap .site-header .nav-upper .menublock {
  width: 4em;
  height: 4em;
}

.nav-wrap .site-header .nav-upper .menublock:hover {
  border-color: #1e1f26;
}

.nav-wrap .site-header .nav-upper .nav-extras {
  right: 0;
  border-right: 0;
}

.nav-wrap .site-header .nav-upper .cartlink {
  height: 4em;
}

.nav-wrap .site-header .nav-upper .cartlink div:last-of-type {
  font-size: .7rem;
  font-weight: 500;
}

.nav-wrap .site-header .nav-lower {
  position: relative;
  z-index: 1;
  height: 4em;
  text-align: center;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower {
    position: fixed;
    overflow: auto;
    z-index: 10000;
    top: 0;
    left: 0;
    text-align: left;
    padding-top: 3.5em;
    width: 300px;
    margin-left: -300px;
    min-height: 100vh;
    background: #ffffff;
    font-size: 1.15em;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }
  .nav-wrap .site-header .nav-lower.menuopen {
    margin-left: 0;
    display: block !important;
    overflow-x: hidden;
    padding-bottom: 150px;
  }
}

@media (max-width: 992px) {
  .nav-wrap .site-header .nav-lower .industry-icon {
    font-size: .65rem;
  }
}

.nav-wrap .site-header .nav-lower .nav-extras {
  left: 0;
  border-left: 0;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .nav-extras {
    display: block;
    border: 0;
    position: relative;
    top: auto;
    height: 2.5em;
  }
  .nav-wrap .site-header .nav-lower .nav-extras a {
    font-size: .9em;
    font-weight: 500;
    line-height: 1.25em;
  }
  .nav-wrap .site-header .nav-lower .nav-extras.support-menu a {
    font-size: 1em;
    padding: .5em 0;
  }
}

.nav-wrap .site-header .nav-lower .cat-link, .nav-wrap .site-header .nav-lower .allproducts {
  height: 4em;
  padding-top: 4px;
  border-bottom: 4px solid rgba(255, 255, 255, 0);
}

.nav-wrap .site-header .nav-lower .cat-link.selected, .nav-wrap .site-header .nav-lower .allproducts.selected {
  color: #728fc1;
  border-bottom: 4px solid #1e1f26;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .cat-link.selected, .nav-wrap .site-header .nav-lower .allproducts.selected {
    border-bottom: 4px solid rgba(255, 255, 255, 0);
  }
}

@media (max-width: 992px) {
  .nav-wrap .site-header .nav-lower .cat-link, .nav-wrap .site-header .nav-lower .allproducts {
    font-size: .9rem;
    height: 3.7em;
  }
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .cat-link, .nav-wrap .site-header .nav-lower .allproducts {
    height: 2em;
    font-size: 1.1rem;
  }
}

.nav-wrap .site-header .nav-lower .cat-link div:first-of-type, .nav-wrap .site-header .nav-lower .allproducts div:first-of-type {
  font-size: .9em;
  font-weight: 500;
  line-height: 1.25em;
}

.nav-wrap .site-header .nav-lower .cat-menu {
  display: none;
  position: fixed;
  top: 8em;
  left: 0;
  width: 100%;
  z-index: 11;
  text-align: left;
}

.nav-wrap .site-header .nav-lower .cat-menu a {
  font-size: .8em;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .cat-menu {
    position: relative;
    top: auto;
    border: 0;
    background: #f6f6f6;
  }
}

@media (min-width: 767px) {
  .nav-wrap .site-header .nav-lower .cat-menu-wrap {
    background-color: #ffffff;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    background-clip: padding-box;
    overflow: hidden;
  }
}

.nav-wrap .site-header .nav-lower .eddm-nav img {
  margin-bottom: -10px;
  bottom: 0;
}

@media (max-width: 1300px) {
  .nav-wrap .site-header .nav-lower .eddm-nav img {
    margin-bottom: 0px;
  }
}

@media (max-width: 1450px) {
  .nav-wrap .site-header .nav-lower .eddm-nav .hideshow {
    display: none;
  }
}

@media (max-width: 1275px) {
  .nav-wrap .site-header .nav-lower .eddm-nav .hideshow {
    display: inline-block;
  }
}

@media (max-width: 850px) {
  .nav-wrap .site-header .nav-lower .eddm-nav .hideshow {
    display: none;
  }
}

@media (max-width: 1450px) {
  .nav-wrap .site-header .nav-lower .eddm-nav .blockalt {
    display: inline-block;
  }
}

@media (max-width: 1275px) {
  .nav-wrap .site-header .nav-lower .eddm-nav .blockalt {
    display: block;
  }
}

@media (max-width: 991px) {
  .nav-wrap .site-header .nav-lower .eddm-nav .blockalt {
    display: inline-block;
  }
}

.nav-wrap .site-header .nav-lower .nav-border {
  border: 1px solid #dee2e6;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .nav-border {
    border-width: 0px;
  }
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .mobile-nav-border {
    border-bottom: 1px solid #dee2e6;
  }
}

.nav-wrap .site-header .nav-lower .all-products a {
  float: left;
  width: 20%;
}

@media (max-width: 992px) {
  .nav-wrap .site-header .nav-lower .all-products a {
    width: 25%;
  }
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .all-products a {
    width: 100%;
  }
}

.nav-wrap .site-header .nav-lower .all-products a,
.nav-wrap .site-header .nav-lower .product-col a,
.nav-wrap .site-header .nav-lower .product-col p,
.nav-wrap .site-header .nav-lower .product-col .title {
  display: block;
  padding: .25em .5em;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .nav-lower .all-products a,
  .nav-wrap .site-header .nav-lower .product-col a,
  .nav-wrap .site-header .nav-lower .product-col p,
  .nav-wrap .site-header .nav-lower .product-col .title {
    padding: .5em;
    color: #ffffff;
  }
}

.nav-wrap .site-header .cat-menu-backer {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.9) 100%);
  height: 650px;
  width: 100%;
  position: absolute;
  bottom: -650px;
  left: 0;
  z-index: 0;
  display: none;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .cat-menu-backer {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .nav-wrap .site-header .slide-bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #000;
    z-index: 9999;
    opacity: .85;
    display: none;
  }
}

.nav-wrap .site-header .bg {
  opacity: 0.9;
  background: #ffffff;
  border-bottom: 1px solid #e6e8e7;
  z-index: 0;
}

.nav-wrap .site-header.navHide {
  opacity: 0;
  pointer-events: none;
}

.nav-wrap .site-header.navShow {
  opacity: 1;
}

.nav-wrap .site-header .logo {
  max-width: 130px;
  width: 130px;
  height: 4em;
  max-height: 4em;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .logo {
    max-width: 110px;
    width: 110px;
  }
}

.nav-wrap .site-header .logo-yelow {
  max-width: 200px;
  width: 200px;
  height: 10em;
  max-height: 10em;
}

@media (max-width: 767px) {
  .nav-wrap .site-header .logo-yelow {
    max-width: 145px;
    width: 145px;
  }
}

.nav-wrap .site-header .submenu {
  top: 6em;
  display: none;
}

.nav-wrap .site-header .submenu .bg {
  opacity: 1;
  border: 1px solid #e6e8e7;
  border-top: 1px solid #ffffff;
}

.nav-wrap .site-header .main-nav .down {
  font-size: 0.95em;
  line-height: 3.7em;
}

.nav-wrap .site-header .main-nav .down div {
  line-height: inherit;
}

.nav-wrap .site-header .pricing-level {
  top: -1.3em;
}

.nav-wrap .spacer {
  height: 4em;
  max-height: 4em;
}

@media (min-width: 768px) {
  .nav-wrap .spacer {
    height: 8em;
    max-height: 8em;
  }
}

@media print {
  .nav-wrap .spacer {
    display: none;
  }
}

@media (min-width: 767px) {
  .nav-wrap.hideSubNav .nav-lower {
    height: 0;
    border: 0;
  }
}

@media (min-width: 767px) {
  .nav-wrap.hideSubNav .nav-lower .product-nav {
    display: none;
    background: #ffffff;
  }
}

.nav-wrap.hideSubNav .spacer {
  height: 4em;
}

.nav-wrap .slidermenu {
  padding: 1.24em 1em;
}

@media (max-width: 767px) {
  .nav-wrap .slidermenu {
    z-index: 10001 !important;
  }
}

.nav-wrap input[type="checkbox"].menuToggle {
  display: block;
  width: 4em;
  height: 4em;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  -webkit-touch-callout: none;
}

.nav-wrap .menu-icon span.hamburguesa {
  display: block;
  width: 22px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #1e1f26;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
}

.nav-wrap .menu-icon span.hamburguesa:first-child {
  transform-origin: 0% 0%;
}

.nav-wrap .menu-icon span.hamburguesa:last-child {
  margin-bottom: 0;
}

.nav-wrap .menu-icon span.hamburguesa:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.nav-wrap .menuToggle:checked ~ .menu-icon span.hamburguesa {
  opacity: 1;
  transform: rotate(45deg) translate(-6px, -10px);
}

.nav-wrap .menuToggle:checked ~ .menu-icon span.hamburguesa:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.nav-wrap .menuToggle:checked ~ .menu-icon span.hamburguesa:nth-last-child(2) {
  transform: rotate(-45deg) translate(-3px, 9px);
}

#current_vendor {
  margin-bottom: 15px;
}

.daytoshine {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica", "sans-serif";
}

.containerBanner {
  position: relative;
  font-size: 3vw;
}

.containerBanner a {
  border-radius: 7px;
  background-color: #0d2a5f !important;
  font-weight: bold;
  padding: 1vw;
  letter-spacing: 0.1em;
  margin-top: 9vw;
  font-size: 1.3vw;
  display: block;
  width: fit-content;
}

.containerBanner a:hover {
  color: #0d2a5f !important;
  background-color: #fff !important;
}

.containerBanner img {
  width: 99%;
  height: auto;
  display: block;
  margin: auto;
}

.row-menu-daytoshine {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;
  gap: 3%;
  font-weight: bold;
  padding-left: 10vw;
  margin-top: -4.9vw;
  position: relative;
}

.row-menu-daytoshine a {
  color: #0d2a5f;
  text-decoration: none;
}

.row-menu-daytoshine a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.text {
  position: absolute;
  top: 11%;
  left: 8%;
}

.blue-daytoshine {
  color: #0d2a5f;
}

.blue-daytoshine p {
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin: 0px;
}

.blue-daytoshine span {
  letter-spacing: 2.5px;
  font-size: 1.2vw;
  display: block;
}

.getstartedbtn {
  box-shadow: 2px 2px #dcdbc1, 2.5px 2.5px #00c96f;
  margin-bottom: 45px;
}

@media (max-width: 440px) {
  .row-menu-daytoshine {
    justify-content: center;
    gap: 4%;
    font-size: 2vw;
    padding-left: 0;
    margin-top: -4vw;
  }
  .getstartedbtn {
    box-shadow: 1.2px 1.2px #dcdbc1, 1.4px 1.4px #00c96f;
    margin-bottom: 45px;
  }
}

@media (max-width: 560px) {
  .blue-daytoshine span {
    width: 90%;
    font-size: 1.5vw;
    letter-spacing: 1px;
  }
  .containerBanner a {
    margin-top: 7vw;
  }
}

/***** LOGIN STYLE MODAL *****/
.login-form input::placeholder {
  line-height: 1.7em;
}

.login-social .facebook,
.login-social .twitter {
  width: 40px;
  height: 36px;
}

.login-social .facebook {
  background: url("/images/social-icon.png") center left no-repeat;
  background-size: 300%;
}

.login-social .google {
  background: url("/images/standard-button-white.png") center no-repeat;
  background-size: cover;
  width: 255px;
  height: 52px;
}

.login-social .twitter {
  background: url("/images/social-icon.png") center right no-repeat;
  background-size: 300%;
}

/*** ACCOUNT POPUP SUCCESS ***/
.muchsuccess {
  padding-top: 200px;
}

.successburst {
  color: #ffffff;
  width: 181px;
  height: 181px;
  text-align: left;
  top: 0;
  opacity: 0;
  -webkit-animation: myship 1s;
  -webkit-animation-fill-mode: forwards;
}

.successburst:before {
  font-family: 'primo', 'Helvetica', 'sans-serif';
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 181px;
  z-index: 1;
  color: #283655;
  font-size: 181px;
  content: "\e01a";
  -webkit-animation: spinX 1s;
}

@-webkit-keyframes spinX {
  0% {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 50%;
  }
  100% {
    -webkit-transform: rotate(0deg);
    -webkit-transform-origin: 50%;
  }
}

@-webkit-keyframes myship {
  from {
    top: 40px;
    opacity: 0;
  }
  to {
    top: 20px;
    opacity: 1;
  }
}

.sucesscheck {
  width: 181px;
  height: 181px;
}

.sucesscheck:before {
  font-family: 'primo', 'Helvetica', 'sans-serif';
  position: absolute;
  width: 181px;
  height: 181px;
  text-align: center;
  line-height: 181px;
  z-index: 1;
  color: #ffffff;
  font-size: 90px;
  content: "\e00d";
}

.loggedin {
  width: 10px;
  height: 10px;
  background: lightgreen;
  right: 1em;
  bottom: 1em;
}

/* Video */
.js-video {
  height: 0;
  padding-top: 25px;
  padding-bottom: 67.5%;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.js-video.widescreen {
  padding-bottom: 56.34%;
}

.js-video.vimeo {
  padding-top: 0;
}

.js-video embed, .js-video iframe, .js-video object, .js-video video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

/* Forms */
.inputwrap {
  border: 1px solid #ddd;
  background: #ffffff;
  color: #555;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-clip: padding-box;
  position: relative;
}

textarea.form-control {
  font-size: .9rem;
}

.optionError {
  border: 1px solid #d10000;
  background: #ffe5e5;
}

.checkoutError {
  border: 1px solid #d10000 !important;
}

.optionHighlight {
  border: 1px solid #007bff;
  background: #f1f8ff;
}

.step-form.light button {
  background: transparent;
  color: #ffffff;
}

.step-form.light button:hover {
  border-color: #283655;
  background: #283655;
}

.step-form.light .inputwrap.placeholder {
  background: none;
  border: 0;
  border-radius: 0;
}

.step-form.light .inputwrap.placeholder .reqit {
  margin-top: 5px;
}

.step-form.light .inputwrap.placeholder label, .step-form.light .inputwrap.placeholder input, .step-form.light .inputwrap.placeholder.focused label {
  color: #ffffff;
  text-align: left;
}

.step-form.light .inputwrap.placeholder label {
  font-size: 1.5em;
  bottom: 0;
  top: auto;
  opacity: .7;
}

.step-form.light .inputwrap.placeholder.focused label {
  font-size: .85em;
  text-align: left;
  bottom: 15px;
  opacity: 1;
}

.step-form.light .inputwrap.placeholder input {
  font-size: 1.5em;
  background: none;
  border: 0;
  border-bottom: 2px solid #ffffff;
  border-radius: 0;
  -webkit-appearance: none;
}

.step-form.light .inputwrap.placeholder.select:after {
  color: #ffffff;
}

.step-form.light .inputwrap.placeholder.select select, .step-form.light .inputwrap.placeholder.select select:focus {
  background: none;
  border: 2px solid #ffffff;
  border-radius: 0;
  font-size: 1.25em;
  color: #ffffff !important;
  padding: 8px 9px;
  height: auto;
  margin: 0;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}

.step-form.light .inputwrap.placeholder.select select:focus, .step-form.light .inputwrap.placeholder.select select:focus:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #52a8ec;
}

@supports (-ms-ime-align: auto) {
  .step-form.light .inputwrap.placeholder.select select, .step-form.light .inputwrap.placeholder.select select:focus {
    color: #000 !important;
    background: #ffffff;
  }
  .step-form.light .inputwrap.placeholder.select:after {
    color: #000 !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .step-form.light .inputwrap.placeholder.select select, .step-form.light .inputwrap.placeholder.select select:focus {
    color: #000 !important;
    background: #ffffff;
  }
  .step-form.light .inputwrap.placeholder.select:after {
    content: '';
  }
}

.step-form.light .inputwrap.placeholder textarea {
  background: none;
  border: 2px solid #ffffff;
  border-radius: 0;
  font-size: 1.25em;
  color: #ffffff;
}

.step-form.light .inputwrap.placeholder input:-webkit-autofill,
.step-form.light .inputwrap.placeholder input:-webkit-autofill:hover,
.step-form.light .inputwrap.placeholder input:-webkit-autofill:focus input:-webkit-autofill,
.step-form.light .inputwrap.placeholder textarea:-webkit-autofill,
.step-form.light .inputwrap.placeholder textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
.step-form.light .inputwrap.placeholder select.form-control:-webkit-autofill,
.step-form.light .inputwrap.placeholder select.form-control:-webkit-autofill:hover,
.step-form.light .inputwrap.placeholder select.form-control:-webkit-autofill:focus {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
  -webkit-text-fill-color: #fff !important;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.step-form.dark button {
  background: transparent;
  color: #3a3a3a;
}

.step-form.dark button:hover {
  border-color: #283655;
  background: #283655;
  color: #ffffff;
}

.step-form.dark button.bgfirst {
  background: #283655;
  color: #ffffff;
}

.step-form.dark button.bgfirst:hover {
  background: #4D648d;
}

.step-form.dark .inputwrap.placeholder {
  background: none;
  border: 0;
  border-radius: 0;
}

.step-form.dark .inputwrap.placeholder .reqit {
  margin-top: 5px;
}

.step-form.dark .inputwrap.placeholder label, .step-form.dark .inputwrap.placeholder input, .step-form.dark .inputwrap.placeholder.focused label {
  color: #3a3a3a;
  text-align: left;
}

.step-form.dark .inputwrap.placeholder label {
  font-size: 1.15em;
  bottom: 0;
  top: auto;
  opacity: .7;
}

.step-form.dark .inputwrap.placeholder.focused label {
  font-size: .85em;
  text-align: left;
  bottom: 15px;
  opacity: 1;
}

.step-form.dark .inputwrap.placeholder input {
  font-size: 1.15em;
  background: none;
  border: 0;
  border-bottom: 1px solid #3a3a3a;
  border-radius: 0;
  -webkit-appearance: none;
}

.step-form.dark .inputwrap.placeholder.select:after {
  color: #3a3a3a;
}

.step-form.dark .inputwrap.placeholder.select select, .step-form.dark .inputwrap.placeholder.select select:focus {
  background: none;
  border: 1px solid #3a3a3a;
  border-radius: 0;
  font-size: 1.15em;
  color: #3a3a3a !important;
  padding: 8px 9px;
  height: auto;
  margin: 0;
  margin-top: 15px;
  position: relative;
  z-index: 1;
}

.step-form.dark .inputwrap.placeholder.select select:focus, .step-form.dark .inputwrap.placeholder.select select:focus:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #52a8ec;
}

@supports (-ms-ime-align: auto) {
  .step-form.dark .inputwrap.placeholder.select select, .step-form.dark .inputwrap.placeholder.select select:focus {
    color: #000 !important;
    background: #3a3a3a;
  }
  .step-form.dark .inputwrap.placeholder.select:after {
    color: #000 !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .step-form.dark .inputwrap.placeholder.select select, .step-form.dark .inputwrap.placeholder.select select:focus {
    color: #000 !important;
    background: #3a3a3a;
  }
  .step-form.dark .inputwrap.placeholder.select:after {
    content: '';
  }
}

.step-form.dark .inputwrap.placeholder textarea {
  background: none;
  border: 1px solid #3a3a3a;
  border-radius: 0;
  font-size: 1.25em;
  color: #3a3a3a;
}

.step-form.dark .inputwrap.placeholder input:-webkit-autofill,
.step-form.dark .inputwrap.placeholder input:-webkit-autofill:hover,
.step-form.dark .inputwrap.placeholder input:-webkit-autofill:focus input:-webkit-autofill,
.step-form.dark .inputwrap.placeholder textarea:-webkit-autofill,
.step-form.dark .inputwrap.placeholder textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
.step-form.dark .inputwrap.placeholder select.form-control:-webkit-autofill,
.step-form.dark .inputwrap.placeholder select.form-control:-webkit-autofill:hover,
.step-form.dark .inputwrap.placeholder select.form-control:-webkit-autofill:focus {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
  -webkit-text-fill-color: #3a3a3a !important;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.nav-search {
  height: 4em;
}

.nav-search span:first-of-type {
  font-size: 1.25em;
}

.nav-search input {
  font-size: .9rem;
  font-weight: 500;
}

@media (max-width: 767px) {
  .nav-search input {
    padding: 15px;
    width: 100%;
  }
}

.nav-search input::placeholder {
  font-size: .7rem;
  line-height: 1.8em;
  color: #1e1f26;
}

.nav-search input:focus::placeholder {
  color: #cacaca;
}

.nav-search form {
  display: inline-block;
}

@media (max-width: 767px) {
  .nav-search form {
    display: none;
    position: fixed;
    width: 100%;
    top: 6em;
    left: 0;
    background: #ffffff;
    border-bottom: 1px solid #1e1f26;
  }
}

.nav-search-results {
  position: fixed;
  top: 4em;
  left: 0;
  width: 100%;
  z-index: 90;
  margin: 0;
  border-bottom: 1px solid #1e1f26;
}

.nav-search-results .search-result {
  position: relative;
  padding: 0 2em 0 12.5em;
  min-height: 10em;
  color: #283655;
  font-size: .75em;
}

.nav-search-results .search-result img {
  width: 10em;
  height: auto;
  left: 1em;
}

@media (max-width: 767px) {
  .nav-search-results {
    top: 8.05em;
  }
}

.search-page {
  /* SEARCH INPUT
    // ------------------------- */
  /* HITS
    // ------------------------- */
}

.search-page .search-input {
  border: 0;
  border-bottom: 5px solid #eee;
}

.search-page .search-input:focus {
  border-color: #283655;
}

.search-page .search-input,
.search-page .search-input:focus {
  transition: border-color .3s ease-in;
  outline: 0;
  line-height: normal;
}

.search-page .search-input-icon {
  bottom: 15px;
  width: 20px;
  height: 20px;
  content: '';
  cursor: default;
  background: url(/images/search/search_icon.png) no-repeat;
}

.search-page .search-input-icon.empty {
  cursor: pointer;
  background-image: url(/images/search/delete_icon.png);
}

.search-page .hit-content em {
  font-style: normal;
  background: #dde9fb;
}

.growl {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  top: -3.5em;
  min-height: 3.5em;
  padding: 1em 0;
  z-index: 10004;
  font-size: 1.25em;
}

.growl .growloff {
  width: .8em;
  cursor: pointer;
}

@media (max-width: 720px) {
  .growl {
    font-size: 1em;
  }
}

.review {
  display: none;
}

.rating {
  width: 180px;
  height: 35px;
  background: url(//primoprint.imgix.net/star-ratings.svg) top left no-repeat;
  background-size: cover;
  display: block;
  margin: 0 auto 10px;
}

.rating.stars5 {
  background-position: 0 0%;
}

.rating.stars4 {
  background-position: 0 25%;
}

.rating.stars3 {
  background-position: 0 50%;
}

.rating.stars2 {
  background-position: 0 75%;
}

.rating.stars1 {
  background-position: 0 100%;
}

.star-ratings-sprite {
  background: url("//primoprint.imgix.net/star-rating-bottom.svg");
  background-size: cover;
  font-size: 0;
  height: 15px;
  line-height: 0;
  overflow: hidden;
  text-indent: -999em;
  width: 86px;
  margin: 0 auto;
}

.star-ratings-sprite-rating {
  background: url("//primoprint.imgix.net/star-rating-top.svg");
  background-size: cover;
  background-position: 0 100%;
  float: left;
  height: 15px;
  display: block;
}

.review.review-active {
  display: block;
}

#map-plug {
  display: none;
}

.review-item {
  margin: 0 auto;
  padding: 1em;
}

.review-meta,
.review-stars {
  font-size: 115%;
}

.review-meta {
  float: left;
}

.review-stars {
  float: right;
}

.review-author {
  text-transform: capitalize;
  font-weight: bold;
}

.review-date {
  opacity: .6;
  margin-left: 10px;
}

.review-text {
  line-height: 1.55;
  text-align: left;
  padding-top: 1em;
  clear: both;
}

.review-stars ul {
  display: inline-block;
  list-style: none !important;
  margin: 0;
  padding: 0;
}

.review-stars ul li {
  float: left;
  list-style: none !important;
  margin-right: 1px;
  line-height: 1;
}

.review-stars ul li i {
  color: #E4B248;
  font-size: 1.4em;
  font-style: normal;
}

.review-stars ul li i.inactive {
  color: #c6c6c6;
}

.star:after {
  content: "\2605";
}

/** Modal **/
.mapModal {
  position: fixed;
  top: 60px;
  left: 50px;
  right: 50px;
  bottom: 50px;
  box-sizing: border-box;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8) url("/images/loading.svg") no-repeat center center;
  border-radius: 8px;
  transition: opacity .5s;
}

.mapModal a.btn.hide, .mapModal a.hide.galleryLink {
  opacity: 0;
}

.mapModal .route_functions {
  height: 50px;
  z-index: 3;
  top: 54px;
  left: 10px;
  width: 290px;
}

.mapModal .route_functions .login_buttons {
  display: none;
}

.mapModal .route_functions .login_buttons a {
  margin-left: 10px;
}

.mapModal .route_functions.login .login_buttons {
  display: block;
}

.mapModal .route_functions .route_buttons {
  margin-left: -290px;
  transition: all .3s;
}

.mapModal .route_functions .route_save {
  margin-left: 290px;
  transition: all .3s;
}

.mapModal .route_functions.rButtons .route_buttons {
  margin-left: 0;
}

.mapModal .route_functions.rButtons .route_save {
  margin-left: 290px;
}

.mapModal .route_functions.rSave .route_buttons {
  margin-left: -290px;
}

.mapModal .route_functions.rSave .route_save {
  margin-left: 0;
}

.mapModal .routes_list {
  z-index: 3;
  left: 10px;
  top: 104px;
  bottom: 10px;
  width: 290px;
  border-right: 1px solid #A3A3A3;
  background: #D8D8D8;
  border-radius: 0 0 0 4px;
  overflow-x: hidden;
  overflow-y: auto;
}

.mapModal .routes_list div.login {
  font-size: 1.1em;
  padding: 10px;
  display: none !important;
}

.mapModal .routes_list.login div.login {
  display: block !important;
}

.mapModal .routes_list ul li {
  border-bottom: 1px solid #BABBBA;
  padding: 10px;
  transition: all .4s;
}

.mapModal .routes_list ul li.tutorial {
  height: 100%;
}

.mapModal .routes_list ul li.tutorial h4 {
  margin: 7px 0;
  font-weight: normal;
  color: #212121;
}

.mapModal .routes_list ul li.tutorial span, .mapModal .routes_list ul li.tutorial a {
  display: none;
}

.mapModal .routes_list ul li a.opt {
  top: 10px;
  right: 10px;
  width: 22px;
  height: 22px;
  background: #BABBBA;
  line-height: 22px;
  font-size: 0.8em;
}

.mapModal .routes_list ul li a.opt.optGreen {
  right: 40px;
  display: none;
}

.mapModal .routes_list ul li a.opt.optRed:hover {
  background: #f60000;
}

.mapModal .routes_list ul li.active {
  background: #fff;
}

.mapModal .routes_list ul li.active a.optGreen {
  display: block;
}

.mapModal .routes_list ul li:hover {
  background: #efefef;
}

.mapModal .routes_list ul li h3, .mapModal .routes_list ul li h4 {
  margin: 0;
}

.mapModal .routes_list ul li h3 {
  font-size: 1.2em;
  color: #283655;
}

.mapModal .routes_list ul li h4 {
  font-size: 1.1em;
  color: #283655;
}

.mapModal .routes_list ul li > span {
  display: inline-block;
  padding-right: 12px;
  font-size: 1em;
  color: #5B5B5B;
}

.mapModal .routes_list ul li > span + span {
  padding-right: 0px;
}

.mapModal iframe {
  box-sizing: border-box;
  position: absolute;
  height: 100% !important;
  z-index: 1;
  opacity: 0;
  transition: all .5s;
  border: 0;
}

.mapModal .map {
  padding: 0;
  max-width: 100%;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
}

.mapModal .map .routeSelection {
  margin: 0;
  background: none;
}

.mapModal .map .routeSelection .productheader {
  border-radius: 0 4px 0 0;
  background: #283655;
  border-top: 1px solid #405789;
  border-bottom: 1px solid #101521;
  border-left: 1px solid #101521;
  position: relative;
  z-index: 2;
  margin-left: 290px;
}

.mapModal .map .routeSelection .productheader:first-child {
  position: absolute;
  border-radius: 4px 0 0 0;
  z-index: 4;
  margin-left: 0;
  width: 269px;
  border-left: 0;
}

.mapModal .map .routeSelection .productheader a.mapCloseBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #fff;
  transition: color .5s;
  width: 40px;
  height: 40px;
  line-height: 42px;
}

.mapModal .map .routeSelection .productheader a.mapCloseBtn:hover {
  color: #283655;
}

/** Prod Pages **/
.productDetails.routeSelection {
  position: relative;
  margin-bottom: 0;
  background: #fff url(/images/loading.svg) no-repeat center center;
}

.productDetails.routeSelection iframe {
  box-sizing: border-box;
  transition: all .5s;
}

.productDetails.routeSelection .eddmSubNav {
  position: absolute;
  top: 42px;
  left: 0;
  bottom: 0;
  background: #1b2439;
  width: 44px;
  transition: all .5s;
}

.productDetails.routeSelection .eddmSubNav.expand {
  margin-left: 290px;
}

.productDetails.routeSelection .eddmSubNav .navToggle {
  z-index: 999;
  line-height: 50px;
  bottom: 9px;
  left: 4px;
  color: #fff;
  padding: 8px 16px;
  font-weight: bold;
}

.productDetails.routeSelection .route_functions {
  height: 50px;
  z-index: 3;
  top: 42px;
  margin-left: -290px;
  width: 290px;
  border-right: 1px solid #101521;
  transition: all .5s;
}

.productDetails.routeSelection .route_functions.expand {
  margin-left: 0;
}

.productDetails.routeSelection .route_functions .login_buttons {
  display: none;
  z-index: 100;
}

.productDetails.routeSelection .route_functions .login_buttons a {
  margin: 6px 0 0 8px;
}

.productDetails.routeSelection .route_functions.login .login_buttons {
  display: block;
}

.productDetails.routeSelection .route_functions .route_buttons {
  margin-left: -290px;
  transition: all .3s;
}

.productDetails.routeSelection .route_functions .route_save {
  margin-left: 290px;
  transition: all .3s;
}

.productDetails.routeSelection .route_functions.rButtons .route_buttons {
  margin-left: 0;
}

.productDetails.routeSelection .route_functions.rButtons .route_save {
  margin-left: 290px;
}

.productDetails.routeSelection .route_functions.rSave .route_buttons {
  margin-left: -290px;
}

.productDetails.routeSelection .route_functions.rSave .route_save {
  margin-left: 0;
}

.productDetails.routeSelection .routes_list {
  z-index: 3;
  margin-left: -290px;
  top: 92px;
  bottom: 0;
  width: 290px;
  border-right: 1px solid #A3A3A3;
  background: #D8D8D8;
  border-radius: 0 0 0 4px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all .5s;
}

.productDetails.routeSelection .routes_list div.login {
  display: none;
  padding: 10px;
}

.productDetails.routeSelection .routes_list.login div.login {
  display: block;
}

.productDetails.routeSelection .routes_list.expand {
  margin-left: 0;
}

.productDetails.routeSelection .routes_list ul li {
  border-bottom: 1px solid #BABBBA;
  padding: 10px;
  transition: all .4s;
}

.productDetails.routeSelection .routes_list ul li.tutorial {
  height: 100%;
}

.productDetails.routeSelection .routes_list ul li.tutorial h4 {
  margin: 7px 0;
  font-weight: normal;
  color: #212121;
}

.productDetails.routeSelection .routes_list ul li.tutorial span, .productDetails.routeSelection .routes_list ul li.tutorial a {
  display: none;
}

.productDetails.routeSelection .routes_list ul li a.opt {
  top: 10px;
  right: 10px;
  width: 22px;
  height: 22px;
  background: #BABBBA;
  line-height: 22px;
  font-size: 0.8em;
}

.productDetails.routeSelection .routes_list ul li a.opt.optGreen {
  right: 40px;
  background: #283655;
  display: none;
}

.productDetails.routeSelection .routes_list ul li a.opt.optRed:hover {
  background: #f60000;
}

.productDetails.routeSelection .routes_list ul li.active {
  background: #fff;
}

.productDetails.routeSelection .routes_list ul li.active a.optGreen {
  display: block;
}

.productDetails.routeSelection .routes_list ul li:hover {
  background: #efefef;
}

.productDetails.routeSelection .routes_list ul li h3, .productDetails.routeSelection .routes_list ul li h4 {
  margin: 0;
}

.productDetails.routeSelection .routes_list ul li h3 {
  font-size: 1.2em;
  color: #283655;
}

.productDetails.routeSelection .routes_list ul li h4 {
  font-size: 1.1em;
  color: #283655;
}

.productDetails.routeSelection .routes_list ul li > span {
  display: inline-block;
  padding-right: 12px;
  font-size: 1em;
  color: #5B5B5B;
}

.productDetails.routeSelection .routes_list ul li > span + span {
  padding-right: 0px;
}

.checkmark-container {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 0 auto;
}

.checkmark-container .checkmark {
  border-radius: 50%;
  display: block;
  stroke: #7ac142;
  box-shadow: inset 0px 0px 0px #7ac142;
}

.checkmark-container .checkmark-outline {
  stroke: #7ac142;
  stroke-width: 3;
}

.checkmark-container .checkmark-circle {
  stroke: transparent;
  stroke-width: 1;
  fill: none;
  stroke-miterlimit: 10;
}

.checkmark-container .checkmark-check {
  stroke: transparent;
  transform-origin: 50% 50%;
}

.checkmark-container.checked .checkmark {
  stroke-width: 3;
  stroke: #fff;
  stroke-miterlimit: 10;
  animation: checkmark-fill .4s ease-in-out .4s forwards, checkmark-scale .3s ease-in-out .9s both;
}

.checkmark-container.checked g .checkmark-outline {
  stroke: transparent;
  stroke-width: 1;
}

.checkmark-container.checked g .checkmark-circle {
  stroke: #7ac142;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: checkmark-stroke 0.6s cubic-bezier(0.35, 0, 0.25, 1) forwards;
}

.checkmark-container.checked g .checkmark-check {
  stroke: white;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: checkmark-stroke 0.3s cubic-bezier(0.35, 0, 0.25, 1) 0.8s forwards;
}

@keyframes checkmark-stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes checkmark-scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes checkmark-fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #7ac142;
  }
}

.modal .inputwrap.placeholder {
  margin: 0;
}

.modal .inputwrap.placeholder.select {
  margin-bottom: 15px;
}

.inputwrap.placeholder select::-ms-expand {
  display: none;
}

.inputwrap.placeholder {
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  height: auto;
}

.inputwrap.placeholder .form-control {
  font-size: .9rem;
  padding: 0 10px;
}

.inputwrap.placeholder textarea.form-control {
  border: 0;
  padding: 25px 9px 8px;
  line-height: 16px;
  height: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.inputwrap.placeholder input.form-control {
  border: 0;
  padding: 25px 9px 8px;
  line-height: 16px;
  height: auto;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.inputwrap.placeholder label {
  color: #636363;
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 32px;
  pointer-events: none;
  -webkit-transform: translateZ(0) scale(1);
  transform: translateZ(0) scale(1);
  transform-origin: left top 0;
  transition: all .25s ease-in-out;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 90%;
  display: inline-block;
  white-space: nowrap;
}

.inputwrap.placeholder.focused label {
  color: #283655;
  -webkit-transform: translate3d(0, -9px, 0) scale(0.8);
  transform: translate3d(0, -9px, 0) scale(0.8);
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
}

.inputwrap.placeholder.select label, .inputwrap.placeholder.upload label {
  color: #283655;
  -webkit-transform: translate3d(0, -9px, 0) scale(0.8);
  transform: translate3d(0, -9px, 0) scale(0.8);
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
}

.inputwrap.placeholder.select select, .inputwrap.placeholder.upload select {
  height: 29px;
  margin-bottom: 0;
  margin-top: 20px;
  line-height: 29px;
  float: none;
  border: 0;
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.inputwrap.placeholder.select.focused, .inputwrap.placeholder.upload.focused {
  border-color: #80bdff;
}

@media screen and (max-width: 425px) {
  .inputwrap.placeholder.select, .inputwrap.placeholder.upload {
    padding-left: 0;
  }
}

.inputwrap.placeholder.select:after {
  right: 4px;
  font-family: 'primo';
  content: attr(data-icon);
  content: "\e001";
  speak: none;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 20px;
  pointer-events: none;
}

.inputwrap.placeholder.select select::-ms-expand {
  display: none;
}

@media screen and (max-width: 970px) {
  .inputwrap.placeholder {
    padding: 0;
  }
}

@media screen and (max-width: 425px) {
  .inputwrap.placeholder {
    width: 100%;
  }
}

@-moz-document url-prefix() {
  .inputwrap.placeholder {
    overflow: hidden;
  }
  .inputwrap.placeholder select {
    overflow: hidden;
    width: 110%;
  }
  .step-form .inputwrap.placeholder {
    overflow: visible;
  }
  .step-form .inputwrap.placeholder select {
    overflow: visible;
    width: 100%;
  }
}

.reqit {
  right: 10px;
  font-size: 0.8em;
  color: #cdcdcd;
}

.reqit.red {
  color: #c30000;
}

.listbuilderSteps {
  z-index: 100;
  height: 36px;
}

.listbuilderSteps .step {
  width: 36px;
  line-height: 36px;
}

.listbuilderSteps .step:before {
  color: #ffffff;
}

.listbuilderSteps .step span {
  top: -30px;
}

.listbuilderSteps .step .steptooltip {
  border: 2px solid #283655;
  width: 240px;
  top: 55px;
  left: -5px;
  display: none;
}

.listbuilderSteps .step .steptooltip img {
  top: -19px;
  left: 5px;
  right: inherit;
}

.listbuilderSteps .step:nth-child(2) {
  left: 33.33%;
  margin-left: -18px;
}

.listbuilderSteps .step:nth-child(3) {
  left: 66.33%;
  margin-left: -18px;
}

.listbuilderSteps .step:nth-child(4) {
  right: 0;
}

.listbuilderSteps .step:nth-child(4) .steptooltip {
  right: -5px;
  left: inherit;
}

.listbuilderSteps .step:nth-child(4) img {
  right: 5px;
  left: inherit;
}

.listbuilderSteps .stepline {
  width: 99%;
  height: 5px;
}

.listbuilderSteps .notshowing {
  background: #728fc1;
}

.set.disabled, .optionsList.disabled {
  opacity: .5;
  pointer-events: none;
  cursor: not-allowed;
}

.groupalert.disabled {
  pointer-events: none;
  opacity: .5;
}

.incbutton.disabled .increment {
  cursor: not-allowed;
  opacity: .5;
}

.galleryBullet.img {
  width: 50px;
  height: 33px;
}

.galleryBullet.img img {
  width: 100%;
  height: auto;
  opacity: .5;
}

.galleryBullet.img.gallery-active {
  border-top: 3px solid #E75666;
}

.galleryBullet.img.gallery-active img {
  opacity: 1;
}

.hide-more {
  max-height: 200px;
  overflow: hidden;
}

.show-more {
  background: white;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 40%, white 90%);
}

.productOptions label {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.productOptions label .vertical-align {
  top: inherit;
  transform: none;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label span {
  display: inline-block;
  width: 17px;
  height: 19px;
  margin: -1px 0 0 0;
  vertical-align: middle;
  background: url("/images/check_radio_dark.png") left top no-repeat;
  cursor: pointer;
  background-size: cover;
}

input[type="checkbox"]:checked + label span {
  background: url("/images/check_radio_dark.png") -18px top no-repeat;
  background-size: cover;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label span {
  display: inline-block;
  width: 17px;
  height: 19px;
  margin: -1px 0 0 4px;
  vertical-align: middle;
  background: url("/images/check_radio_dark.png") -36px top no-repeat;
  cursor: pointer;
  background-size: cover;
}

input[type="radio"]:checked + label span {
  background: url("/images/check_radio_dark.png") -54px top no-repeat;
  background-size: cover;
}

/*** FILE UPLOADER ***/
.uploadDesign,
.uploadMobile {
  margin-bottom: 20px;
  padding: 11px 6px 11px 37px;
  line-height: 20px;
  position: relative;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
}

.uploadDesign span,
.uploadMobile span {
  font-size: 25px;
  height: 25px;
  position: absolute;
  left: 7px;
}

.uploadMobile {
  display: none;
}

.uploadLeft div.directions,
.uploadLeft div.filePreview {
  display: none;
}

.uploadLeft.showDirections div.directions {
  display: block;
  padding-bottom: 20px;
}

.uploadLeft.showPreview div.filePreview {
  display: block;
}

.uploadLeft h3 {
  font-weight: bold;
  font-size: 19px;
}

.uploadLeft p {
  color: #1e1f26;
  font-size: 13px;
  line-height: 16px;
}

.uploadLeft img {
  display: inline-block;
  margin-bottom: 10px;
}

.oval-h {
  background: url("/images/oval-business-card-area-h.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.oval-v {
  background: url("/images/oval-business-card-area-v.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.oval-h-clip {
  background: url("/images/oval-business-card-area-h-clip.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.oval-v-clip {
  background: url("/images/oval-business-card-area-v-clip.png");
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.preview {
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1020;
}

.preview img {
  height: auto;
  max-width: 100%;
  display: block;
  margin: 0;
  width: 100%;
}

.previewLine {
  position: absolute;
}

.preview .hangtaghole {
  display: none;
  min-height: inherit;
  min-width: inherit;
}

.preview .hangtaghole .drillsafe {
  border: 1px solid green;
  width: 160%;
  height: 160%;
  display: block;
  border-radius: 100px !important;
}

.preview.vertical .hangtaghole {
  display: block;
  width: 6.9%;
  height: auto;
}

.preview.horizontal .hangtaghole {
  display: block;
  height: 6.9%;
  width: auto;
}

.preview.horizontal .hangtaghole img {
  height: 100%;
  width: auto;
}

.heightauto {
  height: auto !important;
}

.widthauto {
  width: auto !important;
}

.previewMask1,
.previewMask2 {
  z-index: 1001;
  opacity: 0.75;
  position: absolute;
  left: 0;
  top: 0;
}

.previewBleed,
.previewMask1,
.previewMask2 {
  border: 1px solid red;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1000;
}

.previewTrim {
  border: 1px solid blue;
  z-index: 1002;
}

.previewMargins .previewTrim {
  top: 0;
  left: 0;
  position: absolute;
}

.previewSafe {
  border: 1px solid green;
  z-index: 1003;
  top: 0;
  left: 0;
}

.previewLabel {
  font-size: 16px;
  color: #1e1f26;
  text-align: center;
  font-weight: bold;
  padding: 10px 0;
}

.preview-flip-container {
  min-height: 254px;
}

.preview-flip-container .previewTrim {
  border: none;
  -moz-box-shadow: 0 0 10px #cccccc;
  -webkit-box-shadow: 0 0 10px #cccccc;
  box-shadow: 0 0 10px #cccccc;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1000;
}

.preview-flip-container .previewMask1,
.preview-flip-container .previewMask2 {
  border: none;
}

.preview-flip-container,
.previewMargins {
  padding-bottom: 20px;
}

.previewControls {
  text-align: left;
  margin-bottom: 22px;
}

.previewControls .viewbutton {
  border: 1px solid #cccccc;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
}

.previewControls .viewfront {
  margin-right: 4%;
}

.previewControls .viewback,
.previewControls .viewfront {
  width: 48%;
}

.previewPrint .back,
.previewPrint .flip-container,
.previewPrint .front {
  width: 100%;
  height: auto;
}

.previewPrint .front {
  position: relative;
  top: 0;
  left: 0;
}

.viewBtns {
  margin-bottom: 10px;
}

.previewViews label {
  width: 100%;
  text-align: left;
  font-size: 18px;
  line-height: 19px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  padding: 5px 5px 5px 28px;
  cursor: pointer;
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 10px;
}

.previewViews label span {
  margin: 0 !important;
  clear: both;
  position: absolute;
  left: 5px;
}

.previewLegend {
  width: 100%;
}

.previewLegend li {
  width: 33%;
  float: left;
  text-align: left;
  line-height: 18px;
  height: 40px;
  padding: 10px 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin: 0;
}

.previewLegend li span {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin: 0 5px -4px 0;
}

.bleedBox {
  background-color: red;
}

.trimBox {
  background-color: blue;
}

.safeBox {
  background-color: green;
}

.filePreview h4 {
  text-align: left;
  padding: 20px 0;
  font-size: 19px;
  color: #7c7c7c;
}

.filePreview h6 {
  text-align: center;
  padding: 0 0 16px;
  margin-bottom: 0;
}

.fileUpload {
  border: 1px solid #ddd;
  margin: 0 0 10px;
  position: relative;
  background: #fff;
  padding: 0 0 0 70px;
  min-height: 70px;
}

.fileUpload {
  cursor: pointer;
}

.fileUpload:hover {
  background: #f3f3f3;
}

.fileUpload input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  margin: 0;
  z-index: 1001;
  cursor: pointer;
}

.upload-message {
  color: #000;
  margin: 0;
}

.upload-message .icon-download-arrow:before {
  margin-right: 5px;
}

.upload-message a {
  color: #283655;
}

.fileUpload .icon {
  position: absolute;
  width: 70px;
  line-height: 70px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.fileUpload .icon div {
  position: absolute;
  width: 70px;
  height: 70px;
  top: 0;
  left: 0;
  text-align: center;
  color: #ffffff;
  font-size: 35px;
  padding-top: 17px;
}

.fileUpload .waiting {
  background: #cccccc;
}

.fileUpload .uploading {
  background: #283655;
}

.fileUpload .warning {
  background: #e7c900;
}

.fileUpload .approved {
  background: #283655;
}

.fileUpload .approved.rejected {
  background: #c30000;
}

.fileUpload .pending {
  background: #c6e493;
}

.fileUpload .failed {
  background: #c30000;
}

.fileUpload span {
  font-weight: bold;
}

.uploaderWrap {
  padding: 0;
  position: relative;
}

.uploaderWrap .progress {
  margin: 0;
  height: 15px;
}

.upload-message {
  padding-right: 38px;
}

.uploadStep2 .upload-message {
  padding-right: 80px;
}

.uploadStep3 .upload-message {
  padding-right: 0;
}

.uploaderWrap {
  padding: 0;
}

.sr-only {
  color: #000;
  z-index: 1002;
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  overflow: hidden;
  clip: auto;
  border: 0;
  top: 12px;
  right: 10px;
}

.icon-spinner-2:before {
  -webkit-animation: rotation 3s infinite linear;
  display: inline-block;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.fileActions {
  padding: 10px 0 0;
  border-top: 1px solid #ccc;
}

.fileActions ul {
  margin-bottom: 10px;
  width: 100%;
  display: block;
}

.fileActions li {
  font-size: 13px;
  margin: 0;
  padding-left: 10px;
  text-indent: -10px;
  line-height: 17px;
}

.fileActions li:before {
  content: "• ";
  color: #e7c900;
}

.fileActions .faileddesc li:before {
  content: "• ";
  color: #c30000;
}

.fileActions .button {
  margin: 0 10px 5px 0;
  font-size: 13px !important;
  height: 25px;
  border: 1px solid #cdcdcc;
}

.button.nocolor {
  background: #f5f5f5;
  color: #000;
}

.button.nocolor:hover {
  background: #ffffff;
}

.button.errorcolor {
  background: #ffc4c4;
  color: #000;
}

.button.errorcolor:hover {
  background: #ffd6d6;
}

.button .removeFile {
  margin-left: 0;
  color: #1e1f26;
  font-size: 15px;
}

.button sup {
  margin-top: -5px;
  display: inline-block;
}

.dateDelay {
  font-size: 11px;
  clear: both;
  padding: 0;
}

.deleteCommon {
  position: absolute;
  top: 0;
  left: 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 99999;
  padding-top: 17px;
}

.deleteCommon .button {
  margin-bottom: 0;
}

.uploadStep1 {
  padding: 14px 14px 12px;
}

.uploadStep1 p {
  color: #777;
}

.uploadStep1 p span {
  color: #000;
}

.uploadApproved {
  padding: 24px 10px;
  position: relative;
}

.uploadVerify {
  padding: 4px 0;
}

.uploadVerify .button {
  margin: 0;
}

.uploadStep2 {
  position: relative;
  padding: 12px 10px;
}

.approved,
.failed,
.pending,
.uploadApproved,
.uploadCommon,
.uploadFailed,
.uploadPending,
.uploadStep1,
.uploadStep2,
.uploadStep3,
.uploadVerify,
.uploadWarning,
.uploading,
.waiting,
.warning {
  display: none;
}

.stepUpload .uploadStep1,
.stepUpload .waiting {
  display: block;
}

.stepUploading .uploadStep2,
.stepUploading .uploading {
  display: block;
}

.stepWarning .uploadStep3,
.stepWarning .uploadWarning,
.stepWarning .warning {
  display: block;
}

.stepPending .pending,
.stepPending .uploadPending,
.stepPending .uploadStep3 {
  display: block;
}

.stepCommon .pending,
.stepCommon .uploadCommon,
.stepCommon .uploadStep3 {
  display: block;
}

.stepVerify .approved,
.stepVerify .uploadStep3,
.stepVerify .uploadVerify {
  display: block;
}

.stepApproved .approved,
.stepApproved .uploadApproved,
.stepApproved .uploadStep3 {
  display: block;
}

.stepFailed .failed,
.stepFailed .uploadFailed,
.stepFailed .uploadStep3 {
  display: block;
}

.uploadCommon,
.uploadFailed,
.uploadPending,
.uploadVerify,
.uploadWarning {
  padding: 17px 10px;
}

.dz-preview {
  display: none;
}

.dz-drag-hover {
  background: #e6f4cb;
}

.approval textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cdcdcd;
  background: #fbfbfb;
  font-weight: normal;
}

.approval div.approval_step_2 {
  display: none;
}

.adminBar div {
  padding: 10px;
}

.adminBar h1 {
  margin: 0;
  padding: 0;
  line-height: 38px;
}

.adminBar .productDetails {
  margin-bottom: 0;
}

.attention {
  background: #f3f0ba;
  padding: 8px 5px 8px 40px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  position: relative;
  margin-bottom: 20px;
}

.attention .notification {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  color: #bfbc83;
  position: absolute;
  left: 10px;
}

.attention a {
  color: #283655;
}

.attention.info {
  background: #badbff;
  padding: 8px 5px 8px 40px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  position: relative;
  margin-bottom: 20px;
}

.attention.info .notification {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  color: #283655;
  position: absolute;
  left: 10px;
}

.attention.info a {
  color: #283655;
}

.accountsNav {
  border-right: 0;
  border-radius: 10px 0 0 10px;
}

.accountExpand .accountsNav {
  text-align: center;
}

.accountExpand .accountsNav .acctnavtext {
  display: none;
}

.accountContent {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  border: 1px solid #adadad;
  min-height: 400px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
}

@media (min-width: 768px) {
  .accountContent {
    width: 75%;
    -webkit-border-radius: 0 10px 10px 10px;
    border-radius: 0 10px 10px 10px;
    background-clip: padding-box;
  }
}

@media print {
  .accountContent {
    border: 0;
    padding: 0;
    width: 100% !important;
  }
  .accountContent .invoiceDetails {
    width: 100%;
  }
  .accountContent .container {
    max-width: none;
  }
}

.sidenav {
  float: left;
  width: 100%;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-clip: padding-box;
  margin-bottom: 1em;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #9f9f9f;
}

@media (min-width: 768px) {
  .sidenav {
    width: 25%;
    border-right: none;
    -webkit-border-radius: 10px 0 0 10px;
    border-radius: 10px 0 0 10px;
    background-clip: padding-box;
  }
}

.sidenav li {
  margin-bottom: 0;
}

.sidenav li a {
  font-size: .85rem;
  color: #3a3a3a;
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid #9f9f9f;
  overflow: hidden;
}

.sidenav li a:hover {
  background: rgba(255, 255, 255, 0.4);
  color: #283655;
}

.sidenav li a.select, .sidenav li a.select:hover {
  background-color: #283655;
  color: #ffffff;
}

.sidenav li:last-child a {
  border-bottom: none;
}

.historyDetails {
  position: absolute;
  top: 0;
  left: 105%;
  width: 100%;
  opacity: 0;
}

.historyJobs,
.invoiceList,
.pendingJobs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/*** Flip Effect ***/
.flip-container, .preview-flip-container {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
}

.flip-container,
.front,
.back {
  width: 94%;
}

.flipper {
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  position: relative;
}

.front, .back {
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
}

.back {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}

.flip-container:hover .back, .flip-container.hover .back, .flip-container.galleryLink .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.flip-container:hover .front, .flip-container.hover .front, .flip-container.galleryLink .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.preview-flip-container.hover .back, .preview-flip-container.galleryLink .back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  transform: rotateY(0deg);
}

.preview-flip-container.hover .front, .preview-flip-container.galleryLink .front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.front, .back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front {
  z-index: 2;
}

/*** END Flip Effect ***/
.method {
  width: 46px;
  height: 32px;
  background: url("/images/paymentmethods.png") top left no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.method.credit {
  background-position: 0 0;
}

.method.echeck {
  background-position: 0 -32px;
}

.method.paypal {
  background-position: 0 -64px;
}

.method.invoice {
  background-position: 0 -96px;
}

.method.visa {
  background-position: 0 -128px;
}

.method.mastercard {
  background-position: 0 -160px;
}

.method.discover {
  background-position: 0 -192px;
}

.method.amex {
  background-position: 0 -224px;
}

.method.apple {
  background-position: 0 -256px;
}

@media print {
  .cartlist {
    font-size: 1.2rem;
    padding: 0 !important;
  }
  .cartlist .jobTitle {
    font-weight: 700;
    font-size: 1.2rem;
    color: #000000;
    padding: 0;
  }
}

.vendordesc h3 {
  margin-bottom: 1em;
}

.vendordesc a {
  color: #283655;
}

.vendordesc a:hover {
  color: #4D648d;
}

.vendordesc img {
  margin: -2em 0 1em;
}

/*** BLOG ***/
.wp-searchform {
  height: 34px;
}

.wp-searchform button {
  background: none;
  line-height: 34px;
}

.wp-searchform input {
  line-height: 34px;
  width: 175px;
}

a.blogclose {
  color: #ffffff;
  line-height: 34px;
}

.more-link {
  background: #283655;
  padding: 10px 20px;
  text-align: center;
  color: #ffffff;
  margin: 0 auto 30px;
  display: block;
  width: 250px;
  border-radius: 5px;
}

.more-link:hover {
  background: #4D648d;
  text-decoration: none;
  color: #ffffff;
}

.article-content ul li {
  list-style: disc;
}

.article-content h2 {
  font-size: 1.5em;
  font-weight: 700;
  margin-bottom: 1em;
}

.article-content h3 {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 1em;
}

.article-content h4 {
  font-size: 1.15em;
  font-weight: 700;
  margin-bottom: 1em;
}

.article-content h5, .article-content h6 {
  font-size: 1em;
  font-weight: 700;
  margin-bottom: 1em;
}

#GTS_CONTAINER {
  position: fixed;
  bottom: 0;
  left: 5px;
  z-index: 1;
}

.filter {
  position: static;
}

.filter .tab {
  border: 1px solid #adadad;
}

.filter .tab-spacer {
  height: 5px;
  bottom: 0;
  border: 1px solid #adadad;
  border-top: 0;
  border-bottom: 0;
  display: none;
}

.filter .filter-list {
  display: none;
  margin-top: -1px;
  border: 1px solid #adadad;
  left: 0;
}

.filter .filter-list li a {
  border-radius: 2em;
}

.filter .filtertag.selected {
  background-color: #283655;
  color: #ffffff;
}

.subcatalts .subcat.hidesubcat {
  display: none;
}

.subcatalts .subcat a {
  padding-bottom: 0;
}

.subcatalts .subcat a .subcatimg {
  padding-bottom: 0;
}

.subcatalts .subcat a:hover h2.colordark {
  color: #728fc1;
}

.photocontent {
  padding-bottom: 100%;
}

.photocontent img {
  object-fit: cover;
}

.filterItem.filtered, .filterItem.not-active {
  display: none;
}

.allproductspricing span {
  font-weight: 600;
}

@media (min-width: 576px) {
  .modal-dialog {
    min-width: 600px;
  }
}

body {
  font-family: 'Gotham SSm A', 'Gotham SSm B', 'Helvetica', 'sans-serif';
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  color: #1e1f26;
  font-size: 1rem;
}

.container {
  max-width: 1200px;
}

.pagewrap {
  overflow: hidden;
}

@media print {
  .pagewrap {
    margin: 0 !important;
  }
}

ul.bullet li {
  list-style: initial;
}

li {
  list-style: none;
}

.btn-primary, .galleryLink,
.btn-secondary {
  border: 0;
}

.btn, .galleryLink, a.btn, a.galleryLink {
  border-radius: 0;
}

.btn.disabled, .disabled.galleryLink, a.btn.disabled, a.disabled.galleryLink {
  pointer-events: all;
}

hr {
  border-top: 1px solid #cacaca;
}

.modal-backdrop {
  opacity: 0.8;
}

.modal-backdrop:before {
  font-family: 'primo', 'Helvetica', 'sans-serif';
  content: "\e037";
  color: #ffffff;
  font-size: 4em;
  top: 2em;
  position: absolute;
  left: 50%;
  margin-left: -.5em;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation: rotation 3s infinite linear;
  display: inline-block;
}

.modal-backdrop.noload:before {
  content: '';
}

#modal .modal-content {
  overflow: visible;
}

.border-lightgray {
  border: 1px solid #cacaca;
}

.page-link,
a {
  color: #4D648d;
}

.page-link:hover,
a:hover {
  color: #728fc1;
}

.page-link.disabled,
a.disabled {
  cursor: not-allowed;
}

.page-item.active .page-link {
  background: #283655;
}

.nav-pills .nav-link {
  border-radius: 0;
}

.nav-pills .nav-link.active {
  background-color: #283655;
}

.nav-tabs.dark {
  border-bottom: 1px solid #adadad;
}

.nav-tabs.dark .nav-link {
  color: #1e1f26;
  border: 1px solid #adadad;
}

.nav-tabs.dark .nav-link.active {
  background: #1e1f26;
  border: 1px solid #1e1f26;
  color: #ffffff;
}

.btn-outline-primary {
  border-color: #283655;
  color: #283655;
}

.btn-outline-primary:hover {
  background-color: #283655;
  border-color: #283655;
  color: #ffffff;
}

.btn-outline-secondary {
  border-color: #283655;
  color: #283655;
}

.btn-outline-secondary:hover {
  background-color: #283655;
  border-color: #283655;
  color: #ffffff;
}

.btn-outline-gray {
  border-color: #adadad;
  color: #636363;
}

.btn-outline-gray:hover {
  background-color: #adadad;
  color: #ffffff;
}

.btn.btn-dribbble, .btn-dribbble.galleryLink {
  background: #ea4c89;
  border-radius: 5em;
}

.btn.btn-dribbble:hover, .btn-dribbble.galleryLink:hover {
  background: #f2679d;
}

[data-dismiss="modal"]:focus, textarea:focus, input:focus, button:focus, .btn:focus, .galleryLink:focus {
  outline: none;
}

.sidenav .select .badge-primary,
.nav-item .active .badge-primary {
  background: #ffffff;
  color: #283655;
}

.form-control[readonly] {
  background-color: #ffffff;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}

.modal-content {
  overflow: hidden;
}

.modal-body {
  font-size: 0.95em;
}

.modal-body ul li {
  list-style: disc;
}

.modal-body ol li {
  list-style: decimal;
}

.modal-header {
  background: #283655;
  display: block;
  color: #ffffff;
}

.modal-header h4 {
  font-size: 1.25em;
  font-weight: 600;
}

.modal-header .close,
.modal-header .close:focus,
.modal-header .close:hover {
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 767px) {
  .samples-img {
    width: 800px;
    height: auto;
  }
}

@media (max-width: 767px) {
  .home-accounts {
    font-size: .8rem;
  }
}

.category-hero h1 {
  font-size: 1.8em;
}

.category-hero p {
  margin-bottom: 0;
}

.category-hero .row {
  min-height: 280px;
}

.subcat a {
  padding-bottom: 3em;
}

.subcat a .subcatimg {
  padding-bottom: 70%;
  overflow: hidden;
}

.subcat a .btn, .subcat a .galleryLink {
  width: 80%;
  bottom: 20px;
}

.subcat.large a {
  padding-bottom: 5em;
}

.subcat.large .btn, .subcat.large .galleryLink {
  bottom: 2em;
}

.hero-eddm::after {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  opacity: 0.9;
  content: "";
  background-image: url("/images/eddm-hero02.jpg");
  background-size: auto 110%;
  background-position: bottom right;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .hero-eddm::after {
    opacity: 0.2;
  }
}

.hero-non-profit::after {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  content: "";
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url("/images/non-profit-printing.jpg");
}

@media (max-width: 767px) {
  .hero-non-profit::after {
    opacity: 0.2;
  }
}

.vendor-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media (max-width: 767px) {
  .vendor-bg {
    opacity: 0.4;
  }
}

.blocklinks a.selected .notch {
  width: 20px;
  height: 20px;
  background: url("/images/info-notch.svg") top left no-repeat;
  background-size: 100% auto;
  bottom: -20px;
  z-index: 100;
}

.circle-step {
  width: 1em;
  height: 1em;
  top: 2px;
}

.bulletnav {
  width: 0.9em;
  height: 0.9em;
  padding: 0.1em;
  opacity: 0.3;
}

.bulletnav.active {
  opacity: 1;
}

.error-bg {
  min-height: 370px;
  max-width: 600px;
  background: url("/images/error-bg.png") center center no-repeat;
  background-size: 100%;
}

@media (max-width: 767px) {
  .error-bg {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

._elevio_launcher {
  display: none;
}

.ctxUnderline {
  border: 0 !important;
}

.cleanForm .cleanquestion {
  padding-left: 7em;
}

.cleanForm .cleanquestion input, .cleanForm .cleanquestion select, .cleanForm .cleanquestion textarea {
  width: 100%;
  border: 0;
  background: #ffffff;
}

.cleanForm .cleanquestion input:focus, .cleanForm .cleanquestion select:focus, .cleanForm .cleanquestion textarea:focus {
  outline: none;
}

.cleanForm .cleanquestion input, .cleanForm .cleanquestion select {
  height: 36px;
}

.cleanForm .cleanquestion label {
  width: 5em;
}

.cleanForm .designfiles span {
  line-height: 36px;
}

.price-table {
  border: 1px solid #3a3a3a;
  border-radius: .5em;
  margin: 0 .5em;
}

.price-table .row {
  font-size: .85em;
  border-bottom: 1px solid #3a3a3a;
}

.price-table .row:last-of-type {
  border: 0;
}

.price-table .row div {
  padding: .75em 0;
}

.price-table .row div:first-of-type {
  border-right: 1px solid #3a3a3a;
  background: rgba(193, 193, 193, 0.3);
}

ul.detailBox li,
.detailBox.ship div {
  margin-bottom: .4em;
  display: block;
}

@media (min-width: 767px) {
  ul.detailBox li,
  .detailBox.ship div {
    float: left;
    width: 50%;
  }
}

.payment-type {
  font-size: .6rem;
}

@media (min-width: 767px) {
  .payment-type {
    font-size: .4rem;
  }
}

@media (min-width: 992px) {
  .payment-type {
    font-size: .6rem;
  }
}

.payment-type .payment {
  margin-right: 10px;
}

@media (min-width: 767px) {
  .payment-type .payment {
    margin-right: 5px;
  }
}

@media (min-width: 992px) {
  .payment-type .payment {
    margin-right: 10px;
  }
}

.payment-type .payment:last-of-type {
  margin-right: 0;
}

.footer-logo {
  width: 70px;
  height: 14px;
}

.footer-logo img {
  margin-top: 2px;
}

@-moz-document url-prefix() {
  select, select.form-control {
    -moz-appearance: none;
    background-image: url(/images/arrow-down.svg);
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: right 10px center;
    outline: none;
  }
  select:-moz-focusring, select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
  }
}

.home-video {
  width: 100%;
  height: 100%;
  position: absolute;
}

.home-video .res-video {
  position: relative;
}

@media (max-width: 990px) {
  .home-video .res-video.eddm {
    min-width: auto;
    min-height: auto;
    width: 1138px;
    height: 640px;
  }
}

@media (max-width: 990px) {
  .home-video .res-video.design {
    min-width: auto;
    min-height: auto;
    width: 1138px;
    height: 593px;
  }
}

.home-video iframe {
  position: absolute;
  min-width: 110%;
  min-height: 110%;
  width: 100%;
  height: 100%;
}

.boxes {
  background-image: linear-gradient(-180deg, #F4F4F4 2%, #FBFBFB 100%);
  border: 1px solid #DBDBDB;
}

.boxes:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.boxes:hover {
  background-image: linear-gradient(-180deg, #FFFFFF 2%, #FFFFFF 100%);
}

.boxes:hover .bgfirst {
  background: #4D648d;
}

.boxes .inner {
  border: 1px solid #FFFFFF;
}

#leadsplease-link {
  cursor: pointer;
}

@-moz-document url-prefix() {
  #modal-lp {
    height: 760px;
    min-height: 700px;
    margin: auto;
    width: 50%;
    width: fit-content;
    overflow-y: hidden;
  }
}

#modal-lp {
  height: 760px;
  min-height: 700px;
  margin: auto;
  width: fit-content;
  overflow-y: hidden;
}

#modal-lp .modal-body ul li {
  list-style: none;
}

#modal-lp .ext-strict .x-form-text {
  height: 21px !important;
}

#modal-lp #mailing-list-choices .x-form-cb-label {
  margin-bottom: 0 !important;
}

.x-form-check-wrap {
  display: flex;
  align-items: center;
}

.x-form-check-wrap label {
  margin: 0 0 0 2px;
  padding: 0;
  top: 0;
}

.x-form-check-wrap input[type='radio'] {
  display: inline-block;
}

.footable {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border: solid #ccc 1px;
  font-size: 14px;
}

.footable:not(.customTable) tr:nth-child(even) {
  background-color: #e6e7e7;
}

.footable.customTable tr.productRow:nth-child(3) {
  background-color: #e6e7e7 !important;
}

.footable.customTable tr.reorderCustom:nth-child(4) {
  background-color: #e6e7e7 !important;
}

.footable.breakpoint > tbody > tr > td.expand {
  background: url("/images/plus.png") no-repeat 5px center;
}

.footable.breakpoint > tbody > tr.footable-detail-show > td.expand {
  background: url("/images/minus.png") no-repeat 5px center;
}

.footable.breakpoint > tbody > tr.footable-row-detail {
  background: #eee;
}

.footable > tbody > tr:hover {
  background: #fbf8e9;
}

.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}

.footable > tbody > tr > td {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
}

.footable > thead > tr > th {
  padding: 10px 3px;
  color: #a1a1a1;
}

.footable > tbody > tr > td.footable-cell-detail {
  border-left: none;
  text-align: left;
}

.footable > thead > tr > th, .footable > thead > tr > td {
  background-color: #1e1f26;
  border-top: none;
  text-align: center;
}

.footable > thead > tr > th.footable-first-column, .footable > thead > tr > td.footable-first-column,
.footable > tbody > tr > td.footable-first-column {
  border-left: none;
}

.footable > tbody img {
  vertical-align: middle;
}

.footable > tfoot > tr > th, .footable > tfoot > tr > td {
  border-top: 1px solid #ccc;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  padding: 10px;
}

.footable > thead > tr > th > span.footable-sort-indicator {
  width: 16px;
  height: 16px;
  display: block;
  float: right;
  background: url("/images/sorting_sprite.png") no-repeat top left;
}

.footable > thead > tr > th.footable-sortable:hover {
  cursor: pointer;
}

.footable > thead > tr > th.footable-sorted > span.footable-sort-indicator {
  background-position: 0 -32px;
}

.footable > thead > tr > th.footable-sorted-desc > span.footable-sort-indicator {
  background-position: 0 -16px;
}

@font-face {
  font-family: 'galleryslider-icon';
  src: url("//cdn.primoprint.com/fonts/galleryslider-icon.eot");
  src: url("//cdn.primoprint.com/fonts/galleryslider-icon.eot?#iefix") format("embedded-opentype"), url("//cdn.primoprint.com/fonts/galleryslider-icon.woff") format("woff"), url("//cdn.primoprint.com/fonts/galleryslider-icon.ttf") format("truetype"), url("//cdn.primoprint.com/fonts/galleryslider-icon.svg#galleryslider-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

.galleryslider {
  height: 350px;
}

.galleryslider.square {
  height: 480px;
}

.galleryslider .slides > li {
  height: 350px;
  display: none;
  -webkit-backface-visibility: hidden;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 50%;
}

.galleryslider .slides > li img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 576px) {
  .galleryslider .slides > li img {
    width: 100%;
    height: auto;
  }
}

.galleryslider.square .slides > li {
  height: 480px;
}

.gallery-pauseplay span {
  text-transform: capitalize;
}

.slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

.no-js .slides > li:first-child {
  display: block;
}

.galleryslider {
  zoom: 1;
}

.gallery-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .gallery-viewport {
  max-height: 300px;
}

.galleryslider .slides {
  zoom: 1;
}

.gallery-direction-nav {
  *height: 0;
}

.gallery-direction-nav a {
  width: 40px;
  height: 40px;
  margin: -20px 0 0;
  top: 50%;
  z-index: 10;
  opacity: 0;
  color: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gallery-direction-nav .gallery-prev {
  left: -50px;
}

.gallery-direction-nav .gallery-next {
  right: -50px;
  text-align: right;
}

.galleryslider:hover .gallery-prev {
  opacity: 0.7;
  left: 10px;
}

.galleryslider:hover .gallery-next {
  opacity: 0.7;
  right: 10px;
}

.galleryslider:hover .gallery-next:hover,
.galleryslider:hover .gallery-prev:hover {
  opacity: 1;
}

.gallery-direction-nav .gallery-disabled {
  opacity: 0 !important;
  filter: alpha(opacity=0);
  cursor: default;
}

.gallery-direction-nav a:before {
  font-family: "galleryslider-icon";
  font-size: 40px;
  line-height: 40px;
  display: inline-block;
  content: '\f001';
}

.gallery-direction-nav a.gallery-next:before {
  content: '\f002';
}

.gallery-direction-nav.white a {
  text-align: center;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50px;
  height: 50px;
  width: 50px;
}

.gallery-direction-nav.white .gallery-prev {
  padding: 7px 7px 3px 3px;
}

.gallery-direction-nav.white .gallery-next {
  padding: 7px 3px 3px 7px;
}

.gallery-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.gallery-control-nav li a {
  width: 10px;
  height: 10px;
  display: block;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  text-indent: -9999px;
  border-radius: 20px;
}

.gallery-control-nav li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}

.gallery-control-nav li.gallery-active a {
  background: rgba(0, 0, 0, 0.9);
}

.datepickr-wrapper {
  display: inline-block;
  position: relative;
}

.datepickr-calendar {
  font-family: 'Trebuchet MS', Tahoma, Verdana, Arial, sans-serif;
  font-size: 12px;
  background-color: #eee;
  color: #333;
  border: 1px solid #ddd;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 2px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 250px;
}

.open .datepickr-calendar {
  display: block;
}

.datepickr-calendar .datepickr-months {
  background-color: #4D648d;
  border: 1px solid #283655;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  padding: 2px;
  text-align: center;
  font-size: 120%;
}

.datepickr-calendar .datepickr-prev-month,
.datepickr-calendar .datepickr-next-month {
  color: #fff;
  text-decoration: none;
  padding: 0 .6em;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
}

.datepickr-calendar .datepickr-prev-month {
  float: left;
}

.datepickr-calendar .datepickr-next-month {
  float: right;
}

.datepickr-calendar .datepickr-current-month {
  padding: 0 .5em;
  font-weight: bold;
}

.datepickr-calendar .datepickr-prev-month:hover,
.datepickr-calendar .datepickr-next-month:hover {
  background-color: #fff;
  color: #478DD9;
}

.datepickr-calendar table {
  border-collapse: collapse;
  padding: 0;
  width: 100%;
}

.datepickr-calendar thead {
  font-size: 90%;
}

.datepickr-calendar th,
.datepickr-calendar td {
  width: 14.3%;
}

.datepickr-calendar th {
  text-align: center;
  padding: 5px;
}

.datepickr-calendar td {
  text-align: right;
  padding: 1px;
}

.datepickr-calendar .datepickr-day {
  display: block;
  color: #283655;
  background-color: #f6f6f6;
  border: 1px solid #ccc;
  padding: 5px;
  cursor: pointer;
  height: 34px;
  line-height: 24px;
}

.datepickr-calendar .datepickr-day:hover {
  color: #ffffff;
  background-color: #283655;
  border: 1px solid #283655;
}

.datepickr-calendar .today .datepickr-day {
  background-color: #fff0A5;
  border: 1px solid #fed22f;
  color: #363636;
}

.datepickr-calendar .selected .datepickr-day {
  background-color: #283655;
  color: #fff;
}

.datepickr-calendar .disabled .datepickr-day,
.datepickr-calendar .disabled .datepickr-day:hover {
  background-color: #eee;
  border: 1px dotted #ccc;
  color: #bbb;
  cursor: default;
}
