@font-face {
	font-family: 'galleryslider-icon';
	src: url('//cdn.primoprint.com/fonts/galleryslider-icon.eot');
	src: url('//cdn.primoprint.com/fonts/galleryslider-icon.eot?#iefix') format('embedded-opentype'), url('//cdn.primoprint.com/fonts/galleryslider-icon.woff') format('woff'), url('//cdn.primoprint.com/fonts/galleryslider-icon.ttf') format('truetype'), url('//cdn.primoprint.com/fonts/galleryslider-icon.svg#galleryslider-icon') format('svg');
	font-weight: normal;
	font-style: normal;
}

.galleryslider {
	height: 350px;

	&.square {
		height: 480px;
	}
}

.galleryslider .slides > li {
	height: 350px;
	display: none;
	-webkit-backface-visibility: hidden;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: 50% 50%;
	img {
		display: block;
		width: 100%;
		height: auto;
		object-fit: contain;
		@media (max-width: 576px) {
			width: 100%;
			height: auto;
		}
	}
}

.galleryslider.square .slides > li {
	height: 480px;
}

.gallery-pauseplay span {
	text-transform: capitalize;
}

.slides:after {
	content: "\0020";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

html[xmlns] .slides {
	display: block;
}

* html .slides {
	height: 1%;
}

.no-js .slides > li:first-child {
	display: block;
}

.galleryslider {
	zoom: 1;
}

.gallery-viewport {
	max-height: 2000px;
	-webkit-transition: all 1s ease;
	-moz-transition: all 1s ease;
	-o-transition: all 1s ease;
	transition: all 1s ease;
}

.loading .gallery-viewport {
	max-height: 300px;
}

.galleryslider .slides {
	zoom: 1;
}

.gallery-direction-nav {
	*height: 0;
}

.gallery-direction-nav a {
	width: 40px;
	height: 40px;
	margin: -20px 0 0;
	top: 50%;
	z-index: 10;
	opacity: 0;
	color: rgba(0,0,0,0.8);
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.gallery-direction-nav .gallery-prev {
	left: -50px;
}

.gallery-direction-nav .gallery-next {
	right: -50px;
	text-align: right;
}

.galleryslider:hover .gallery-prev {
	opacity: 0.7;
	left: 10px;
}

.galleryslider:hover .gallery-next {
	opacity: 0.7;
	right: 10px;
}

.galleryslider:hover .gallery-next:hover,
.galleryslider:hover .gallery-prev:hover {
	opacity: 1;
}

.gallery-direction-nav .gallery-disabled {
	opacity: 0!important;
	filter:alpha(opacity=0);
	cursor: default;
}

.gallery-direction-nav a:before {
	font-family: "galleryslider-icon";
	font-size: 40px;
	line-height: 40px;
	display: inline-block;
	content: '\f001';
}

.gallery-direction-nav a.gallery-next:before {
	content: '\f002';
}

.gallery-direction-nav.white a {
	text-align: center;
	background: rgba(255, 255, 255, 0.8);
	border-radius: 50px;
	height: 50px;
    width: 50px;
}

.gallery-direction-nav.white .gallery-prev {
	padding: 7px 7px 3px 3px;
}

.gallery-direction-nav.white .gallery-next {
	padding: 7px 3px 3px 7px;
}

.gallery-control-nav li {
	margin: 0 6px;
	display: inline-block;
	zoom: 1;
	*display: inline;

	a {
		width: 10px;
		height: 10px;
		display: block;
		cursor: pointer;
		background: rgba(0,0,0,0.5);
		text-indent: -9999px;
		border-radius: 20px;

		&:hover {
			background: #333;
			background: rgba(0,0,0,0.7);
		}
	}

	&.gallery-active a {
		background: rgba(0,0,0,0.9);
	}
}
