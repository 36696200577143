.daytoshine {
  font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica", "sans-serif";
}

.containerBanner {
  position: relative;
  font-size: 3vw;
}

.containerBanner a {
  border-radius: 7px;
  background-color: #0d2a5f !important;
  font-weight: bold;
  padding: 1vw;
  letter-spacing: 0.1em;
  margin-top: 9vw;
  font-size: 1.3vw;
  display: block;
  width: fit-content;
}
.containerBanner a:hover {
  color: #0d2a5f !important;
  background-color: #fff !important;
}

.containerBanner img {
  width: 99%;
  height: auto;
  display: block;
  margin: auto;
}

.row-menu-daytoshine {
  display: flex;
  justify-content: flex-start;
  font-size: 1.2vw;
  gap: 3%;
  font-weight: bold;
  padding-left: 10vw;
  margin-top: -4.9vw;
  position: relative;
}
.row-menu-daytoshine a {
  color: #0d2a5f;
  text-decoration: none;
}

.row-menu-daytoshine a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.text {
  position: absolute;
  top: 11%;
  left: 8%;
}

.blue-daytoshine {
  color: #0d2a5f;
}
.blue-daytoshine p {
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin: 0px;
}

.blue-daytoshine span {
  letter-spacing: 2.5px;
  font-size: 1.2vw;
  display: block;
}

.getstartedbtn {
  box-shadow: 2px 2px #dcdbc1, 2.5px 2.5px #00c96f;
  margin-bottom: 45px;
}

@media (max-width: 440px) {
  .row-menu-daytoshine {
    justify-content: center;
    gap: 4%;
    font-size: 2vw;
    padding-left: 0;
    margin-top: -4vw;
  }
  .getstartedbtn {
    box-shadow: 1.2px 1.2px #dcdbc1, 1.4px 1.4px #00c96f;
    margin-bottom: 45px;
  }
}

@media (max-width: 560px) {
  .blue-daytoshine span {
    width: 90%;
    font-size: 1.5vw;
    letter-spacing: 1px;
  }
  .containerBanner a {
    margin-top: 7vw;
  }
}