// Autocomplete Search
.nav-search {
    height: 4em;
    span:first-of-type {
        font-size: 1.25em
    }
    input {
        font-size: .9rem;
        font-weight: 500;
        @media (max-width: 767px) {
            padding: 15px;
            width: 100%;
        }
        &::placeholder {
            font-size: .7rem;
            line-height: 1.8em;
            color: $dark;
            @extend .animate2;
        }
        &:focus::placeholder{
            color: $grayLightest;
        }
    }
    form {
        display: inline-block;
        @media (max-width: 767px) {
            display: none;
            position: fixed;
            width: 100%;
            top: 6em;
            left: 0;
            background: #ffffff;
            border-bottom: 1px solid $dark;
        }
    }
}

.nav-search-results {
    position: fixed;
    top: 4em;
    left: 0;
    width: 100%;
    z-index: 90;
    margin: 0;
    border-bottom: 1px solid $dark;
    .search-result {
        position: relative;
        padding: 0 2em 0 12.5em;
        min-height: 10em;
        color: $blueReg;
        font-size: .75em;
        img {
            width: 10em;
            height: auto;
            left: 1em;
        }
    }
    @media (max-width: 767px) {
        top: 8.05em;
    }
}

//SEARCH RESULTS PAGE

.search-page {

    /* SEARCH INPUT
    // ------------------------- */

    .search-input {
        border: 0;
        border-bottom: 5px solid #eee;
        &:focus {
            border-color: $blueReg;
        }
    }

    .search-input,
    .search-input:focus {
        transition: border-color .3s ease-in;
        outline: 0;
        line-height: normal;
    }

    .search-input-icon {
        bottom: 15px;
        width: 20px;
        height: 20px;
        content: '';
        cursor: default;
        background: url(/images/search/search_icon.png) no-repeat;

        &.empty {
            cursor: pointer;
            background-image: url(/images/search/delete_icon.png);
        }
    }

    /* HITS
    // ------------------------- */

    .hit-content em {
        font-style: normal;
        background: #dde9fb;
    }

}
