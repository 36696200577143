.vendordesc {
    h3 {
        margin-bottom: 1em;
    }
    a {
        color: $blueReg;
        &:hover {
            color: $blueLight;
        }
    }
    img {
        @extend .imgauto;
        margin: -2em 0 1em;
    }
}
