/*** BLOG ***/

.wp-searchform {
	height: 34px;
	button{
		background: none;
		line-height: 34px;
	}
	input {
		line-height: 34px;
		width: 175px;
	}
}

a.blogclose {
	color: #ffffff;
	line-height: 34px;
}

.more-link {
	background: $blueReg;
	padding: 10px 20px;
	text-align: center;
	color: #ffffff;
	margin: 0 auto 30px;
	display: block;
	width: 250px;
	border-radius: 5px;
	&:hover {
		background: $blueLight;
		text-decoration: none;
		color: #ffffff;
	}
}

.article-content {
	ul li {
		list-style: disc;
	}
	h2 {
		font-size: 1.5em;
		font-weight: 700;
		margin-bottom: 1em;
	}
	h3{
		font-size: 1.25em;
		font-weight: 700;
		margin-bottom: 1em;
	}
	h4{
		font-size: 1.15em;
		font-weight: 700;
		margin-bottom: 1em;
	}
	h5, h6{
		font-size: 1em;
		font-weight: 700;
		margin-bottom: 1em;
	}
}
