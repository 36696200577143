//PRODUCT SELECTOR STEPS

.listbuilderSteps {
    z-index: 100;
    height: 36px;

    .step {
        width: 36px;
        line-height: 36px;

        &:before {
            color: #ffffff;
        }

        span {
            top: -30px;
        }

        .steptooltip {
            border: 2px solid $blueReg;
            width: 240px;
            top: 55px;
            left: -5px;
            display: none;

            img {
                top: -19px;
                left: 5px;
                right: inherit;
            }
        }

        &:nth-child(2) {
            left: 33.33%;
            margin-left: -18px;

            .steptooltip {
                @extend .horizontal-align-ab;

                img {
                    @extend .horizontal-align-ab;
                }
            }
        }

        &:nth-child(3) {
            left: 66.33%;
            margin-left: -18px;

            .steptooltip {
                @extend .horizontal-align-ab;

                img {
                    @extend .horizontal-align-ab;
                }
            }
        }

        &:nth-child(4) {
            right: 0;

            .steptooltip {
                right: -5px;
                left: inherit;
            }

            img {
                right: 5px;
                left: inherit;
            }
        }
    }

    .stepline {
        width: 99%;
        height: 5px;
    }

    .notshowing {
        background: $blueLighter;
    }
}

.set, .optionsList {
    &.disabled {
        opacity: .5;
        pointer-events: none;
        cursor: not-allowed;
    }
}

.groupalert.disabled {
    pointer-events: none;
    opacity: .5;
    @extend .noselect;
}

.incbutton.disabled {
  .increment {
    cursor: not-allowed;
    opacity: .5;
  }
}

.galleryBullet.img {
  width: 50px;
  height: 33px;
  img {
    width: 100%;
    height: auto;
    opacity: .5;
  }
  &.gallery-active {
    border-top: 3px solid $coral;
    img {
      opacity: 1;
    }
  }
}

.hide-more {
  max-height: 200px;
  overflow: hidden;
}

.show-more {
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 40%, rgba(255,255,255,1) 90%);
}

.productOptions {
  label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .vertical-align {
      top: inherit;
      transform: none;
    }
  }
}
