.growl {
    position: fixed;
    background: rgba(0, 0, 0, 0.9);
    top: -3.5em;
    min-height: 3.5em;
    padding: 1em 0;
    z-index: 10004;
    font-size: 1.25em;
    .growloff {
        width: .8em;
        cursor: pointer;
    }
    @media (max-width: 720px) {
      font-size: 1em;
    }
}
