.filter {
    position: static;
    .tab {
        border: 1px solid $grayLighter;
    }
    .tab-spacer {
        height: 5px;
        bottom: 0;
        border: 1px solid $grayLighter;
        border-top: 0;
        border-bottom: 0;
        display: none;
    }
    .filter-list{
        display: none;
        margin-top: -1px;
        border: 1px solid $grayLighter;
        left: 0;
        li a{
            border-radius: 2em;
        }
    }
    .filtertag {
        &.selected {
            background-color: $blueReg;
            color: #ffffff;
        }
    }
}

.subcatalts {
    .subcat {
        &.hidesubcat {
            display: none;
        }
        a {
            padding-bottom: 0;
            .subcatimg {
                padding-bottom: 0;
            }
            &:hover {
                h2.colordark {
                    color: $blueLighter;
                }
            }
        }

    }
}

.photocontent {
    padding-bottom: 100%;
    img {
        object-fit: cover;
    }
}

.filterItem {
    &.filtered, &.not-active {
        display: none;
    }
}

.allproductspricing {
  span {
    font-weight: 600;
  }
}
