/**
COLOR
*/
$dark: #1e1f26;
$grayReg: #3a3a3a;
$grayLight: #636363;
$grayLighter: #adadad;
$grayLightest: #cacaca;
$blueReg: #283655;
$blueLight: #4D648d;
$blueLighter: #728fc1;
$blueLightest: #D0E1F9;
$attention: #c30000;
$attentionlight: #d91e1e;
$tan: #f4f1ec;
$coral: #E75666;
$coralLight: #f26675;

// Background Colors
.bgwhite {
  background: #ffffff;

  &.hover:hover {
    background: #ffffff;
  }

  &.colorfirst {
    color: $blueReg;
  }
}

.bgdark {
  background: $dark;
  color: #ffffff;

  &.hover:hover {
    background: $dark;
    color: #ffffff;
  }

  a {
    color: #ffffff;
  }
}

.bgfirst {
  background: $blueReg;
  color: #ffffff;

  &.hover:hover {
    background: $blueLight;
    color: #ffffff;
  }

  &.disabled {
    color: #ffffff;
  }

  &.colorwhite, a {
    color: #ffffff;
  }

  &.light {
    background: $blueLight;

    &.hover:hover {
      background: $blueLighter;
    }
  }
}

.bgred {
  background: $coral;
  border: 2px solid $coral;
  color: #ffffff;

  &.hover:hover {
    background: $coralLight;
    border: 2px solid $coralLight;
    color: #ffffff;
  }

  &.colorwhite, a {
    color: #ffffff;
  }

  &.outline {
    background: #ffffff;
    border: 2px solid $coral;
    color: $coral;

    &.hover:hover {
      background: #ffffff;
      border: 2px solid $coralLight;
      color: $coralLight;
    }
  }

}

.bgattention {
  background: $attention;
  border: 2px solid $attention;
  color: #ffffff;

  &.hover:hover {
    background: $attentionlight;
    border: 2px solid $attentionlight;
    color: #ffffff;
  }

  &.colorwhite, a {
    color: #ffffff;
  }

  &.outline {
    background: #ffffff;
    border: 2px solid $attention;
    color: $attention;

    &.hover:hover {
      background: #ffffff;
      border: 2px solid $attentionlight;
      color: $attentionlight;
    }
  }

}

.bgblue {
  background: #2fa6e5;
  border: 2px solid #2fa6e5;
  color: #ffffff;

  &.hover:hover {
    background: #4cb7f0;
    border: 2px solid #4cb7f0;
    color: #ffffff;
  }

  &.colorwhite, a {
    color: #ffffff;
  }

  &.outline {
    background: #ffffff;
    border: 2px solid #2fa6e5;
    color: #2fa6e5;

    &.hover:hover {
      background: #ffffff;
      border: 2px solid #4cb7f0;
      color: #4cb7f0;
    }
  }

}

.bgsecond {
  background: $grayReg;
  color: #ffffff;

  &.hover:hover {
    background: $grayLight;
  }

  a {
    color: #ffffff;
  }
}

.bgthird {
  background: $tan;
}

.bgfourth {
  background: $grayLightest;
  color: #ffffff;

  &.hover:hover {
    background: $grayLighter;
    color: #ffffff;
  }

  &.dark {
    background: $grayLight;
  }

  a {
    color: #ffffff;
  }

  &.disabled {
    color: #ffffff;
  }

  &.light {
    background: #f6f6f6;
  }
}

.bgfifth {
  background: #9e0b0f;
  color: #ffffff;

  &.hover:hover {
    background: #b8181c;
    color: #ffffff;
  }
}

.bgsixth {
  background-color: #fafafa;
  color: $grayReg;

  &.dark {
    background: $grayLightest;
  }

  a {
    color: $grayReg;
  }
}

.bgseventh {
  background: #f6f6f6;
}

.bgeighth {
  background-color: #fafafa;
}

.bgerror {
  background: #c30000;
  color: #ffffff;

  &.hover:hover {
    background: #db3a3a;
  }

  a {
    color: #ffffff;
  }
}

.bghighlight {
  background: rgba(250, 255, 153, 0.3);
}

.bgshadow {
  background: rgba(0, 0, 0, 0.50)
}

.lightlink {
  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

.bgnone {
  background: none;
}

// TEXT COLOR
.colordark {
  color: $dark;

  a {
    color: $dark;
  }

  &.hover {
    a:hover {
      color: $blueLighter;
    }
  }

  &.nohover {
    color: $dark;
  }

  &.selected {
    color: $blueReg;
  }
}

.colorwhite {
  color: #ffffff;
  &:hover {
    color: #ffffff;
  }
  a {
    color: #ffffff;
  }
  &.nohover {
    color: #ffffff;
  }
}

.colorblack {
  color: #000000 !important;
  &:hover {
    color: #000000;
  }
  a {
    color: #000000;
  }
  &.nohover {
    color: #000000;
  }
}

.colorattention {
  color: $attention;
}

.colorcoral {
  color: $coral;
}

.button-coral {
  background: $coral;
  color: #ffffff;
  &:hover {
    background: $coralLight;
    color: #ffffff;
  }
  &.outline {
    background: none;
    border-color: $coral;
    color: $blueReg;
    border-style: solid;
    &:hover {
      background: none;
      border-color: $coralLight;
      color: $blueLight;
    }
  }
}

.button-blue {
  background: $blueReg;
  color: #ffffff;
  &:hover {
    background: $blueLight;
    color: #ffffff;
  }
  &.outline {
    background: none;
    border-color: $blueReg;
    color: $blueReg;
    border-style: solid;
    &:hover {
      background: none;
      border-color: $blueLight;
      color: $blueLight;
    }
  }
}

.colorgreen {
  color: #8FD944;
}

.bgattention {
  background-color: $attention;
}

.colorfirst {
  color: $blueReg;

  &.hover:hover {
    color: $blueLight;
  }
}

.colorsecond {
  color: $blueLight;

  a {
    color: $blueLight;

    &:hover {
      color: $blueLighter;
    }
  }

  &.hover:hover {
    color: $blueLighter;
  }
}

.colorthird {
  color: $blueLighter;

  a {
    color: $blueLighter;

    &:hover {
      color: $blueLightest;
    }
  }

  &.hover:hover {
    color: $blueLightest;
  }
}

.colordarkgray {
  color: $grayReg;
}

.colorgray {
  color: $grayLight;

  a {
    color: $grayLight;
  }
}

.colorlightgray {
  color: $grayLighter;

  a {
    color: $grayLighter;
  }

  &.nohover {
    color: $grayLighter;
  }
}

a.preventhover {
  &:visited, &:hover, &:active {
    color: inherit !important;
  }
  color: inherit !important;
}

a.althover {
  img, div, p {
    @extend .animate2;
    -webkit-backface-visibility: hidden;
  }
  &:hover {
    img {
      opacity: .8;
    }
    .bgfirst {
      background: $blueLight;
    }
    .bgfourth {
      background: #fafafa;
    }
    .colorsecond {
      color: $blueLighter;
    }
  }
}

.hoverbglight {
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  &.selected {
    background: rgba(255, 255, 255, 0.2);
  }
}

.hoverdark{
  @extend .animate2;
  &:hover {
    background: $blueReg;
    border-color: $blueReg;
    color: #ffffff;
  }
}

.grouphover:hover {
  .hoverdark{
    background: $blueReg;
    border-color: $blueReg;
    color: #ffffff;
  }
  .bgfifth {
    background: #b8181c;
    color: #fff;
  }
}

// Border Colors
.borderfirst {
  border: 1px solid $blueReg;

  &.hover:hover {
    border: 1px solid $blueLight;
  }
}

.bordersecond {
  border: 1px solid $grayReg;

  &.light {
    border: 1px solid $grayLightest;
  }
}

.borderthird {
  border: 1px solid $grayLighter;

  &.light {
    border: 1px solid $grayLightest;
  }
}

.borderfourth {
  border: 1px solid #c30000;
}

.borderfifth {
  border: 1px solid #e9ecef;
}

.borderwhite {
  border: 1px solid #ffffff;
}

.bordergray {
  border-color: $grayLightest;
  &.dark {border-color: $grayLight;}
}

.inputrequired {
  border: 1px solid #c30000 !important;
}
