/* Forms */
.inputwrap {
	border:1px solid #ddd;
	background: #ffffff;
	color:#555;
	@include border-radius(2px);
	position:relative;
}

textarea.form-control {
	font-size: .9rem;
}

.optionError {
	border: 1px solid #d10000;
    background: #ffe5e5;
}

.checkoutError {
	border: 1px solid #d10000 !important;
}

.optionHighlight {
	border: 1px solid #007bff;
    background: #f1f8ff;
}
