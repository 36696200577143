/**
    MEDIA QUERIES

    Where all media queries' variables are defined

    1100px = largescreen
    970px = desktop
    850px = tablet
    670px = medium
    570px = small
    425px = mobile
*/

$mq-largescreen: "screen and (max-width: 1100px)";
$mq-desktop: "screen and (max-width: 970px)";
$mq-tablet: "screen and (max-width: 850px)";
$mq-medium: "screen and (max-width: 670px)";
$mq-small: "screen and (max-width: 570px)";
$mq-mobile: "screen and (max-width: 425px)";


// Small devices (landscape phones, 576px and up)
$bp-min-sm: "min-width: 576px";
// Medium devices (tablets, 768px and up)
$bp-min-md: "min-width: 768px";
// Large devices (desktops, 992px and up)
$bp-min-lg: "min-width: 992px";
// Extra large devices (large desktops, 1200px and up)
$bp-min-xl: "min-width: 1200px";


// Extra small devices (portrait phones, less than 576px)
$bp-max-xs: "max-width: 575px";
// Small devices (landscape phones, less than 768px)
$bp-max-sm: "max-width: 767px";
// Medium devices (tablets, less than 992px)
$bp-max-md: "max-width: 991px";
// Large devices (desktops, less than 1200px)
$bp-max-lg: "max-width: 1199px";
