.nav-wrap {

    font-size: .8rem;

    @media ($bp-min-lg) {
        font-size: 1rem;
    }

    .site-header {
        z-index: 1000;
        will-change: transform;
        @extend .animateopacity;

        .nav-extras {
            width: 100%;
            max-width: 300px;
            border: 1px solid $dark;
            background: #ffffff;
            border-top: 0;
            position: fixed;
            top: 4em;
            z-index: 10;
            display: none;
            input::placeholder {
                color: $grayLightest;
                font-size: .8em;
            }
        }

        .nav-upper {
            display: block;
            height: 4em;
            .menublock {
                width: 4em;
                height: 4em;
                &:hover {
                    border-color: $dark;
                }
            }
            .nav-extras {
                right: 0;
                border-right: 0;
            }
            .cartlink {
                height: 4em;
                div:last-of-type {
                    font-size: .7rem;
                    font-weight: 500;
                }
            }
        }

        .nav-lower {
            position: relative;
            z-index: 1;
            height: 4em;
            text-align: center;
            @media (max-width: 767px) {
                position: fixed;
                overflow: auto;
                z-index: 10000;
                top: 0;
                left: 0;
                text-align: left;
                padding-top: 3.5em;
                width: 300px;
                margin-left: -300px;
                min-height: 100vh;
                background: #ffffff;
                font-size: 1.15em;
                -webkit-transition: all .2s ease-in-out;
                -moz-transition: all .2s ease-in-out;
                -o-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                &.menuopen {
                    margin-left: 0;
                    display: block !important;
                    overflow-x: hidden;
                    padding-bottom: 150px;
                }
            }
            @media (max-width: 992px) {
              .industry-icon {
                font-size: .65rem;
              }
            }

            .nav-extras {
                left: 0;
                border-left: 0;
                @media (max-width: 767px) {
                    display: block;
                    border: 0;
                    position: relative;
                    top: auto;
                    height: 2.5em;
                    a {
                        font-size: .9em;
                        font-weight: 500;
                        line-height: 1.25em;
                    }
                    &.support-menu {
                        a {
                            font-size: 1em;
                            padding: .5em 0;
                        }
                    }
                }
            }

            .cat-link, .allproducts {
                height: 4em;
                padding-top: 4px;
                border-bottom: 4px solid rgba(255,255,255,0);
                &.selected {
                    color: $blueLighter;
                    border-bottom: 4px solid $dark;
                    @media (max-width: 767px) {
                        border-bottom: 4px solid rgba(255,255,255,0);
                    }
                }
                @media (max-width: 992px) {
                  font-size: .9rem;
                  height: 3.7em;
                }
                @media (max-width: 767px) {
                    height: 2em;
                    font-size: 1.1rem;
                }
                div:first-of-type {
                    font-size: .9em;
                    font-weight: 500;
                    line-height: 1.25em;
                }
            }

            .cat-menu {
                display: none;
                position: fixed;
                top: 8em;
                left: 0;
                width: 100%;
                z-index: 11;
                text-align: left;
                a {
                    font-size: .8em;
                }
                @media (max-width: 767px) {
                    position: relative;
                    top: auto;
                    border: 0;
                    background: #f6f6f6;
                }
            }

            .cat-menu-wrap {
              @media (min-width: 767px) {
                background-color: #ffffff;
                @include border-radius-custom(0, 0, 10px, 10px);
                overflow: hidden;
              }
            }

            .eddm-nav {
                img {
                    margin-bottom: -10px;
                    bottom: 0;
                }
                @media (max-width: 1300px) {
                    img {margin-bottom: 0px;}
                }
                .hideshow {
                    @media (max-width: 1450px) {
                        display: none;
                    }
                    @media (max-width: 1275px) {
                        display: inline-block;
                    }
                    @media (max-width: 850px) {
                        display: none;
                    }
                }
                .blockalt {
                    @media (max-width: 1450px) {
                        display: inline-block;
                    }
                    @media (max-width: 1275px) {
                        display: block;
                    }
                    @media (max-width: 991px) {
                        display: inline-block;
                    }
                }
            }

            .nav-border {
              border: 1px solid #dee2e6;
              @media (max-width: 767px) {
                border-width: 0px;
              }
            }

            .mobile-nav-border {
              @media (max-width: 767px) {
                border-bottom: 1px solid #dee2e6;
              }
            }

            .all-products a {
                float: left;
                width: 20%;
                @media (max-width: 992px) {
                    width: 25%;
                }
                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .all-products a,
            .product-col a,
            .product-col p,
            .product-col .title {
                display: block;
                padding: .25em .5em;
                @media (max-width: 767px) {
                    padding: .5em;
                    color: #ffffff;
                }
            }

        }

        .cat-menu-backer {
          background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.70) 50%, rgba(0,0,0,0.90) 100%);
          height: 650px;
          width: 100%;
          position: absolute;
          bottom: -650px;
          left: 0;
          z-index: 0;
          display: none;
          @media (max-width: 767px) {
            display: none !important;
          }
        }

        .slide-bg {
            @media (max-width: 767px) {
                position: fixed;
                width: 100%;
                height: 100vh;
                top: 0;
                left: 0;
                background: #000;
                z-index: 9999;
                opacity: .85;
                display: none;
            }
        }

        .bg {
            opacity: 0.9;
            background: #ffffff;
            border-bottom: 1px solid #e6e8e7;
            z-index: 0;
        }

        &.navHide {
            opacity: 0;
            pointer-events: none;
        }

        &.navShow {
            opacity: 1;
        }

        .logo {
            max-width: 130px;
            width: 130px;
            height: 4em;
            max-height: 4em;
            @media (max-width: 767px) {
                max-width: 110px;
                width: 110px;
            }
        }
        .logo-yelow {
            max-width: 200px;
            width: 200px;
            height: 10em;
            max-height: 10em;
            @media (max-width: 767px) {
                max-width: 145px;
                width: 145px;
            }
        }

        .submenu {
            top: 6em;
            display: none;
            .bg {
                opacity: 1;
                border: 1px solid #e6e8e7;
                border-top: 1px solid #ffffff;
            }
        }

        .main-nav {
            .down {
                font-size: 0.95em;
                line-height: 3.7em;

                div {
                    line-height: inherit;
                }
            }
        }

        .pricing-level {
            top: -1.3em;
        }

    }

    .spacer {
        height: 4em;
        max-height: 4em;
        @media ($bp-min-md) {
            height: 8em;
            max-height: 8em;
        }
        @media print {
            display: none;
        }
    }

    &.hideSubNav {
        .nav-lower {
            @media (min-width: 767px) {
                height: 0;
                border: 0;
            }
            .product-nav {
                @media (min-width: 767px) {
                    display: none;
                    background: #ffffff;
                }
            }
        }
        .spacer {
            height: 4em;
        }
    }

}

// Hamburger menu
.nav-wrap {

    .slidermenu {
        padding: 1.24em 1em;
        @media (max-width: 767px) {
            z-index: 10001 !important;
        }
    }

    input[type="checkbox"].menuToggle {
      display: block;
      width: 4em;
      height: 4em;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      -webkit-touch-callout: none;
    }

    .menu-icon span.hamburguesa {
        display: block;
        width: 22px;
        height: 2px;
        margin-bottom: 5px;
        position: relative;
        background: $dark;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                    opacity 0.55s ease;
        -webkit-transform-style: preserve-3d;
        -webkit-backface-visibility: hidden;
        &:first-child{
            transform-origin: 0% 0%;
        }
        &:last-child{
            margin-bottom: 0;
        }
        &:nth-last-child(2){
          transform-origin: 0% 100%;
        }
    }

    .menuToggle:checked ~ .menu-icon span.hamburguesa{
      opacity: 1;
      transform: rotate(45deg) translate(-6px, -10px);
    }

    .menuToggle:checked ~ .menu-icon span.hamburguesa:nth-last-child(3){
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
    }

    .menuToggle:checked ~ .menu-icon span.hamburguesa:nth-last-child(2){

      transform: rotate(-45deg) translate(-3px, 9px);
    }
}

#current_vendor {
    margin-bottom: 15px;
}