body {
	font-family: 'Gotham SSm A', 'Gotham SSm B', 'Helvetica', 'sans-serif';
	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	color: $dark;
	font-size: 1rem;
}

.container {
	max-width: 1200px;
}

.pagewrap {
	overflow: hidden;
	@media print {
		margin: 0 !important
	}
}

ul.bullet {
	li {
		list-style: initial;
	}
}

li {
	list-style: none;
}

a {
	@extend .animate2;
}

// Bootstrap Override
.btn-primary,
.btn-secondary {
	border: 0;
}

.btn, a.btn {
	border-radius: 0;
	&.disabled {
		pointer-events: all;
	}
}

hr {
	border-top: 1px solid $grayLightest;
}

.modal-backdrop {
	opacity: 0.8;
	&:before {
		font-family: 'primo', 'Helvetica', 'sans-serif';
		content: "\e037";
		color: #ffffff;
		font-size: 4em;
		top: 2em;
		position: absolute;
		left: 50%;
		margin-left: -.5em;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
		-webkit-animation: rotation 3s infinite linear;
		display: inline-block;
	}
	&.noload {
		&:before {
			content: '';
		}
	}
}

#modal .modal-content {
	overflow: visible;
}

.border-lightgray {
	border: 1px solid $grayLightest;
}

.page-link,
a {
	color: $blueLight;

	&:hover {
		color: $blueLighter;
	}

	&.disabled {
		cursor: not-allowed;
	}
}

.page-item.active .page-link {
	background: $blueReg;
}

.nav-pills .nav-link {
	border-radius: 0;
	&.active {
		background-color: $blueReg;
	}
}

.nav-tabs.dark {
	border-bottom: 1px solid $grayLighter;
	.nav-link{
		color: $dark;
		border: 1px solid $grayLighter;
		&.active {
			background: $dark;
			border: 1px solid $dark;
			color: #ffffff;
		}
	}
}

.btn-outline-primary {
	border-color: $blueReg;
	color: $blueReg;

	&:hover {
		background-color: $blueReg;
		border-color: $blueReg;
		color: #ffffff;
	}
}

.btn-outline-secondary {
	border-color: $blueReg;
	color: $blueReg;

	&:hover {
		background-color: $blueReg;
		border-color: $blueReg;
		color: #ffffff;
	}
}

.btn-outline-gray {
	border-color: $grayLighter;
	color: $grayLight;

	&:hover {
		background-color: $grayLighter;
		color: #ffffff;
	}
}

.btn.btn-dribbble {
	background: #ea4c89;
	border-radius: 5em;
	&:hover {
		background: #f2679d;
	}
}

[data-dismiss="modal"], textarea, input, button, .btn {
	&:focus{
		outline: none;
	}
}

.sidenav .select,
.nav-item .active {
	.badge-primary {
		background: #ffffff;
		color: $blueReg;
	}
}

.form-control[readonly] {
	background-color: #ffffff;
}
.form-control:disabled {
	background-color: #e9ecef !important;
}

// Bootstrap modal
.modal-content {
	overflow: hidden;
}

.modal-body {
	font-size: 0.95em;

	ul {
		li {
			list-style: disc;
		}
	}

	ol {
		li {
			list-style: decimal;
		}
	}
}

.modal-header {
	background: $blueReg;
	display: block;
	color: #ffffff;

	h4 {
		font-size: 1.25em;
		font-weight: 600;
	}

	.close,
	.close:focus,
	.close:hover {
		color: #ffffff;
		cursor: pointer;
		@extend .animate2;
	}
}

// Home Page
.samples-img {
	@media (max-width: 767px) {
		width: 800px;
		height: auto;
	}
}
.home-accounts {
	@media (max-width: 767px) {
		font-size: .8rem
	}
}

// Category Page
.category-hero {
	h1 {
		font-size: 1.8em;
	}

	p {
		margin-bottom: 0;
	}

	.row {
		min-height: 280px;
	}
}

.subcat {
	a {
		padding-bottom: 3em;

		.subcatimg {
			padding-bottom: 70%;
			overflow: hidden;
		}

		.btn {
			width: 80%;
			bottom: 20px;
		}
	}

	&.large {
		a {
			padding-bottom: 5em;
		}

		.btn {
			bottom: 2em;
		}
	}
}

// Hero Images
.hero-eddm::after {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	opacity: 0.9;
	content: "";
	background-image: url("/images/eddm-hero02.jpg");
	background-size: auto 110%;
	background-position: bottom right;
	background-repeat: no-repeat;
	@media (max-width: 767px) {
		opacity: 0.2;
	}
}

.hero-non-profit::after {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	content: "";
	@media (max-width: 767px) {
		opacity: 0.2;
	}
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	background-image: url("/images/non-profit-printing.jpg");
}

.vendor-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
	@media (max-width: 767px) {
		opacity: 0.4;
	}
}

// EDDM Page
.blocklinks a.selected {
	.notch {
		width: 20px;
		height: 20px;
		background: url("/images/info-notch.svg") top left no-repeat;
		background-size: 100% auto;
		bottom: -20px;
		z-index: 100;
	}
}

.circle-step {
	width: 1em;
	height: 1em;
	top: 2px;
}

// Custom Nav Bullets
.bulletnav {
	width: 0.9em;
	height: 0.9em;
	padding: 0.1em;
	opacity: 0.3;

	&.active {
		opacity: 1;
	}
}

// Error Page
.error-bg {
	min-height: 370px;
	max-width: 600px;
	background: url("/images/error-bg.png") center center no-repeat;
	background-size: 100%;
	@media (max-width: 767px) {
		background-color: rgba(255, 255, 255, 0.5);
	}
}

// elev.io
._elevio_launcher {
	display: none;
}

.ctxUnderline {
	border: 0 !important;
}

// Design style form
.cleanForm {
	.cleanquestion {
		padding-left: 7em;
		input, select, textarea {
			width: 100%;
			border: 0;
			background: #ffffff;
			&:focus {
				outline: none
			}
		}
		input, select {
			height: 36px;
		}
		label {
			width: 5em;
		}
	}
	.designfiles {
		span{
			line-height: 36px;
		}
	}
}

//Pricing Table
.price-table {
	border: 1px solid $grayReg;
	border-radius: .5em;
	margin: 0 .5em;
	.row {
		font-size: .85em;
		border-bottom: 1px solid $grayReg;
		&:last-of-type {
			border: 0;
		}
		div {
			padding: .75em 0;
			&:first-of-type {
				border-right: 1px solid $grayReg;
				background: rgba(193, 193, 193, 0.30);
			}
		}
	}
}


// Order View
ul.detailBox li,
.detailBox.ship div {
	margin-bottom: .4em;
	display: block;
	@media (min-width: 767px) {
		float: left;
		width: 50%;
	}
}

//Footer
.payment-type {
	font-size: .6rem;
	@media (min-width: 767px) {
		font-size: .4rem;
	}
	@media (min-width: 992px) {
		font-size: .6rem;
	}
	.payment {
		margin-right: 10px;
		@media (min-width: 767px) {
			margin-right: 5px;
		}
		@media (min-width: 992px) {
			margin-right: 10px;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}
}
.footer-logo {
	width: 70px;
	height: 14px;
	img{
		margin-top: 2px;
	}
}

// Come on firefox
@-moz-document url-prefix() {
	select, select.form-control {
		-moz-appearance: none;
		background-image: url(/images/arrow-down.svg);
		background-repeat: no-repeat;
		background-size: 10px;
		background-position: right 10px center;
		outline: none;
	}
	select:-moz-focusring, select::-moz-focus-inner {
		color: transparent !important;
		text-shadow: 0 0 0 #000 !important;
	}
}

.home-video {
	width: 100%;
	height: 100%;
	position: absolute;
	.res-video {
		position:relative;
		&.eddm {
			@media (max-width: 990px) {
				min-width: auto;
				min-height: auto;
				width: 1138px;
				height: 640px;
			}
		}
		&.design{
			@media (max-width: 990px) {
				min-width: auto;
				min-height: auto;
				width: 1138px;
				height: 593px;
			}
		}
	}
	iframe {
		position: absolute;
		min-width: 110%;
		min-height: 110%;
		width: 100%;
		height: 100%;
	}
}

.boxes {
	background-image: linear-gradient(-180deg, #F4F4F4 2%, #FBFBFB 100%);
	border: 1px solid #DBDBDB;
	&:after {
		content: "";
		display: block;
		padding-bottom: 100%;
	}
	&:hover {
		background-image: linear-gradient(-180deg, #FFFFFF 2%, #FFFFFF 100%);
		.bgfirst {
			background: $blueLight;
		}
	}
	.inner {
		border: 1px solid #FFFFFF;
	}
}



// CLEAN UP
.galleryLink {
	@extend .btn;
	@extend .btn-primary;
	@extend .bgsecond;
	@extend .hover;
	@extend .noline;
	@extend .type-1;
	@extend .mt-2;
	@extend .mb-4;
	@extend .p-1;
	@extend .pr-2;
	@extend .pl-2;
}


#leadsplease-link {
	cursor: pointer;
}
@-moz-document url-prefix() {
	#modal-lp {
		height: 760px;
		min-height: 700px;
		margin: auto;
		width: 50%;
		width: fit-content;
		overflow-y: hidden;
	}
}
#modal-lp {
	height: 760px;
	min-height: 700px;
    margin: auto;
	width: fit-content;
    overflow-y: hidden;

	.modal-body ul li {
		list-style: none;
	}

	.ext-strict .x-form-text {
		height: 21px!important;
	}

	#mailing-list-choices .x-form-cb-label {
		margin-bottom: 0!important;
	}
}

.x-form-check-wrap {
	display: flex;
	align-items: center;

	label {
		margin:0 0 0 2px;
		padding:0;
		top:0;
	}

	input[type='radio'] {
		display: inline-block;
	}
}