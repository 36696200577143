.step-form {
    &.light {
        button {
            background: transparent;
            color: #ffffff;
            @extend .animate2;
            &:hover {
                border-color: $blueReg;
                background: $blueReg;
            }
        }
        .inputwrap.placeholder{
            background: none;
            border: 0;
            border-radius: 0;
            .reqit {
                margin-top: 5px;
            }
            label, input, &.focused label {
                color: #ffffff;
                text-align: left;
            }
            label {
                font-size: 1.5em;
                bottom: 0;
                top: auto;
                opacity: .7;
            }
            &.focused label {
                font-size: .85em;
                text-align: left;
                bottom: 15px;
                opacity: 1;
            }
            input {
                font-size: 1.5em;
                background: none;
                border: 0;
                border-bottom: 2px solid #ffffff;
                border-radius: 0;
                -webkit-appearance: none;
            }
            &.select {
                &:after {
                    color: #ffffff;
                }
                select, select:focus {
                    background: none;
                    border: 2px solid #ffffff;
                    border-radius: 0;
                    font-size: 1.25em;
                    color: #ffffff !important;
                    padding: 8px 9px;
                    height: auto;
                    margin: 0;
                    margin-top: 15px;
                    position: relative;
                    z-index: 1;
                    &:focus {
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(82, 168, 236, 1);
                    }
                }
                @supports (-ms-ime-align:auto) {
                    select, select:focus{
                        color: #000 !important;
                        background: #ffffff;
                    }
                    &:after {
                        color: #000 !important;
                    }
                }
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    select, select:focus{
                        color: #000 !important;
                        background: #ffffff;
                    }
                    &:after {
                        content:''
                    }
                }
            }
            textarea {
                background: none;
                border: 2px solid #ffffff;
                border-radius: 0;
                font-size: 1.25em;
                color: #ffffff;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus
            input:-webkit-autofill,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover
            textarea:-webkit-autofill:focus,
            select.form-control:-webkit-autofill,
            select.form-control:-webkit-autofill:hover,
            select.form-control:-webkit-autofill:focus {
                -webkit-animation: autofill 0s forwards;
                animation: autofill 0s forwards;
                -webkit-text-fill-color: #fff !important;
            }

            @keyframes autofill {
                100% {
                    background: transparent;
                    color: inherit;
                }
            }

            @-webkit-keyframes autofill {
                100% {
                    background: transparent;
                    color: inherit;
                }
            }
        }
    }
    &.dark {
        button {
            background: transparent;
            color: $grayReg;
            @extend .animate2;
            &:hover {
                border-color: $blueReg;
                background: $blueReg;
                color: #ffffff;
            }
        }
        button.bgfirst {
          background: $blueReg;
          color: #ffffff;
          &:hover {
            background: $blueLight
          }
        }
        .inputwrap.placeholder{
            background: none;
            border: 0;
            border-radius: 0;
            .reqit {
                margin-top: 5px;
            }
            label, input, &.focused label {
                color: $grayReg;
                text-align: left;
            }
            label {
                font-size: 1.15em;
                bottom: 0;
                top: auto;
                opacity: .7;
            }
            &.focused label {
                font-size: .85em;
                text-align: left;
                bottom: 15px;
                opacity: 1;
            }
            input {
                font-size: 1.15em;
                background: none;
                border: 0;
                border-bottom: 1px solid $grayReg;
                border-radius: 0;
                -webkit-appearance: none;
            }
            &.select {
                &:after {
                    color: $grayReg;
                }
                select, select:focus {
                    background: none;
                    border: 1px solid $grayReg;
                    border-radius: 0;
                    font-size: 1.15em;
                    color: $grayReg !important;
                    padding: 8px 9px;
                    height: auto;
                    margin: 0;
                    margin-top: 15px;
                    position: relative;
                    z-index: 1;
                    &:focus {
                        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(82, 168, 236, 1);
                    }
                }
                @supports (-ms-ime-align:auto) {
                    select, select:focus{
                        color: #000 !important;
                        background: $grayReg;
                    }
                    &:after {
                        color: #000 !important;
                    }
                }
                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    select, select:focus{
                        color: #000 !important;
                        background: $grayReg;
                    }
                    &:after {
                        content:''
                    }
                }
            }
            textarea {
                background: none;
                border: 1px solid $grayReg;
                border-radius: 0;
                font-size: 1.25em;
                color: $grayReg;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus
            input:-webkit-autofill,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover
            textarea:-webkit-autofill:focus,
            select.form-control:-webkit-autofill,
            select.form-control:-webkit-autofill:hover,
            select.form-control:-webkit-autofill:focus {
                -webkit-animation: autofill 0s forwards;
                animation: autofill 0s forwards;
                -webkit-text-fill-color: $grayReg !important;
            }

            @keyframes autofill {
                100% {
                    background: transparent;
                    color: inherit;
                }
            }

            @-webkit-keyframes autofill {
                100% {
                    background: transparent;
                    color: inherit;
                }
            }
        }
    }
}
