﻿.footable {
	border-collapse: separate;
	border-spacing: 0;
	width: 100%;
	border: solid #ccc 1px;
	font-size: 14px;
}

.footable:not(.customTable) tr:nth-child(even) {background-color: #e6e7e7;}

.footable.customTable tr.productRow:nth-child(3) {background-color: #e6e7e7 !important;}
.footable.customTable tr.reorderCustom:nth-child(4) {background-color: #e6e7e7 !important;}

.footable.breakpoint > tbody > tr > td.expand {
  background: url('/images/plus.png') no-repeat 5px center;
}

.footable.breakpoint > tbody > tr.footable-detail-show > td.expand {
  background: url('/images/minus.png') no-repeat 5px center;
}

.footable.breakpoint > tbody > tr.footable-row-detail {
  background: #eee;
}

.footable > tbody > tr:hover {
  background: #fbf8e9;
}

.footable.breakpoint > tbody > tr:hover:not(.footable-row-detail) {
  cursor: pointer;
}

.footable > tbody > tr > td {
  border-top: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  position: relative;
}

.footable > thead > tr > th {
  padding: 10px 3px;
  color: #a1a1a1;
}

.footable > tbody > tr > td.footable-cell-detail {
  border-left: none;
  text-align: left;
}

.footable > thead > tr > th, .footable > thead > tr > td {
  background-color: $dark;
  border-top: none;
  text-align: center;
}

.footable > thead > tr:first-child > th.footable-first-column, .footable > thead > tr:first-child > td.footable-first-column {

}

.footable > thead > tr:first-child > th.footable-last-column, .footable > thead > tr:first-child > td.footable-last-column {

}

.footable > thead > tr:first-child > th.footable-first-column.footable-last-column, .footable > thead > tr:first-child > td.footable-first-column.footable-last-column {

}

.footable > tbody > tr:last-child > td.footable-first-column {

}

.footable > tbody > tr:last-child > td.footable-last-column {

}

.footable > tbody > tr:last-child > td.footable-first-column.footable-last-column {

}

.footable > thead > tr > th.footable-first-column, .footable > thead > tr > td.footable-first-column,
.footable > tbody > tr > td.footable-first-column {
  border-left: none;
}

.footable > tbody img {
  vertical-align:middle;
}

.footable > tfoot > tr > th, .footable > tfoot > tr > td {
  border-top: 1px solid #ccc;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  padding: 10px;
}
